import React, { useState } from "react";
import styles from "./ShareLink.module.css";
import { Box, Button, Stack, Typography } from "@mui/material";
import BottomDrawer from "../BottomDrawer";
import { ReactComponent as Share } from "../../img/button-share.svg";
import { ReactComponent as Link } from "../../img/share-link.svg";
import { ReactComponent as Copy } from "../../img/clipboard.svg";
import placeHolder from "../../img/placeholder1.png";

export const truncate = (paragraph) => {
  if (paragraph?.length > 30) {
    return paragraph?.substring(0, 30) + "...";
  } else {
    return paragraph;
  }
};

const ShareLink = ({ link, creatorName, open, setOpen }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const handleShare = async () => {
    if (!navigator.share) {
      const imageUrl = placeHolder;
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const imageFile = new File([blob], "creator-image.jpg", {
        type: "image/jpeg",
      });
      const objectUrl = URL.createObjectURL(imageFile);
      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = "creator-image.jpg";
      link.click();

      console.log("Image downloaded successfully");
      return;
    }
    const imageUrl = placeHolder;
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const imageFile = new File([blob], "your-image.jpg", {
      type: "image/jpeg",
    });

    try {
      await navigator.share({
        title: creatorName,
        text: `Book 1:1 time with ${creatorName} on Beep`,
        url: `https://${link}`,
        files: [imageFile],
      });
      setOpen(false);
      console.log("Content shared successfully");
    } catch (error) {
      console.error("Error sharing", error);
    }
  };

  return (
    <BottomDrawer
      header="Share your Beep link"
      subHeader={
        <>
          Select an image to share to socials, or copy your
          <br /> Beep link for posts, hyperlinks, or link-in-bio.
        </>
      }
      isOpen={open}
      onClose={() => setOpen(false)}
      canExit
      centered
      styleOveride={{ px: "30px", pt: "25px" }}
    >
      <Stack alignItems={"center"} mt={3}>
        <img src={placeHolder} className={styles.carouselImage} alt={"share"} />
      </Stack>
      <Box px={"30px"} pb={"30px"}>
        <Button
          startIcon={<Share />}
          className={styles.shareButton}
          onClick={handleShare}
        >
          Share
        </Button>
        <Stack
          direction={"row"}
          border={"1px solid #EAEAF1"}
          borderRadius={"4px"}
          height={32}
          mt={4}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            flexGrow={1}
            gap={1}
            sx={{
              height: "100%",
              background: "rgba(234, 234, 241, 0.30)",
              pl: 1,
            }}
          >
            <Link />
            <Typography variant="body2">{truncate(link)}</Typography>
          </Stack>
          <Button
            startIcon={<Copy />}
            className={styles.copyButton}
            onClick={handleCopy}
          >
            {copied ? "Copied" : "Copy"}
          </Button>
        </Stack>
      </Box>
    </BottomDrawer>
  );
};

export default ShareLink;
