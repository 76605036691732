import React, { useContext } from "react";
import PropTypes from "prop-types";

import { UserContext } from "../../context";
import CopyLink from "../ShareLink";
import styles from "./ShareLinkGuide.module.css";
import { ReactComponent as LinkGuide } from "../../img/link-guide.svg";
import { ReactComponent as WebsiteGuide } from "../../img/website-guide.svg";

const ShareLinkGuide = ({ className }) => {
  const { user } = useContext(UserContext);
  const creatorLink = `${window.location.host}/${user?.creator.handle}`;

  return (
    <div className={className}>
      <h3 className={styles.shareHeader}>Share your new chat</h3>
      <p className={styles.guideDesc}>
        Get started monetizing your DMs by sharing your Beep link across the
        internet to maximize touch points with your audience.
      </p>
      <div className={styles.section}>
        <LinkGuide />
        <div className={styles.textContainer}>
          <h4>Add to your Link-in-bios</h4>
          <p>On Instagram, Tiktok, Youtube, and more</p>
        </div>
      </div>
      <div className={styles.section}>
        <WebsiteGuide />
        <div className={styles.textContainer}>
          <h4>Add to your Website or Blog</h4>
          <p>Use your URL as a hyperlink, make a clickable image, etc.</p>
        </div>
      </div>
      {user && <CopyLink link={creatorLink} className={styles.copy} />}
    </div>
  );
};

ShareLinkGuide.propTypes = {
  className: PropTypes.string,
};

export default ShareLinkGuide;
