import React, { useCallback, useContext, useEffect, useState } from "react";
import { withFormik } from "formik";
import PropTypes from "prop-types";
import CustomFormInput from "../../../../../components/CustomFormInput";
import Template from "../Template";
import { SIGNUP_STEPS } from "../../../../../lib/signup";
import AvatarUpload from "../../../../../components/AvatarUpload";
import { UserContext } from "../../../../../context";
import { Box, Divider, InputLabel, Stack, Typography } from "@mui/material";
import avatarFallback from "../../../../../img/avatar-fallback.svg";

const BioForm = ({
  values,
  errors,
  setFieldValue,
  setSubmitting,
  handleSubmit,
  activeStep,
  isSubmitting,
}) => {
  const { onError } = useContext(UserContext);
  const [inputValues, setInputValues] = useState({
    brandName: "",
    topic1: "",
    topic2: "",
  });
  const onFormSubmit = useCallback(async () => {
    if (isSubmitting) return;
    setSubmitting(true);
    await handleSubmit(values);
  }, [handleSubmit, setSubmitting, values, isSubmitting]);

  const handleInputChange = (event, fieldName) => {
    const newValue = event.target.value;
    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldName]: newValue,
    }));
  };

  const handleSuccessfulUpload = useCallback(
    (url) => {
      setFieldValue("avatarUrl", url);
    },
    [setFieldValue]
  );

  useEffect(() => {
    const updateBriefBio = () => {
      const briefBio = inputValues;
      setFieldValue("briefBio", briefBio);
    };
    updateBriefBio();
  }, [setFieldValue, inputValues]);

  return (
    <Template
      title={"About you"}
      subtitle={
        <>
          Describe your expertise in your Headline and add a photo to help
          clients get to <br /> know you better.
        </>
      }
      step={activeStep}
      onSubmit={onFormSubmit}
      disabled={
        !inputValues.brandName || !inputValues.topic1 || !inputValues.topic2
      }
      isRequired={false}
    >
      <AvatarUpload
        onSuccessfulUpload={handleSuccessfulUpload}
        onError={onError}
        url={avatarFallback}
        noText
      />
      <Box mt={6}>
        <InputLabel variant="body1" sx={{ color: "#000", fontSize: "18px" }}>
          From the mind behind
        </InputLabel>
        <CustomFormInput
          name="brandName"
          sx={{ mb: 2, height: 50 }}
          onChange={(event) => handleInputChange(event, "brandName")}
          placeholder="Enter your brand or business"
          forProfile
          multiline
          style={{ padding: "0px 5px 0px 10px" }}
        />

        <InputLabel variant="body1" sx={{ color: "#000", fontSize: "18px" }}>
          {values?.firstName} advises you on
        </InputLabel>
        <Stack direction={"row"} alignItems="center" gap={3}>
          <CustomFormInput
            name="topic1"
            sx={{ mb: 2, height: 50 }}
            onChange={(event) => handleInputChange(event, "topic1")}
            placeholder="Enter a topic"
            forProfile
            multiline
            style={{ padding: "0px 5px 0px 10px" }}
          />
          <Typography variant="body1" fontSize={"18px"}>
            and
          </Typography>
          <CustomFormInput
            name="topic2"
            sx={{ mb: 2, height: 50 }}
            onChange={(event) => handleInputChange(event, "topic2")}
            placeholder="Enter a topic"
            forProfile
            multiline
            style={{ padding: "0px 5px 0px 10px" }}
          />
        </Stack>
        <Divider sx={{ borderColor: "#000", my: 2 }} />
        <Stack mt={3} gap={2}>
          <Typography variant="body2" color={"#717192"}>
            Example headline:
          </Typography>
          <Typography variant="body1" color={"#717192"}>
            From the mind behind Bon Appetite, {values?.firstName} advises you
            on nutrition and culinary management.
          </Typography>
        </Stack>
      </Box>
    </Template>
  );
};

BioForm.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setSubmitting: PropTypes.func,
  error: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.func,
  loading: PropTypes.bool,
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => initialValues,
  validationSchem: {},
  handleSubmit: (values, { props: { onSubmit, activeStep } }) => {
    return onSubmit(
      values,
      `/creator/onboarding/${SIGNUP_STEPS[activeStep + 1]}`
    );
  },
  displayName: "BioForm",
})(BioForm);
