import React, { useState } from "react";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import "./index.css";
import CustomButton from "../../components/CustomButton";
import useMutate from "../../hooks/useMutate";
import BottomDrawer from "../BottomDrawer";
import { ReactComponent as BeepLogo } from "../../img/logo-with-text.svg";
import { ReactComponent as Exit } from "../../img/exit.svg";
// import { ReactComponent as Back } from "../../img/back-arrow.svg";
import { ReactComponent as PoweredBy } from "../../img/powered-by-stripe.svg";

const CardInfoDialog = ({
  open,
  onDismiss,
  onSubmit,
  showSave = true,
  setupIntent = false,
  onError,
  order,
  description,
  creator,
  dmPrice,
  message,
  onMessageChange,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [saveCard, setSaveCard] = useState(true);
  const [loading, setLoading] = useState(false);
  const [updateOrder] = useMutate(`/order/${order?.id}`, { method: "put" });
  const [getSetupIntent] = useMutate("/payment/setup");

  const handleSaveCard = async () => {
    if (!showSave || !saveCard) return;

    const { error } = await updateOrder({
      savePayment: true,
    });
    if (error) {
      onError(error.message);
      return;
    }

    onSubmit();
    setLoading(false);
  };

  const handleSetupIntent = async () => {
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      onError(submitError?.message);
      setLoading(false);
      return;
    }

    // Create the SetupIntent and obtain clientSecret
    const res = await getSetupIntent();
    if (!res.data?.clientSecret) {
      onError("Unable to save payment method. Please try again.");
      setLoading(false);
      return;
    }

    // Confirm the SetupIntent using the details collected by the Payment Element
    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret: res.data.clientSecret,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: "if_required",
    });

    if (error) {
      onError(error.message);
      setLoading(false);
    } else {
      onSubmit();
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setLoading(true);

    // if save card, call api to setup
    if (showSave && saveCard) await handleSaveCard();
    if (setupIntent) await handleSetupIntent();
  };

  return (
    <BottomDrawer isOpen={open} onClose={onDismiss} showHandle canExit={false}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Stack direction={"row"} alignItems="center" gap={2}>
          {/* <Back /> */}
          <BeepLogo width={92} height={23} />
        </Stack>

        <Exit
          style={{
            width: "24px",
            height: "24px",
            cursor: "pointer",
          }}
          onClick={onDismiss}
        />
      </Stack>
      <form onSubmit={handleSubmit} className="modalForm">
        <Typography variant="header1" fontSize={"20px"}>
          {creator ? (
            <>
              Enter payment to send your <br />
              question to {creator}
            </>
          ) : (
            "Add payment method"
          )}
        </Typography>
        <p className="card-info-dialog-description">{description}</p>
        {message && (
          <Stack direction={"row"} alignItems="center" gap={1} my={3}>
            <textarea
              value={message}
              onChange={onMessageChange}
              className="text-input"
              placeholder="Type your message..."
            />
          </Stack>
        )}
        <PaymentElement />
        <Stack className="stackLayout" my={1}>
          <Typography variant="body2" color={"#717192"} fontSize="10px">
            Your payments are securely processed by Stripe
          </Typography>
          <PoweredBy />
        </Stack>
        {showSave && (
          <FormControlLabel
            sx={{ float: "left" }}
            control={
              <Checkbox
                checked={saveCard}
                onChange={(event) => setSaveCard(event.target.checked)}
                name="save"
                inputProps={{ "aria-label": "controlled" }}
                color="primary"
                size="small"
                classes={{
                  colorPrimary: "checkbox-color",
                }}
              />
            }
            label="Save this card for future transactions"
            classes={{
              label: "card-info-save",
            }}
          />
        )}
        <CustomButton
          className="card-info-dialog-button"
          type="submit"
          disabled={!stripe}
          loading={loading}
        >
          {dmPrice ? `Pay ${`$${dmPrice}`} & Send Message` : "Add"}
        </CustomButton>
      </form>
    </BottomDrawer>
  );
};

CardInfoDialog.propTypes = {
  open: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  order: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default CardInfoDialog;
