import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./VideoCallPreview.module.css";
import { ReactComponent as Check } from "../../../img/confirm-check.svg";
import { ReactComponent as Calendar } from "../../../img/calendar.svg";
import TimeZoneIcon from "../../icons/TimeZoneIcon";
import CustomButton from "../../CustomButton";

function daysUntilCall(date) {
  const callDate = new Date(date);
  const currentDate = new Date();
  const diffMilliseconds = Math.abs(callDate - currentDate);
  const diffDays = diffMilliseconds / (1000 * 60 * 60 * 24);
  return `in ${Math.round(diffDays)} days`; // Otherwise, return the number of days
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
}

function getTimeFromDate(dateString) {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  let period = "am";
  if (hours >= 12) {
    period = "pm";
  }
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const timeString = `${hours}:${paddedMinutes} ${period}`;
  return timeString;
}

export default function VideoCallPreview({ booking, creator, completed }) {
  const handleJoin = (joinUrl) => {
    window.open(joinUrl, "_blank");
  };

  return (
    <div className={styles.bookingPreview}>
      <Box
        className={completed ? styles.bookingInfoCompleted : styles.bookingInfo}
      >
        <Typography
          variant="body1"
          fontSize={"22px"}
          fontWeight={700}
          color="#fff"
        >
          {booking?.contactDetails?.name} ${creator?.videoCallHourlyRate / 2}
        </Typography>
        <Typography variant="body2" fontWeight={700} color="#fff">
          {completed
            ? `Your call with ${booking?.contactDetails?.name} has ended`
            : `You are scheduled with ${booking?.contactDetails?.name}
          ${daysUntilCall(booking?.startDate)}`}
        </Typography>
      </Box>
      <Stack gap={1.5} mb={1} mt={2}>
        <Stack direction={"row"} alignItems="center" gap={1}>
          <Check />
          <Typography variant="body2">30 Minute Meeting</Typography>
        </Stack>
        <Stack direction={"row"} alignItems="start" gap={1}>
          <Calendar />
          <Typography variant="body2" lineHeight={"16px"}>
            {getTimeFromDate(booking?.startDate)} –{" "}
            {getTimeFromDate(booking?.endDate)} <br />
            {formatDate(booking?.startDate)}
          </Typography>
        </Stack>
        <Stack direction={"row"} alignItems="center" gap={1}>
          <TimeZoneIcon />
          <Typography variant="body2">{booking?.timezone}</Typography>
        </Stack>
      </Stack>
      {!completed && (
        <Stack gap={1} mt={2}>
          <CustomButton
            onClick={() => handleJoin(booking?.joinUrl)}
            variant="secondary"
            sx={{ fontSize: "15px" }}
          >
            Join meeting
          </CustomButton>
          <CustomButton
            variant="secondary"
            sx={{ fontSize: "15px", border: "none !important" }}
          >
            Reschedule
          </CustomButton>
        </Stack>
      )}
    </div>
  );
}
