import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Avatar, Badge } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";
import backArrow from "../../../img/back-arrow.svg";
import "./index.css";
import styles from "./ChatPreview.module.css";

const truncate = (paragraph) => {
  if (!paragraph) return "";
  if (paragraph.length > 200) {
    return paragraph.substring(0, 200) + "...";
  } else {
    return paragraph;
  }
};

const timeDiff = (laterDate, earlierDate) => {
  // get days, use if greater than zero
  const diffInDays = differenceInDays(laterDate, earlierDate);
  if (diffInDays > 0) return `${diffInDays}d`;
  // get hours, use if greater than zero
  const diffInHours = differenceInHours(laterDate, earlierDate);
  if (diffInHours) return `${diffInHours}h`;
  // get minutes
  const diffInMins = differenceInMinutes(laterDate, earlierDate);
  if (diffInMins) return `${diffInMins}m`;

  const diffInSeconds = differenceInSeconds(laterDate, earlierDate);
  if (diffInSeconds) return `${diffInSeconds}s`;
};

const ChatPreview = ({ chat, recipient = {}, needsReply }) => {
  const navigate = useNavigate();

  const handleOpenFullChat = useCallback(() => {
    navigate(`/${recipient?.handle}/message`);
  }, [navigate, recipient]);

  return (
    <div className="chat-preview">
      <div className="chat-preview-header">
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={<div className={styles.online} />}
          invisible={true}
        >
          <Avatar alt="User Avatar" src={recipient.avatarUrl} />
        </Badge>
        <div className="chat-preview-title">
          <p className="chat-preview-name">
            {recipient.firstName} {recipient.lastName}
          </p>
          {chat && (
            <p className="chat-preview-subtitle">
              {needsReply
                ? `Sent ${timeDiff(
                    new Date(),
                    new Date(chat.lastReplyDate)
                  )} ago`
                : `Replied ${
                    timeDiff(new Date(), new Date(chat.lastReplyDate)) || "0s"
                  } ago`}
            </p>
          )}
        </div>
        {!needsReply && (
          <img
            className="chat-preview-open-chat"
            src={backArrow}
            alt="open chat"
            width={14}
            height={19}
            onClick={handleOpenFullChat}
          />
        )}
      </div>
      {needsReply && (
        <div className="chat-preview-message" onClick={handleOpenFullChat}>
          {chat && (
            <div className="chat-preview-text">
              <p>{truncate(chat?.lastMessage?.body) || "[Media]"}</p>
            </div>
          )}
          <img
            className="chat-preview-open-chat"
            src={backArrow}
            alt="open chat"
            width={14}
            height={19}
          />
        </div>
      )}
    </div>
  );
};

ChatPreview.propTypes = {
  chatId: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
  }),
  recipient: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
  }),
  onSend: PropTypes.func,
  onError: PropTypes.func,
  needsReply: PropTypes.bool,
};

export default ChatPreview;
