import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Avatar, Badge, Stack, Typography } from "@mui/material";
import cns from "classnames";

import styles from "./AvatarUpload.module.css";
import camera from "../../img/camera.png";
import CustomButton from "../CustomButton";

const AvatarUpload = ({
  onSuccessfulUpload,
  removeImage,
  onError,
  size = "regulars",
  ctaText = "Change image",
  url,
  className,
  noText,
  showButtons,
}) => {
  const [imagePreview, setImagePreview] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = React.createRef();

  const openFileDialog = () => {
    if (isLoading) return;
    fileInputRef.current.click();
  };

  const handleAvatarUpload = useCallback(
    (evt) => {
      setIsLoading(true);
      const data = new FormData();
      data.append("file", evt.target.files[0]);
      data.append("upload_preset", "avatar");
      data.append("cloud_name", "creator-tools");
      axios("https://api.cloudinary.com/v1_1/creator-tools/image/upload", {
        method: "post",
        data,
      })
        .then((res) => {
          setImagePreview(res.data.secure_url);
          onSuccessfulUpload(res.data.secure_url);
          setIsLoading(false);
        })
        .catch(() => {
          onError("Unable to upload image. Please try again.");
          setIsLoading(false);
        });
    },
    [onError, onSuccessfulUpload]
  );

  return (
    <Stack
      className={showButtons && "stackLayout"}
      gap={showButtons && 5}
      width={showButtons && "100%"}
    >
      <Stack
        className={cns(className, styles.upload)}
        onClick={openFileDialog}
        direction="row"
        gap={2}
        alignItems={"center"}
        justifyContent={noText && "center"}
      >
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <div className={styles.cameraContainer}>
              <img
                alt="camera icon"
                src={camera}
                className={
                  size === "small"
                    ? styles.smallCamera
                    : size === "medium"
                    ? styles.mediumCamera
                    : styles.camera
                }
              />
            </div>
          }
        >
          <Avatar
            alt="User Avatar"
            name="avatarUrl"
            className={
              size === "small"
                ? styles.smallAvatar
                : size === "medium"
                ? styles.mediumAvatar
                : styles.avatar
            }
            src={imagePreview || url}
          />
        </Badge>
        <input
          type="file"
          id="avatarUpload"
          onChange={handleAvatarUpload}
          className={styles.uploadInput}
          ref={fileInputRef}
        />

        {!noText && (
          <Typography variant="body1" fontSize="17px">
            Add a profile picture
          </Typography>
        )}
      </Stack>
      {showButtons && (
        <Stack flexGrow={1} gap={1}>
          <CustomButton
            onClick={openFileDialog}
            sx={{ minHeight: 36, width: "auto" }}
          >
            {ctaText}
          </CustomButton>
          <CustomButton
            onClick={removeImage}
            variant="secondary"
            sx={{ minHeight: 36 }}
          >
            Remove
          </CustomButton>
        </Stack>
      )}
    </Stack>
  );
};

AvatarUpload.propTypes = {
  onSuccessfulUpload: PropTypes.func,
  onError: PropTypes.func,
  small: PropTypes.bool,
};

export default AvatarUpload;
