import React, { useState } from "react";
import "./index.css";
import { Stack } from "@mui/material";
import BottomDrawer from "../BottomDrawer";
import CustomButton from "../CustomButton";
import CustomFormInput from "../CustomFormInput";

export default function UpdateBio({
  value,
  setFieldValue,
  open,
  setOpen,
  onSubmit,
}) {
  const [bioValue, setBioValue] = useState(value || "");

  const handleInputChange = (e) => {
    setBioValue(e.target.value);
  };

  const handleSave = (event) => {
    setFieldValue("fullBio", bioValue);
    setOpen(false);
    onSubmit(event);
  };

  const handleClose = () => {
    setOpen(false);
    setBioValue(value);
  };

  return (
    <BottomDrawer
      isOpen={open}
      onClose={() => handleClose()}
      header="Bio"
      showHandle
      canExit={false}
      showBack
    >
      <CustomFormInput
        name="fullBio"
        sx={{ mb: 2 }}
        value={bioValue}
        onChange={handleInputChange}
        multiline
        placeholder="Tell fans more about yourself here"
        inputClassName="update-input"
      />
      <Stack direction={"row"} gap={2} mt={4}>
        <CustomButton variant="secondary" onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton onClick={handleSave} disabled={!bioValue}>
          Save
        </CustomButton>
      </Stack>
    </BottomDrawer>
  );
}
