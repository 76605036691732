import { forwardRef } from "react"
import use100vh from "../../hooks/use100vh"

const Div100vh = forwardRef(({ style, ...other }, ref) => {
  const height = use100vh();

  const styleWithRealHeight = {
    ...style,
    height: height ? `${height}px` : '100svh'
  }

  return <div ref={ref} style={styleWithRealHeight} {...other} />
})

Div100vh.displayName = 'Div100vh';

export default Div100vh;