import React, { useCallback } from "react";
import { withFormik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import CustomFormInput from "../../../../../components/CustomFormInput";
import Template from "../Template";
import { SIGNUP_STEPS } from "../../../../../lib/signup";
import TimezoneSelect from "../../../../../components/TimezoneSelect";
import { Box } from "@mui/material";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  timezone: Yup.string().required("Timezone is required"),
});

const NameForm = ({
  values,
  setFieldValue,
  setSubmitting,
  handleSubmit,
  activeStep,
  isSubmitting,
}) => {
  const onFormSubmit = useCallback(async () => {
    if (isSubmitting) return;
    setSubmitting(true);

    await handleSubmit(values);
  }, [handleSubmit, setSubmitting, values, isSubmitting]);

  return (
    <Template
      title="Create your Beep account"
      step={activeStep}
      onSubmit={onFormSubmit}
      disabled={!values.firstName || !values.lastName || !values.timezone}
      showLogin
    >
      <Box mt="-10px">
        <CustomFormInput
          name="firstName"
          label="First name"
          sx={{ mb: 2 }}
          placeholder="First"
          requiredText
        />
        <Box mt="30px">
          <CustomFormInput
            name="lastName"
            label="Last name"
            sx={{ mb: 2 }}
            placeholder="Last"
            requiredText
          />
        </Box>
        <Box mt="30px">
          <TimezoneSelect
            timezone={values?.timezone}
            onChange={(event, newValue) => {
              setFieldValue("timezone", newValue);
            }}
          />
        </Box>
      </Box>
    </Template>
  );
};

NameForm.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setSubmitting: PropTypes.func,
  error: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.func,
  loading: PropTypes.bool,
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => ({
    ...initialValues,
    timezone:
      initialValues.timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone,
  }),
  validationSchema,
  handleSubmit: (values, { props: { onSubmit, activeStep } }) => {
    return onSubmit(
      values,
      `/creator/onboarding/${SIGNUP_STEPS[activeStep + 1]}`
    );
  },
  displayName: "NameForm",
})(NameForm);
