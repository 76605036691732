import React, { useState } from "react";
import { Stack } from "@mui/material";
import BottomDrawer from "../BottomDrawer";
import CustomButton from "../CustomButton";
import CustomFormInput from "../CustomFormInput";
import styles from "./CreatorAccount.module.css";

export default function UpdateEmail({
  email,
  setFieldValue,
  open,
  setOpen,
  onSubmit,
}) {
  const [emailValue, setEmailValue] = useState(email || "");

  const handleInputChange = (e) => {
    setEmailValue(e.target.value);
  };

  const handleSave = (event) => {
    setFieldValue("email", emailValue);
    setOpen(false);
    onSubmit(event);
  };

  const handleClose = () => {
    setOpen(false);
    setEmailValue(email);
  };

  return (
    <BottomDrawer
      isOpen={open}
      onClose={() => handleClose()}
      header="Change email"
      showHandle
      canExit={false}
      showBack
    >
      <Stack mt={3}>
        <CustomFormInput
          name="email"
          label="New email*"
          sx={{ mb: 2 }}
          value={emailValue}
          onChange={handleInputChange}
          inputClassName={styles.accountSettingsInput}
        />
      </Stack>
      <Stack direction={"row"} gap={2} mt={3}>
        <CustomButton variant="secondary" onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton onClick={handleSave} disabled={!emailValue}>
          Save
        </CustomButton>
      </Stack>
    </BottomDrawer>
  );
}
