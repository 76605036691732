export const SIGNUP_STEP_LOOKUP = {
  contact: 0,
  name: 1,
  services: 2,
  availability: 3,
  schedule: 3,
  conferencing: 4,
  bio: 5,
  expertise: 6,
  payments: 7,
  completed: 8
}

export const SIGNUP_STEPS = [
  'contact',
  'name',
  'services',
  'availability',
  'conferencing',
  'bio',
  'expertise',
  'payments',
  'completed'
]