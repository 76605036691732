import React from "react";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";
import cns from "classnames";

import styles from "./CustomButton.module.css";

const CustomButton = ({
  onSubmit,
  onClick,
  children,
  className,
  disabled = false,
  fullWidth = true,
  endIcon,
  startIcon,
  loading,
  variant = "primary",
  style,
  ...props
}) => {
  return (
    <LoadingButton
      {...props}
      loading={loading}
      loadingIndicator={
        <CircularProgress
          style={{
            color: variant === "primary" ? "white" : "#5F52EB",
          }}
          size={16}
        />
      }
      variant="contained"
      fullWidth={fullWidth}
      onClick={onSubmit || onClick}
      startIcon={loading ? null : startIcon}
      endIcon={loading ? null : endIcon}
      className={cns(className, styles[variant])}
      disabled={disabled}
      style={{ opacity: disabled ? 0.6 : 1, color: "white", ...style }}
    >
      {loading ? null : children}
    </LoadingButton>
  );
};

CustomButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  endIcon: PropTypes.node,
  loading: PropTypes.bool,
};

export default CustomButton;
