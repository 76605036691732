import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { Box, Button, Stack, Typography } from "@mui/material";
import styles from "./UpdateProfileForm.module.css";
import CustomButton from "../../../../components/CustomButton";
import BackButton from "../../../../components/BackButton";
import AvatarUpload from "../../../../components/AvatarUpload";
import beepLogo from "../../../../img/logo-with-text.svg";
import { ReactComponent as Plus } from "../../../../img/plus.svg";
import UpdateBio from "../../../../components/CreatorProfile/UpdateBio";
import UpdateExpertise from "../../../../components/CreatorProfile/UpdateExpertise";
import { expertiseLookup } from "../../Signup/SignupForm/ExpertiseForm";
import UpdateSocialLinks from "../../../../components/CreatorProfile/UpdateSocialLinks";
import PreviewIcon from "../../../../components/icons/PreviewIcon";
import UpdateHeadline from "../../../../components/CreatorProfile/UpdateHeadline";
import SocialLinks from "../../../../components/CreatorProfile/SocialLinks";
import UpdateFaq from "../../../../components/CreatorProfile/UpdateFaq";
import UpdateWork from "../../../../components/CreatorProfile/UpdateWork";
import { useNavigate } from "react-router-dom";
import UpdateReviews from "../../../../components/CreatorProfile/UpdateReviews";

const UpdateProfileForm = ({
  userId,
  values,
  handleSubmit,
  setFieldValue,
  onError,
  errors,
  touched,
  loading,
  setFieldTouched,
  setFieldError,
}) => {
  const navigate = useNavigate();
  const [bioOpen, setBioOpen] = useState(false);
  const [expertiseOpen, setExpertiseOpen] = useState(false);
  const [socialsOpen, setSocialsOpen] = useState(false);
  const [headlineOpen, setHeadlineOpen] = useState(false);
  const [faqOpen, setFaqOpen] = useState(false);
  const [reviewsOpen, setReviewsOpen] = useState(false);
  const [worksOpen, setWorksOpen] = useState(false);

  const handleFormSubmit = useCallback(
    (event) => {
      event.preventDefault();

      handleSubmit(values);
    },
    [handleSubmit, values]
  );

  const handleSuccessfulUpload = useCallback(
    (url) => {
      setFieldValue("avatarUrl", url);
    },
    [setFieldValue]
  );

  const removeImage = () => {
    setFieldValue("avatarUrl", "");
  };

  const handleSave = (event) => {
    handleFormSubmit(event);
    navigate(-1);
  };

  const defaultFaqs = useMemo(
    () => [
      { id: 1, content: "Can I gut check my strategy with you?" },
      {
        id: 2,
        content:
          "I'd love some inspiration on how to go from where I am, to where you are in my journey- how do I get there?",
      },
      {
        id: 3,
        content: "Can you share feedback on this challenge I'm working on?",
      },
    ],
    []
  );

  useEffect(() => {
    if (!values?.faqs) {
      setFieldValue("faqs", defaultFaqs);
    }
  }, [defaultFaqs, values?.faqs, setFieldValue]);

  return (
    <>
      <div className={styles.header}>
        <Stack direction="row">
          <BackButton />
          <img src={beepLogo} alt="logo" width={87} height={22} />
        </Stack>
        <Typography variant="body2" fontWeight={600}>
          Edit profile
        </Typography>
      </div>
      <div className={styles.container}>
        <Stack className="stackLayout" my="20px">
          <Typography fontFamily="Archivo" fontSize="18px" fontWeight={900}>
            Your Profile
          </Typography>
          <Button
            onClick={() => navigate("/creator/preview")}
            sx={{
              minWidth: 30,
              height: 30,
              background: "#000 !important",
              color: "#fff !important",
              borderRadius: "30px !important",
            }}
          >
            <PreviewIcon />
          </Button>
        </Stack>
        <Box my={2}>
          <AvatarUpload
            onSuccessfulUpload={handleSuccessfulUpload}
            removeImage={removeImage}
            url={values.avatarUrl}
            size="medium"
            noText
            showButtons
            ctaText="Change profile photo"
          />
        </Box>
        <Box
          border="1px solid #D9D9D9"
          borderRadius="8px"
          p="12px"
          height="auto"
          mt={3}
        >
          <Stack className="stackLayout">
            <Typography variant="header1">Headline</Typography>{" "}
            <Button
              className={styles.editButton}
              onClick={() => setHeadlineOpen(true)}
            >
              Edit
            </Button>
          </Stack>
          <Stack width={"85%"} mt={1}>
            {values?.briefBio &&
              values?.briefBio?.brandName &&
              values?.briefBio?.topic1 &&
              values?.briefBio?.topic2 && (
                <Typography variant="body2">
                  From the mind behind {values?.briefBio?.brandName},{" "}
                  {values?.firstName} advises you on {values?.briefBio?.topic1}{" "}
                  and {values?.briefBio?.topic2}
                </Typography>
              )}
          </Stack>
        </Box>
        <Box>
          <Box
            border="1px solid #D9D9D9"
            borderRadius="8px"
            p="12px"
            height="auto"
            mt={4}
          >
            <Stack className="stackLayout">
              <Typography variant="header1">Bio</Typography>{" "}
              <Button
                className={styles.editButton}
                onClick={() => setBioOpen(true)}
              >
                Edit
              </Button>
            </Stack>
            <Stack width={"85%"} mt={1}>
              <Typography variant="body2">{values?.fullBio}</Typography>
            </Stack>
          </Box>
        </Box>
        <Box
          border="1px solid #D9D9D9"
          borderRadius="8px"
          px="12px"
          pt="12px"
          height="auto"
          mt={4}
        >
          <Stack className="stackLayout">
            <Typography variant="header1">FAQ</Typography>
            <Button
              className={styles.editButton}
              onClick={() => setFaqOpen(true)}
            >
              Edit
            </Button>
          </Stack>
          <Box>
            {values?.faqs?.map((faq) => (
              <Stack
                key={faq?.id}
                direction="row"
                alignItems={"start"}
                justifyContent="space-between"
                my={2}
                gap={1}
                width={"85%"}
              >
                <Typography variant="body2">{faq?.content}</Typography>
              </Stack>
            ))}
          </Box>

          <Button
            className={styles.addButton}
            startIcon={<Plus />}
            onClick={() => setFaqOpen(true)}
          >
            Add more
          </Button>
        </Box>
        <Box
          border="1px solid #D9D9D9"
          borderRadius="8px"
          px="12px"
          pt="12px"
          height="auto"
          mt={4}
        >
          <Stack className="stackLayout">
            <Stack direction="row" gap={1} alignItems="center">
              <Typography variant="header1">You can Advise on</Typography>
            </Stack>
          </Stack>
          <Box display="flex" flexWrap="wrap" mt="20px" gap={1} width="95%">
            {values?.expertise
              ?.filter((option) => option)
              ?.map((option, index) => (
                <Box
                  key={index}
                  sx={{
                    background: "#F3F3F4",
                    color: "#000",
                    borderRadius: "50px",
                    padding: "14px 16px",
                    mb: "5px",
                    fontSize: "14px",
                    fontFamily: "Lato",
                  }}
                >
                  {expertiseLookup[option?.title || option]}{" "}
                  {option?.title || option}
                </Box>
              ))}
          </Box>
          <Button
            className={styles.addButton}
            startIcon={<Plus />}
            onClick={() => setExpertiseOpen(true)}
          >
            Add more
          </Button>
        </Box>
        <Box
          border="1px solid #D9D9D9"
          borderRadius="8px"
          px="12px"
          py="12px"
          height="auto"
          mt={4}
        >
          <Stack className="stackLayout">
            <Typography variant="header1">Your Reviews</Typography>
            <Button
              className={styles.editButton}
              onClick={() => setReviewsOpen(true)}
            >
              Edit
            </Button>
          </Stack>
          <Box>
            {values?.reviews?.map((review, index) => (
              <Stack
                key={index}
                direction="row"
                alignItems={"start"}
                justifyContent="space-between"
                my={2}
                gap={1}
                width={"85%"}
              >
                <Typography variant="body2">{review?.content}</Typography>
              </Stack>
            ))}
          </Box>
        </Box>
        <Box
          border="1px solid #D9D9D9"
          borderRadius="8px"
          px="12px"
          pt="12px"
          height="auto"
          mt={4}
        >
          <Stack className="stackLayout">
            <Typography variant="header1">Your Social Links</Typography>
            <Button
              className={styles.editButton}
              onClick={() => setSocialsOpen(true)}
            >
              Edit
            </Button>
          </Stack>
          <Stack
            direction="row"
            alignItems={"start"}
            justifyContent="space-between"
            mt={2}
          >
            {values?.socialLinks && (
              <>
                <SocialLinks linksValue={values?.socialLinks} />
              </>
            )}
          </Stack>
          <Button
            className={styles.addButton}
            startIcon={<Plus />}
            onClick={() => setSocialsOpen(true)}
          >
            Add more
          </Button>
        </Box>
        <Box
          border="1px solid #D9D9D9"
          borderRadius="8px"
          px="12px"
          pt="12px"
          height="auto"
          mt={4}
        >
          <Stack className="stackLayout">
            <Typography variant="header1">Work Experience</Typography>
            <Button
              className={styles.editButton}
              onClick={() => setWorksOpen(true)}
            >
              Edit
            </Button>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"start"}
          >
            <Stack gap={1} mt={2}>
              {values?.workExperience?.map((work) => (
                <Stack key={work?.id} direction="row" alignItems="center">
                  <img
                    src={work?.imageUrl}
                    height={25}
                    width={50}
                    style={{
                      objectFit: "contain",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "2px",
                    }}
                    alt="work"
                  />
                  <Typography variant="body2" marginLeft={"10px"}>
                    {work?.companyName}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>

          <Button
            className={styles.addButton}
            startIcon={<Plus />}
            onClick={() => setWorksOpen(true)}
          >
            Add more
          </Button>
        </Box>
        <UpdateHeadline
          value={values?.briefBio}
          setFieldValue={setFieldValue}
          open={headlineOpen}
          setOpen={setHeadlineOpen}
          onSubmit={handleFormSubmit}
        />
        <UpdateBio
          value={values?.fullBio}
          setFieldValue={setFieldValue}
          open={bioOpen}
          setOpen={setBioOpen}
          onSubmit={handleFormSubmit}
        />
        <UpdateExpertise
          expertise={values?.expertise}
          setFieldValue={setFieldValue}
          open={expertiseOpen}
          setOpen={setExpertiseOpen}
          backgroundValue={values?.expertiseBackground}
          onSubmit={handleFormSubmit}
        />
        <UpdateSocialLinks
          value={values?.socialLinks}
          setFieldValue={setFieldValue}
          open={socialsOpen}
          setOpen={setSocialsOpen}
          onSubmit={handleFormSubmit}
        />
        <UpdateFaq
          open={faqOpen}
          setOpen={setFaqOpen}
          value={values?.faqs}
          setFieldValue={setFieldValue}
          defaultFaqs={defaultFaqs}
          onSubmit={handleFormSubmit}
        />
        <UpdateReviews
          open={reviewsOpen}
          setOpen={setReviewsOpen}
          value={values?.reviews}
          setFieldValue={setFieldValue}
          onSubmit={handleFormSubmit}
        />
        <UpdateWork
          value={values?.workExperience}
          open={worksOpen}
          setOpen={setWorksOpen}
          setFieldValue={setFieldValue}
          onError={onError}
          onSubmit={handleFormSubmit}
        />
        <Stack gap={2} my={4}>
          <CustomButton onClick={handleSave}>Save</CustomButton>
          <CustomButton variant="secondary" onClick={() => navigate(-1)}>
            Cancel
          </CustomButton>
        </Stack>
      </div>
    </>
  );
};

UpdateProfileForm.propTypes = {
  isOpen: PropTypes.bool,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  onLoginClick: PropTypes.func,
  onError: PropTypes.func,
  errors: PropTypes.string,
  touched: PropTypes.func,
  values: PropTypes.any,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => initialValues,
  validationSchem: {},
  handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
    onSubmit(values);
    setSubmitting(false);
  },
  displayName: "UpdateProfileForm",
})(UpdateProfileForm);
