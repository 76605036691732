import PropTypes from "prop-types";
import BottomDrawer from "../../BottomDrawer";
import MuiPhoneNumber from "material-ui-phone-number";
import { useCallback } from "react";
import CustomButton from "../../CustomButton";
import { withFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as Exit } from "../../../img/exit.svg";
import styles from "./PhoneNumberDrawer.module.css";

const phoneRules =
  /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/;

const INITIAL_VALUES = {
  phoneNotifications: true,
};
const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(phoneRules, "This is not a valid phone number")
    .required("Phone number is required"),
});

const PhoneNumberDrawer = ({
  isOpen,
  onClose = () => {},
  errors,
  touched,
  loading,
  setFieldTouched,
  setFieldValue,
  handleSubmit,
}) => {
  const handlePhoneInput = useCallback(
    (value) => {
      let phoneNumber = value.replace(/-|\s|([()])/g, "");
      setFieldTouched("phoneNumber", true);
      setFieldValue("phoneNumber", phoneNumber);
    },
    [setFieldValue, setFieldTouched]
  );

  return (
    <BottomDrawer
      isOpen={isOpen}
      onClose={onClose}
      canExit={false}
      title="Turn on phone notifications"
    >
      <div className={styles.header}>
        <Exit className={styles.exit} onClick={onClose} />
      </div>
      <form onSubmit={handleSubmit} className={styles.modalForm}>
        <h3 className={styles.title}>Turn on phone notifications</h3>
        <div className={styles.inputs}>
          <MuiPhoneNumber
            onChange={handlePhoneInput}
            name="phoneNumber"
            label="ENTER PHONE NUMBER"
            defaultCountry="us"
            fullWidth
          />
          {errors.phoneNumber && touched.phoneNumber && (
            <p className="error-message">{errors.phoneNumber}</p>
          )}
        </div>
        <CustomButton type="submit" disabled={loading} loading={loading}>
          Save
        </CustomButton>
      </form>
    </BottomDrawer>
  );
};

PhoneNumberDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withFormik({
  mapPropsToValues: () => INITIAL_VALUES,
  validationSchema,
  handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
    return onSubmit(values).then(() => setSubmitting(false));
  },
  displayName: "PhoneNumberDrawer",
})(PhoneNumberDrawer);
