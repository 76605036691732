import React, { useCallback, useEffect, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Box, Stack, Typography } from "@mui/material";
import { ReactComponent as Arrow } from "../../../../img/date-arrow.svg";
import { ReactComponent as Clock } from "../../../../img/clock.svg";
import { ReactComponent as DebitCard } from "../../../../img/debit-card.svg";
import { ReactComponent as Calendar } from "../../../../img/calendar.svg";
import useMutate from "../../../../hooks/useMutate";
import { format } from "date-fns";
import TimeZoneIcon from "../../../../components/icons/TimeZoneIcon";
import CustomButton from "../../../../components/CustomButton";
import * as Yup from "yup";

const contactDetailsSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters"),
});

export default function BookingDetails({
  creator,
  user,
  order,
  setOrder,
  handleBack,
  selectedTime,
  selectedDate,
  timeZone,
  handleNext,
  startTimeFormatted,
  endTimeFormatted,
}) {
  const [contactDetails, setContactDetails] = useState({
    name: user?.name || "",
    email: user?.email || "",
    phone: user?.phone || "",
  });
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [description, setDescription] = useState("");
  const [createOrderGuest] = useMutate("/public/order", { auth: false });
  const [createBooking] = useMutate("/public/booking", { auth: false });
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const createBookingOrderGuest = useCallback(async () => {
    if (!order) {
      const { data: orderData } = await createOrderGuest({
        type: "BOOKING",
        duration: 30,
        creatorId: creator?.id,
      });
      setOrder(orderData);
    }
  }, [order, creator?.id, createOrderGuest, setOrder]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContactDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (name === "email") {
      setEmailError("");
    } else if (name === "name") {
      setNameError("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await contactDetailsSchema.validate(contactDetails);
      setLoading(true);
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setLoading(false);
        return;
      }

      const { data: bookingData } = await createBooking({
        orderId: order?.id,
        startDate: selectedTime,
        timezone: timeZone,
        description: description,
        contactDetails: contactDetails,
      });

      if (bookingData) {
        const { error } = await stripe.confirmPayment({
          elements,
          clientSecret: order?.clientSecret,
          confirmParams: {
            return_url: window.location.href,
          },
          redirect: "if_required",
        });
        if (error) {
          setLoading(false);
          return;
        }
      } else {
        setLoading(false);
      }
      setLoading(false);
      handleNext();
    } catch (error) {
      setLoading(false);
      if (error.path === "email") {
        setEmailError(error.message);
      } else if (error.path === "name") {
        setNameError(error.message);
      }
    }
  };

  useEffect(() => {
    createBookingOrderGuest();
  }, [createBookingOrderGuest]);

  return (
    <Stack px={1} mb={2}>
      <Stack
        direction={"row"}
        alignItems="center"
        sx={{
          width: "100%",
          borderRadius: "16px",
          background: "#f2f2f2",
          py: 2,
          px: 1,
          mb: 2,
          boxSizing: "border-box",
        }}
        gap={1}
      >
        <Arrow
          onClick={handleBack}
          style={{ position: "absolute", cursor: "pointer" }}
        />
        <Box flexGrow={1} textAlign="center">
          <Typography variant="header1" fontSize={"20px"}>
            30 Minute Meeting
          </Typography>
        </Box>
      </Stack>
      <Stack gap={1.5} mb={3} mt={1} borderBottom="1px solid #717192" pb={1.5}>
        <Stack direction={"row"} alignItems="center" gap={1}>
          <Clock />
          <Typography variant="body2">30 Minutes</Typography>
        </Stack>
        <Stack direction={"row"} alignItems="center" gap={1}>
          <DebitCard />
          <Typography variant="body2">
            ${order?.total / 100 || creator?.videoCallHourlyRate / 2} USD
          </Typography>
        </Stack>
        <Stack direction={"row"} alignItems="start" gap={1}>
          <Calendar />
          <Typography variant="body2" lineHeight={"16px"}>
            {startTimeFormatted} – {endTimeFormatted} <br />
            {format(selectedDate, "EEEE, MMMM d, yyyy")}
          </Typography>
        </Stack>
        <Stack direction={"row"} alignItems="center" gap={1}>
          <TimeZoneIcon />
          <Typography variant="body2">{timeZone}</Typography>
        </Stack>
      </Stack>

      <form onSubmit={handleSubmit}>
        <Typography variant="body1" fontWeight={700} fontSize="18px" mb={2}>
          Enter Details
        </Typography>
        <Stack mb={3} pb={2} gap={1} borderBottom="1px solid #717192">
          <Box>
            <Typography variant="body2" color={"#30313C"} my={1}>
              Name*
            </Typography>
            <input
              name="name"
              type={"text"}
              value={contactDetails.name}
              onChange={handleChange}
              className={`details-input ${nameError && "error"}`}
            />
            {nameError && (
              <Typography
                variant="body2"
                fontSize={"13px"}
                mt={0.5}
                color="red"
              >
                {nameError}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography variant="body2" color={"#30313C"} my={1}>
              Email*
            </Typography>
            <input
              name="email"
              type={"email"}
              value={contactDetails.email}
              onChange={handleChange}
              className={`details-input ${emailError && "error"}`}
            />
            {emailError && (
              <Typography
                variant="body2"
                fontSize={"13px"}
                mt={0.5}
                color="red"
              >
                {emailError}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography variant="body2" color={"#30313C"} my={1}>
              Phone number <i>(not required)</i>
            </Typography>
            <input
              name="phone"
              type={"tel"}
              value={contactDetails.phone}
              onChange={handleChange}
              className="details-input"
            />
          </Box>
          <Box>
            <Typography variant="body2" color={"#30313C"} my={1}>
              Please share any details that will help me to prepare <br /> for
              our meeting.
            </Typography>
            <textarea
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              resize="vertical"
              className="details-input textarea"
            />
          </Box>
        </Stack>
        <Typography variant="body2" fontWeight={700} mb={2}>
          Payment Information*
        </Typography>
        <PaymentElement />
        <CustomButton
          type="submit"
          loading={loading}
          sx={{ mt: 3, background: "#000 !important" }}
        >
          Schedule Call
        </CustomButton>
      </form>
    </Stack>
  );
}
