import React, { useCallback, useContext, useEffect } from "react";
import { withFormik } from "formik";
import PropTypes from "prop-types";
import Template from "../Template";
import { Box, Stack, Typography } from "@mui/material";
import { SIGNUP_STEPS } from "../../../../../lib/signup";
import zoom from "../../../../../img/zoom.png";
import googleMeet from "../../../../../img/google-meet.png";
import CustomButton from "../../../../../components/CustomButton";
import LoadingIconButton from "../../../../../components/LoadingIconButton";
import DeleteIcon from "../../../../../components/icons/DeleteIcon";
import SuccessIcon from "../../../../../components/icons/SuccessIcon";
import useFetch from "../../../../../hooks/useFetch";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import { UserContext } from "../../../../../context";
import useMutate from "../../../../../hooks/useMutate";
import { useSearchParams } from "react-router-dom";

const ConferencingForm = ({
  values,
  setSubmitting,
  handleSubmit,
  activeStep,
  isSubmitting,
}) => {
  const { data, loading } = useFetch("/user/connected-apps");
  const { user, onError } = useContext(UserContext);
  const [connectConferencing, { loading: connecting }] = useLazyFetch(
    `/conferencing/connect/zoom`
  );
  const [revokeToken, { loading: revoking }] = useMutate(`/token/revoke`, {
    method: "PUT",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const success = searchParams.get("success");
  const error = searchParams.get("error");

  useEffect(() => {
    if (success || error) {
      if (error) onError("Unable to connect calendar");
      searchParams.delete("success");
      searchParams.delete("error");
      setSearchParams(searchParams);
    }
  }, [success, error, onError, searchParams, setSearchParams]);

  const onFormSubmit = useCallback(async () => {
    if (isSubmitting) return;
    setSubmitting(true);

    await handleSubmit(values);
  }, [handleSubmit, setSubmitting, values, isSubmitting]);

  const handleConnectConferencing = useCallback(async () => {
    const { data, error } = await connectConferencing();
    if (error) return onError(error);
    if (data && data.url) {
      window.location.replace(data.url);
    }
  }, [connectConferencing, onError]);

  const handleRemoveCalendar = useCallback(async () => {
    await revokeToken({
      type: "ZOOM_CONFERENCING",
      userId: user?.creator?.id,
    }).then(async (response) => {
      if (response?.error) {
        onError(response?.error || "Something went wrong. Please try again.");
        return;
      }
    });
    connectConferencing();
  }, [onError, revokeToken, connectConferencing, user?.creator?.id]);

  const zoomConnection = data?.connections?.find(
    (app) => app.type === "ZOOM_CONFERENCING"
  );

  return (
    <Template
      title="Connect video apps"
      subtitle="Connect your favorite video apps so fans can easily book calls with you."
      step={activeStep}
      isRequired={false}
      onSubmit={onFormSubmit}
    >
      {!loading && (
        <Stack gap={2}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            gap={1}
            sx={{
              borderRadius: "8px",
              border: "1px solid #D9D9D9",
              padding: "7px 12px",
            }}
          >
            <Stack
              direction="row"
              gap={1}
              alignItems={zoomConnection ? "start" : "center"}
            >
              <img src={zoom} alt="Zoom" width={28} height={28} />
              <Box>
                <Typography variant="body1">Zoom</Typography>
                {zoomConnection && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#717192",
                      fontSize: "12px",
                    }}
                  >
                    {zoomConnection.email}
                  </Typography>
                )}
                {zoomConnection && (
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    gap={0.5}
                    mt="3px"
                  >
                    <SuccessIcon />
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#717192",
                        fontSize: "12px",
                        fontWeight: 900,
                      }}
                    >
                      Connected
                    </Typography>
                  </Stack>
                )}
              </Box>
            </Stack>
            {zoomConnection ? (
              <LoadingIconButton
                sx={{
                  height: "fit-content",
                  mt: "-12px",
                  mr: "-5px",
                }}
                onClick={handleRemoveCalendar}
                loading={revoking}
              >
                <DeleteIcon />
              </LoadingIconButton>
            ) : (
              <CustomButton
                sx={{
                  width: "83px",
                  minHeight: "30px !important",
                  height: "30px !important",
                  fontSize: "15px",
                }}
                onSubmit={handleConnectConferencing}
                loading={connecting}
              >
                Connect
              </CustomButton>
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            gap={1}
            sx={{
              borderRadius: "8px",
              border: "1px solid #D9D9D9",
              padding: "7px 12px",
            }}
          >
            <Stack direction="row" gap={1} alignItems={"center"}>
              <img src={googleMeet} alt="Zoom" width={28} height={28} />
              <Box>
                <Typography variant="body1">Google Meet</Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: "#717192",
                    fontSize: "12px",
                  }}
                >
                  Gmail or Google Workspace
                </Typography>
              </Box>
            </Stack>
            <CustomButton
              disabled
              sx={{
                width: "114px",
                minHeight: "30px !important",
                height: "30px !important",
                fontSize: "15px",
              }}
            >
              Coming Soon
            </CustomButton>
          </Stack>
          <Typography variant="body2">
            Heads Up ⚠️: Without integrating a Video Call tool, you will need to
            add it manually to your calendar invite in order to offer 1:1 Video
            Calls.
          </Typography>
        </Stack>
      )}
    </Template>
  );
};

ConferencingForm.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setSubmitting: PropTypes.func,
  error: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.func,
  loading: PropTypes.bool,
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => initialValues,
  validationSchem: {},
  handleSubmit: (values, { props: { onSubmit, activeStep } }) => {
    return onSubmit(
      null,
      `/creator/onboarding/${SIGNUP_STEPS[activeStep + 1]}`
    );
  },
  displayName: "ConferencingForm",
})(ConferencingForm);
