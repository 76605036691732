import { Stack } from "@mui/material";
import React from "react";
import { ReactComponent as BeepLogo } from "../../img/logo-completed.svg";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

export default function Footer() {
  return (
    <Stack
      alignItems={"center"}
      gap={10}
      sx={{ height: "400px", background: "#1b1c27", py: 10 }}
    >
      <BeepLogo
        style={{
          height: 50,
          width: 150,
        }}
      />
      <Stack gap={5}>
        <Link to="/" className={styles.footerLink}>
          Start earning on Beep
        </Link>
        <a href="mailto:beep@beepmehere.com" className={styles.footerLink}>
          Contact Us
        </a>
        <Link to="/terms" className={styles.footerLink}>
          Terms of Service
        </Link>
        <Link to="/privacy" className={styles.footerLink}>
          Privacy Policy
        </Link>
      </Stack>
    </Stack>
  );
}
