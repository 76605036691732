import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as BeepLogo } from "../../img/logo-with-text.svg";
import { ReactComponent as Exit } from "../../img/exit.svg";

import BottomDrawer from "../../components/BottomDrawer";
import SignupForm from "../SignupForm";
import { Stack } from "@mui/material";

const SignupModal = ({ isOpen, onClose, experience, setExperience }) => {
  return (
    <BottomDrawer isOpen={isOpen} canExit={false} showHandle>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <BeepLogo width={92} height={23} />
        <Exit
          style={{
            width: "24px",
            height: "24px",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
      </Stack>
      <SignupForm
        onSuccess={onClose}
        experience={experience}
        setExperience={setExperience}
      />
    </BottomDrawer>
  );
};

SignupModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SignupModal;
