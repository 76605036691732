import React from "react";

export default function VideoCallIcon({ fillWhite }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="17"
      viewBox="0 0 28 17"
      fill="none"
    >
      <rect
        y="0.211914"
        width="18.5557"
        height="16.7885"
        rx="4"
        fill={fillWhite ? "white" : "black"}
      />
      <path
        d="M20.7527 9.02286L20.7527 6.48516L26.3027 1.4988C26.9464 0.920458 27.971 1.37731 27.971 2.24267L27.971 14.4009C27.971 15.1939 27.0923 15.6714 26.427 15.24L20.7527 11.5606L20.7527 9.02286Z"
        fill={fillWhite ? "white" : "black"}
      />
    </svg>
  );
}
