import { Stack, Typography } from "@mui/material";
import React from "react";

export default function CompleteProfile({ title, description, openEdit, sx }) {
  return (
    <Stack
      sx={{
        ...sx,
        background: "gba(81, 81, 81, 0.50)",
        borderRadius: "20px",
        border: "2px dashed #888",
        padding: "32px 16px",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Typography
        variant="body1"
        color={"#fff"}
        sx={{ textDecoration: "underline" }}
        onClick={() => openEdit(true)}
      >
        {title}
      </Typography>
      <Typography variant="body2" color={"#aaa"}>
        {description}
      </Typography>
    </Stack>
  );
}
