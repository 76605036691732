import React, { useState } from "react";
import styles from "./EmptyTabState.module.css";
import { Box, Stack, Typography } from "@mui/material";
import CustomButton from "../CustomButton";
import PreviewIcon from "../icons/PreviewIcon";
import { useNavigate } from "react-router-dom";
import ShareLink from "../ShareLink";

export default function EmptyTabState({ userInfo, title, isVideoTab }) {
  const navigate = useNavigate();
  const [openShare, setOpenShare] = useState(false);
  const creatorLink = `${window.location.host}/${userInfo?.handle}`;

  return (
    <>
      <Stack alignItems="center" mt={6} px={2}>
        {!isVideoTab && (
          <>
            <Typography fontSize="22px" fontFamily="Archivo" fontWeight={900}>
              {title}
            </Typography>
            <Typography variant="body2">
              Share your Beep link to get started
            </Typography>
            <CustomButton
              onClick={() => setOpenShare(true)}
              style={{ marginTop: "30px" }}
            >
              Share your Beep link on Social
            </CustomButton>
          </>
        )}

        <Stack
          className={styles.previewContainer}
          width="100%"
          height={"200px"}
          borderRadius="15px"
          position="relative"
          mt={isVideoTab ? 0 : 5}
        >
          <Stack direction={"column"} className={styles.iphoneMockup}>
            {userInfo?.avatarUrl ? (
              <Box className={styles.iphoneContent}>
                <img
                  src={userInfo?.avatarUrl}
                  alt="avatar"
                  width={"85%"}
                  height={"97%"}
                  style={{
                    borderTopRightRadius: 20,
                    borderTopLeftRadius: 20,
                    objectFit: "cover",
                    marginTop: 5,
                    position: "relative",
                  }}
                />
                <Box
                  position="absolute"
                  width="100%"
                  left="10%"
                  bottom="-5%"
                  color="#fff"
                >
                  <Typography
                    sx={{
                      fontFamily: "Archivo",
                      fontWeight: 900,
                      fontSize: "18px",
                      lineHeight: "20px",
                    }}
                  >
                    {userInfo?.firstName}
                    <br /> {userInfo?.lastName}
                  </Typography>
                  {userInfo?.briefBio &&
                    userInfo?.briefBio?.brandName &&
                    userInfo?.briefBio?.topic1 &&
                    userInfo?.briefBio?.topic2 && (
                      <Typography width={"80%"} variant="body2" fontSize="10px">
                        From the mind behind {userInfo?.briefBio?.brandName},{" "}
                        {userInfo?.firstName} advises you on{" "}
                        {userInfo?.briefBio?.topic1} and{" "}
                        {userInfo?.briefBio?.topic2}
                      </Typography>
                    )}
                </Box>
              </Box>
            ) : (
              <Box
                bgcolor={"#000"}
                height={"93%"}
                width="87%"
                mt={1.2}
                ml={1.2}
                borderRadius={"15px 15px 0px 0px"}
                textAlign="center"
              >
                <Box position="absolute" width="86%" top={"15%"} color="#fff">
                  <Typography
                    variant="header1"
                    fontSize={"30px"}
                    color="#fff"
                    className="image-text"
                  >
                    {userInfo?.firstName}
                    <br /> {userInfo?.lastName}
                  </Typography>
                  {userInfo?.briefBio &&
                    userInfo?.briefBio?.brandName &&
                    userInfo?.briefBio?.topic1 &&
                    userInfo?.briefBio?.topic2 && (
                      <Typography variant="body2" fontSize="10px" mt={3.5}>
                        From the mind behind {userInfo?.briefBio?.brandName},{" "}
                        {userInfo?.firstName} advises you on{" "}
                        {userInfo?.briefBio?.topic1} and{" "}
                        {userInfo?.briefBio?.topic2}
                      </Typography>
                    )}
                </Box>
              </Box>
            )}
          </Stack>
          <Box
            sx={{
              width: "100%",
              background:
                "linear-gradient(180deg, rgba(0, 19, 69, 0.00) 0%, rgba(0, 19, 69, 0.80) 91.33%, #001345 125.97%)",
              position: "absolute",
              bottom: 0,
              pb: 4,
              display: "flex",
              justifyContent: "center",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
          >
            <CustomButton
              onClick={() => navigate("/creator/preview")}
              sx={{
                width: 204,
                height: 48,
                background: "#fff !important",
                color: "#000 !important",
              }}
              startIcon={<PreviewIcon fill={"#000"} />}
            >
              Preview my page
            </CustomButton>
          </Box>
        </Stack>
        {!isVideoTab && (
          <ShareLink
            creatorName={`${userInfo?.firstName} ${userInfo?.lastName}`}
            link={creatorLink}
            open={openShare}
            setOpen={setOpenShare}
          />
        )}
      </Stack>
    </>
  );
}
