import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import "./index.css";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import styles from "./CreatorHeader.module.css";
import { ReactComponent as Account } from "../../../../img/account.svg";
import { ReactComponent as LinkIcon } from "../../../../img/profileMenu/link-icon.svg";
import { ReactComponent as ProfileIcon } from "../../../../img/profileMenu/profile-icon.svg";
import { ReactComponent as AvailabilityIcon } from "../../../../img/profileMenu/availability-icon.svg";
import { ReactComponent as EarningsIcon } from "../../../../img/profileMenu/earnings.svg";
import { ReactComponent as DmIcon } from "../../../../img/profileMenu/dm.svg";
import { ReactComponent as IntegrationIcon } from "../../../../img/profileMenu/integration-icon.svg";
import { ReactComponent as HelpIcon } from "../../../../img/profileMenu/help-icon.svg";
import { ReactComponent as LogoutIcon } from "../../../../img/profileMenu/logout-icon.svg";
import { ReactComponent as SettingsIcon } from "../../../../img/profileMenu/settings-icon.svg";
import stripeBadge from "../../../../img/stripe-badge.png";
import beepLogo from "../../../../img/logo-with-text.svg";
import profileArrow from "../../../../img/profile-arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../context";
import BottomDrawer from "../../../../components/BottomDrawer";
import useMutate from "../../../../hooks/useMutate";
import CustomButton from "../../../../components/CustomButton";

const CreatorHeader = ({
  avatarUrl,
  onError,
  onStripeLogin,
  refetchBalance,
  bankRef,
  balance,
  pendingBalance,
  isStripeSetup,
  setOpenShare,
}) => {
  const { logout } = useContext(UserContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [payoutsOpen, setPayoutsOpen] = useState(false);
  const [payout, { loading }] = useMutate(`/creator/payout`, {
    method: "POST",
  });
  const openMenu = anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const fee = Math.round(250 + (balance?.amount || 0) * 0.025);

  const handleTransfer = () => {
    payout({
      amount: balance?.amount,
    }).then(() => {
      refetchBalance();
      setPayoutsOpen(false);
    });
  };

  const handleGoToProfile = () => {
    handleCloseMenu();
    navigate("/creator/profile");
  };

  const handleGoToAccount = () => {
    handleCloseMenu();
    navigate("/creator/account");
  };

  const handleShareLink = () => {
    handleCloseMenu();
    setOpenShare(true);
  };

  const handleGoToServices = () => {
    handleCloseMenu();
    navigate("/creator/services");
  };

  const handleGoToIntegrations = () => {
    handleCloseMenu();
    navigate("/creator/integrations");
  };

  const handleGoToAvailability = () => {
    handleCloseMenu();
    navigate("/creator/availability");
  };

  const handleGoToWelcome = () => {
    handleCloseMenu();
    navigate("/creator/welcome");
  };

  const handleGoToHelp = () => {
    handleCloseMenu();
    navigate("/creator/help");
  };

  const handleLogout = () => {
    handleCloseMenu();
    logout();
  };

  return (
    <div className="creator-header">
      <img src={beepLogo} alt="logo" width={87} height={22} />
      <div>
        <Button
          ref={bankRef}
          onClick={() => setPayoutsOpen(true)}
          startIcon={<Account />}
          sx={{
            background:
              "linear-gradient(117deg, #03A1B7 30.2%, #3C4CE1 79.97%), #000",
            borderRadius: "80px !important",
            fontSize: "14px",
            fontWeight: 900,
            fontFamily: "Lato",
            textTransform: "none",
            color: "#fff",
            padding: "5px 12px !important",
          }}
        >
          Cash out
        </Button>

        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: "5px !important" }}
          aria-controls={openMenu ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? "true" : undefined}
        >
          <Avatar src={avatarUrl} alt="user avatar" className={styles.avatar} />
          <img
            src={profileArrow}
            style={{
              transform: openMenu ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
            }}
            alt="arrow"
          />
        </IconButton>
      </div>
      <BottomDrawer isOpen={payoutsOpen} onClose={() => setPayoutsOpen(false)}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Transfer Balance
        </Typography>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
          <Typography>Pending balance</Typography>
          <Typography>${(pendingBalance?.amount * 0.01).toFixed(2)}</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mb: balance?.amount > 2000 ? 1 : 3 }}
        >
          <Typography>Available to transfer</Typography>
          <Typography>${(balance?.amount * 0.01).toFixed(2)}</Typography>
        </Stack>
        {balance?.amount > 2000 && (
          <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
            <Typography>Stripe fees</Typography>
            <Typography>({(fee * 0.01).toFixed(2)})</Typography>
          </Stack>
        )}
        {balance?.amount > 2000 && (
          <Stack direction="row" justifyContent="space-between" sx={{ mb: 3 }}>
            <Typography>
              <b>Amount to transfer</b>
            </Typography>
            <Typography>
              <b>${((balance?.amount - fee) * 0.01).toFixed(2)}</b>
            </Typography>
          </Stack>
        )}
        <CustomButton
          disabled={balance?.amount < 2000}
          onSubmit={handleTransfer}
          loading={loading}
        >
          Transfer
        </CustomButton>
        {balance?.amount < 2000 && (
          <Typography sx={{ mt: 2, color: "red", textAlign: "center" }}>
            A minimum available amount of $20 is required to transfer.
          </Typography>
        )}
      </BottomDrawer>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            width: "229px",
            height: "auto",
            borderRadius: "8px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.25)",
            mt: 1.5,
            "& .MuiAvatar-root": {
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Link
          to="https://dashboard.stripe.com"
          target={"_blank"}
          onClick={handleCloseMenu}
          style={{ textDecoration: "none" }}
        >
          <MenuItem
            sx={{
              gap: 1,
              position: "relative",
              color: "#000 !important",
            }}
          >
            <EarningsIcon /> My earnings{" "}
            <Box sx={{ position: "absolute", right: "5%", bottom: "5%" }}>
              <img src={stripeBadge} alt="stripe-badge" />
            </Box>
          </MenuItem>
        </Link>

        <MenuItem onClick={handleShareLink} sx={{ gap: 1 }}>
          <LinkIcon /> Share my link
        </MenuItem>
        <MenuItem onClick={handleGoToProfile} sx={{ gap: 1 }}>
          <ProfileIcon />
          Edit Profile
        </MenuItem>
        <MenuItem onClick={handleGoToServices} sx={{ gap: 1 }}>
          <AvailabilityIcon /> Services & pricing
        </MenuItem>
        <MenuItem onClick={handleGoToAvailability} sx={{ gap: 1 }}>
          <AvailabilityIcon /> Availability
        </MenuItem>
        <MenuItem onClick={handleGoToIntegrations} sx={{ gap: 1 }}>
          <IntegrationIcon /> Integrations
        </MenuItem>
        <MenuItem onClick={handleGoToWelcome} sx={{ gap: 1 }}>
          <DmIcon />
          DM welcome message
        </MenuItem>
        <MenuItem onClick={handleGoToAccount} sx={{ gap: 1 }}>
          <SettingsIcon />
          Account Settings
        </MenuItem>
        <MenuItem onClick={handleGoToHelp} sx={{ gap: 1 }}>
          <HelpIcon /> Help
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout} sx={{ gap: 1 }}>
          <LogoutIcon /> Log out
        </MenuItem>
      </Menu>
    </div>
  );
};

CreatorHeader.propTypes = {
  avatarUrl: PropTypes.string,
  onError: PropTypes.func,
  onStripeLogin: PropTypes.func,
};

export default CreatorHeader;
