import React, { useContext, useEffect } from "react";

import './index.css';
import useMutate from "../../../hooks/useMutate";
import { useCreatorAnalytics } from "../../../hooks/useAnalytics";
import { UserContext } from "../../../context";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SignupForm from "./SignupForm";
import { SIGNUP_STEP_LOOKUP } from "../../../lib/signup";
import { pick } from '../../../lib/shared';

const CreatorSignup = () => {
  const { refreshUser, onError, user, loading } = useContext(UserContext);
  const { user: auth0User } = useAuth0();
  const [signup] = useMutate('/signup');
  const { platform, step } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const creatorPlatformId = searchParams.get('identity');
  const sendCreatorEvent = useCreatorAnalytics();
  const navigate = useNavigate();
  const [updateCreator] = useMutate(`/creator/${user?.creator?.id}`, { method: 'PUT' });
  const refresh = searchParams.get('refreshUser');

  const activeStep = SIGNUP_STEP_LOOKUP[step] || 0;

  // if onboarding is complete, redirect to home
  useEffect(() => {
    if (user?.isCreator) {
      if (user?.creator?.isStripeSetup) {
        // navigate('/creator/onboarding/completed');
      } else {
        // navigate to the appropriate page for what they have filled out
      }
    }
  }, [user, navigate]);

  useEffect(() => {
    if (refresh) {
      refreshUser();
      searchParams.delete('refreshUser');
      setSearchParams(searchParams);
    }
  }, [onError, refreshUser, refresh, searchParams, setSearchParams]);

  const handleSubmit = async (values, navigateTo) => {
    if (!values) {
      await refreshUser();
      if (navigateTo) navigate(navigateTo);
      return;
    }
    if (user?.creator?.id) {
      return updateCreator(values)
        .then(async (response) => {
          if (response?.error) {
            onError(response?.error || 'Something went wrong. Please try again.');
            return;
          }
          sendCreatorEvent({
            action: `onboarding step complete`,
            step: activeStep
          });

          await refreshUser();
          if (navigateTo) navigate(navigateTo);
        });
    } else {
      return signup({
        isCreator: true,
        email: auth0User?.email,
        platformSlug: platform,
        creatorPlatformId,
        ...values
      }).then(async (response) => {
        if (response?.error) {
          onError(response?.error || 'Something went wrong. Please try again.');
          return;
        }
        sendCreatorEvent({
          action: `onboarding step complete`,
          step: activeStep
        });

        await refreshUser();
        if (navigateTo) navigate(navigateTo);
      });
    }
  }

  if (loading) return null;

  return (
    <SignupForm
      onSave={handleSubmit}
      activeStep={activeStep}
      initialValues={{
        id: user?.id,
        creatorId: user?.creator?.id,
        ...pick([
          'username',
          'phoneNumber',
          'phoneNotifications',
          'firstName',
          'lastName',
        ], user),
        ...pick([
          'timezone',
          'schedule',
          'videoCallsEnabled',
          'messagesEnabled',
          'keynotesEnabled',
          'videoCallHourlyRate',
          'keynoteFee',
          'dmPrice',
          'keynoteTypesEnabled',
          'videoDurationsEnabled',
          'briefBio',
          'fullBio',
          'expertise',
        ], user?.creator)
      }}
    />
  )
}

export default CreatorSignup;