import React from "react";
import { Avatar, Skeleton, Badge, Typography } from "@mui/material";
import PropTypes from "prop-types";
import styles from "./Header.module.css";
import BackButton from "../../BackButton";

const Header = ({
  firstName,
  lastName,
  avatarUrl,
  isOnline,
  loading,
  backButton,
  onBack,
}) => {
  return (
    <div>
      {loading ? (
        <Skeleton variant="rectangular" height={50} />
      ) : (
        <div className={styles.header}>
          <div className={styles.headerContainer}>
            {backButton && <BackButton onClick={onBack} />}
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={<div className={styles.online} />}
              invisible={!isOnline}
            >
              <Avatar
                alt="User Avatar"
                src={avatarUrl}
                className={styles.chatHeaderAvatar}
              />
            </Badge>
            <div className={styles.title}>
              <Typography variant="body1" fontSize={"18px"} fontWeight={700}>
                {firstName} {lastName}
              </Typography>
              <Typography variant="body2" fontSize={"12px"} color={"#202020"}>
                {isOnline ? "Available now" : "Offline"}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatarUrl: PropTypes.string,
  loading: PropTypes.bool,
  backButton: PropTypes.bool,
  isOnline: PropTypes.bool,
};

export default Header;
