import React, { useState } from "react";
import "./index.css";
import { Box, InputLabel, Stack, Typography } from "@mui/material";
import BottomDrawer from "../BottomDrawer";
import CustomButton from "../CustomButton";
import CustomFormInput from "../CustomFormInput";

export default function UpdateHeadline({
  value,
  setFieldValue,
  open,
  setOpen,
  onSubmit,
}) {
  const [headlineValues, setHeadLineValues] = useState({
    brandName: value?.brandName || "",
    topic1: value?.topic1 || "",
    topic2: value?.topic2 || "",
  });

  const handleInputChange = (event, fieldName) => {
    const newValue = event.target.value;
    setHeadLineValues((prevValues) => ({
      ...prevValues,
      [fieldName]: newValue,
    }));
  };

  const handleSave = (event) => {
    setFieldValue("briefBio", headlineValues);
    setOpen(false);
    onSubmit(event);
  };

  const handleClose = () => {
    setOpen(false);
    setHeadLineValues(value);
  };

  return (
    <BottomDrawer
      isOpen={open}
      onClose={() => handleClose()}
      header="Headline"
      showHandle
      canExit={false}
      showBack
    >
      <Box mt={6}>
        <InputLabel variant="body1" sx={{ color: "#000", fontSize: "18px" }}>
          From the mind behind
        </InputLabel>
        <CustomFormInput
          name="brandName"
          sx={{ mb: 2, mt: 0, height: "50px !important" }}
          value={headlineValues.brandName}
          onChange={(event) => handleInputChange(event, "brandName")}
          multiline
          placeholder="Enter your brand or business"
          inputClassName="update-input"
        />
        <InputLabel variant="body1" sx={{ color: "#000", fontSize: "18px" }}>
          Test advises you on
        </InputLabel>
        <Stack direction={"row"} alignItems="center" gap={3}>
          <CustomFormInput
            name="topic1"
            sx={{
              mb: 2,
              mt: 0,
              height: "50px !important",
            }}
            value={headlineValues.topic1}
            onChange={(event) => handleInputChange(event, "topic1")}
            multiline
            placeholder="Enter a topic"
            inputClassName="update-input"
          />
          <Typography variant="body1" fontSize={"18px"}>
            and
          </Typography>
          <CustomFormInput
            name="topic2"
            sx={{
              mb: 2,
              mt: 0,
              height: "50px !important",
            }}
            value={headlineValues.topic2}
            onChange={(event) => handleInputChange(event, "topic2")}
            multiline
            placeholder="Enter a topic"
            inputClassName="update-input"
          />
        </Stack>
      </Box>
      <Stack direction={"row"} gap={2} mt={4}>
        <CustomButton variant="secondary" onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton
          onClick={handleSave}
          disabled={
            !headlineValues.brandName ||
            !headlineValues.topic1 ||
            !headlineValues.topic2
          }
        >
          Save
        </CustomButton>
      </Stack>
    </BottomDrawer>
  );
}
