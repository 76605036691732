import { Alert, Snackbar } from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { UserContext } from "../../../context";
import useMutate from "../../../hooks/useMutate";
import { pick } from "../../../lib/shared";
import SetAvailability from "./SetAvailability";
import styles from "./Availability.module.css";
import { useNavigate } from "react-router-dom";

const Availability = () => {
  const { user, refreshUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();
  const [updateCreator] = useMutate(`/creator/${user?.creator?.id}`, {
    method: "PUT",
  });

  const handleEdit = useCallback(
    async (values) => {
      setLoading(true);
      const response = await updateCreator({
        ...values,
        handle: values.username,
      });

      setLoading(false);
      if (response?.error) {
        setError(response?.error || "Something went wrong. Please try again.");
        return;
      }
      refreshUser();
      navigate(-1);
    },
    [updateCreator, navigate, refreshUser]
  );

  return (
    <div className={styles.container}>
      <Snackbar open={!!error} autoHideDuration={3000} onClose={setError}>
        <Alert
          className="error-alert"
          onClose={setError}
          severity="error"
          variant="filled"
        >
          {error}
        </Alert>
      </Snackbar>
      {user && (
        <SetAvailability
          initialValues={{
            id: user?.id,
            creatorId: user?.creator?.id,
            ...pick(["username", "phoneNumber", "firstName", "lastName"], user),
            ...pick(["schedule", "timezone", "workingHolidays"], user?.creator),
          }}
          userId={user?.id}
          onSubmit={handleEdit}
          loading={loading}
          onError={setError}
        />
      )}
    </div>
  );
};

export default Availability;
