import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";
import EmptyTabState from "../EmptyTabState";
import VideoCallPreview from "./VideoCallPreview";

export default function index({ user, bookings, loading, refetchVideoCalls }) {
  return (
    <Box px={1.5}>
      {loading && (
        <>
          <Skeleton
            variant="rectangular"
            className="chat-preview-loading"
            height={91}
          />
          <Skeleton
            variant="rectangular"
            className="chat-preview-loading"
            height={91}
          />
          <Skeleton
            variant="rectangular"
            className="chat-preview-loading"
            height={91}
          />
          <Skeleton
            variant="rectangular"
            className="chat-preview-loading"
            height={91}
          />
        </>
      )}

      {!loading && (
        <>
          {bookings?.upcomingMeetings.length > 0 ||
            (bookings?.completedMeetings.length > 0 && (
              <Typography variant="body2" fontWeight={900} my={2}>
                Upcoming ({bookings?.upcomingMeetings.length})
              </Typography>
            ))}
          {bookings?.upcomingMeetings?.map((booking) => (
            <VideoCallPreview
              key={booking?.id}
              booking={booking}
              creator={user?.creator}
            />
          ))}

          {bookings?.completedMeetings.length > 0 && (
            <>
              <Typography variant="body2" fontWeight={900} my={2}>
                Completed
              </Typography>
              {bookings?.completedMeetings?.map((booking) => (
                <VideoCallPreview
                  key={booking?.id}
                  booking={booking}
                  creator={user?.creator}
                  completed
                />
              ))}
            </>
          )}
          {bookings?.upcomingMeetings.length === 0 &&
          bookings?.completedMeetings.length === 0 ? (
            <EmptyTabState
              userInfo={user?.creator}
              title=" No video call bookings yet"
            />
          ) : (
            <EmptyTabState userInfo={user?.creator} isVideoTab />
          )}
        </>
      )}
    </Box>
  );
}
