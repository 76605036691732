import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import BottomDrawer from "../BottomDrawer";
import { ReactComponent as UploadIcon } from "../../img/upload.svg";
import CustomFormInput from "../CustomFormInput";
import CustomButton from "../CustomButton";
import { ReactComponent as Plus } from "../../img/plus.svg";
import "./index.css";
import camera from "../../img/camera.png";
import axios from "axios";
import { ReactComponent as Bin } from "../../img/bin.svg";
import { ReactComponent as Check } from "../../img/check.svg";

export default function UpdateWork({
  value = [{ id: 1, imageUrl: "", companyName: "" }],
  setFieldValue,
  open,
  setOpen,
  onError,
  onSubmit,
}) {
  const [worksValue, setWorksValue] = useState(value);
  const [isLoading, setIsLoading] = useState(false);
  const [isWorkAvailable, setIsWorkAvailable] = useState(true);
  const fileInputRef = React.createRef();

  const handleClose = () => {
    setOpen(false);
    setWorksValue(value);
  };

  const openFileDialog = () => {
    if (isLoading) return;
    fileInputRef.current.click();
  };

  const handleSuccessfulUpload = useCallback(
    (url) => {
      const updatedWorksValue = [...worksValue];
      const lastWorkIndex = updatedWorksValue.length - 1;
      updatedWorksValue[lastWorkIndex] = {
        ...updatedWorksValue[lastWorkIndex],
        imageUrl: url,
      };
      setWorksValue(updatedWorksValue);
    },
    [worksValue]
  );

  const handleAvatarUpload = useCallback(
    (evt) => {
      setIsLoading(true);
      const data = new FormData();
      data.append("file", evt.target.files[0]);
      data.append("upload_preset", "avatar");
      data.append("cloud_name", "creator-tools");
      axios("https://api.cloudinary.com/v1_1/creator-tools/image/upload", {
        method: "post",
        data,
      })
        .then((res) => {
          handleSuccessfulUpload(res.data.secure_url);
          setIsLoading(false);
        })
        .catch(() => {
          onError("Unable to upload image. Please try again.");
          setIsLoading(false);
        });
    },
    [onError, handleSuccessfulUpload]
  );

  const addWorkExperience = () => {
    setWorksValue([
      ...worksValue,
      { id: worksValue.length + 1, imageUrl: "", companyName: "" },
    ]);
  };

  const deleteWork = (id) => {
    if (worksValue.length > 1) {
      setWorksValue(worksValue.filter((work) => work.id !== id));
    }
  };

  const handleSave = (event) => {
    setFieldValue("workExperience", worksValue);
    setOpen(false);
    onSubmit(event);
  };

  useEffect(() => {
    // Check if there is at least one work experience with both a title and an image URL
    const hasValidWork = worksValue.some(
      (work) => work.companyName && work.imageUrl
    );
    setIsWorkAvailable(!hasValidWork);
  }, [worksValue]);

  return (
    <BottomDrawer
      isOpen={open}
      onClose={() => handleClose()}
      header="Work Expertise"
      showHandle
      canExit={false}
      showBack
    >
      <Stack mt={3}>
        {worksValue?.map((work, index) => (
          <div key={work.id}>
            <Stack className="stackLayout">
              <Typography variant="body2" my={1}>
                Work Experience {index + 1}
              </Typography>
              <Bin onClick={() => deleteWork(work.id)} cursor="pointer" />
            </Stack>

            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
              gap={1}
              sx={{
                height: work.imageUrl ? "auto" : "108px",
                borderRadius: "5px",
                border: "1.25px dashed #D9D9D9",
                pr: 3,
                py: work.imageUrl ? 1 : 0,
              }}
            >
              <Stack
                justifyContent={"center"}
                alignItems="center"
                sx={{
                  height: 25,
                  width: 25,
                  background: "#fff",
                  borderRadius: 40,
                  padding: "2px",
                  filter: "drop-shadow(0px 3px 10px rgba(45, 9, 121, 0.20));",
                  marginLeft: 1,
                }}
                onClick={openFileDialog}
              >
                <img src={camera} height={20} width={20} alt="camera" />
              </Stack>
              <Stack alignItems="center" mr={1}>
                {work.imageUrl ? (
                  <img src={work.imageUrl} height={50} alt="work" />
                ) : (
                  <UploadIcon />
                )}

                <input
                  type="file"
                  id={`avatarUpload-${work.id}`}
                  onChange={handleAvatarUpload}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                {!work.imageUrl && (
                  <Typography variant="body2" fontWeight={700}>
                    Add logo images
                  </Typography>
                )}
              </Stack>
              <Box />
            </Stack>

            <CustomFormInput
              name={`work-${work.id}`}
              sx={{ mb: 2 }}
              placeholder="Logo or company name"
              value={work.companyName}
              onChange={(e) => {
                const updatedWorksValue = [...worksValue];
                updatedWorksValue[index] = {
                  ...updatedWorksValue[index],
                  companyName: e.target.value,
                };
                setWorksValue(updatedWorksValue);
              }}
            />
          </div>
        ))}
        {!isWorkAvailable && (
          <Button
            className="addButton"
            startIcon={<Plus />}
            onClick={addWorkExperience}
          >
            Add more
          </Button>
        )}
        {isWorkAvailable && (
          <>
            <Typography variant="body2" mb={2}>
              For best results, logo images should be:{" "}
            </Typography>
            <Stack direction={"row"} alignItems="center" gap={1} mb={1}>
              <Check />
              <Typography variant="body2">High resolution</Typography>
            </Stack>
            <Stack direction={"row"} alignItems="center" gap={1}>
              <Check />
              <Typography variant="body2">
                White with a transparent background
              </Typography>
            </Stack>
          </>
        )}
      </Stack>

      <Stack direction={"row"} gap={2} mt={4}>
        <CustomButton variant="secondary" onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton onClick={handleSave} disabled={isWorkAvailable}>
          Save
        </CustomButton>
      </Stack>
    </BottomDrawer>
  );
}
