import { Box, Drawer, Stack, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import gradient from "../../img/rectangle.png";
import styles from "./BottomDrawer.module.css";
import { ReactComponent as Exit } from "../../img/exit.svg";
import { ReactComponent as Handle } from "../../img/drawer-handle.svg";
import { ReactComponent as Back } from "../../img/back-arrow.svg";

const BottomDrawer = ({
  isOpen,
  canExit = true,
  onClose = () => {},
  children,
  showHeader,
  title,
  header,
  showHandle,
  showBack,
  centered,
  styleOveride,
  subHeader,
  ...props
}) => {
  return (
    <Drawer
      {...props}
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      variant="temporary"
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        square: false,
        className: styles.drawerPaper,
      }}
    >
      {showHeader && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{ position: "absolute", color: "white" }}
          >
            {title}
          </Typography>
          <img src={gradient} style={{ width: "100%" }} alt="Beep Gradient" />
        </Box>
      )}
      <Box
        sx={{
          position: "relative",
          margin: showHeader ? "-30px 0 0 0" : 0,
          padding: !centered && "30px",
          borderTopLeftRadius: "30px !important",
          borderTopRightRadius: "30px !important",
          backgroundColor: "white",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={styleOveride}
        >
          {showHandle && (
            <Handle
              style={{
                position: "absolute",
                top: "15px",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            width={"100%"}
            justifyContent={centered && "center"}
            ml={centered && 2}
          >
            {showBack && <Back className={styles.back} onClick={onClose} />}
            <Typography fontFamily="Archivo" fontSize="18px" fontWeight={900}>
              {header}
            </Typography>
          </Stack>

          {canExit && <Exit className={styles.exit} onClick={onClose} />}
        </Stack>
        {subHeader && (
          <Typography variant="body2" mt={1} textAlign={"center"}>
            {subHeader}
          </Typography>
        )}
        {children}
      </Box>
    </Drawer>
  );
};

BottomDrawer.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
};

export default BottomDrawer;
