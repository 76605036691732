import React from "react";
import PropTypes from "prop-types";
import { FormHelperText, Input, InputLabel } from "@mui/material";
import { useField } from "formik";
import "./index.css";

const CustomFormInput = ({
  label,
  fullWidth = true,
  className,
  helperText,
  valid,
  requiredText,
  forProfile,
  inputClassName,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div className={`custom-input-container ${className}`}>
      <InputLabel
        className={`custom-input-label ${
          meta.error && "custom-input-label-error"
        }`}
        htmlFor={`${props.name}-input`}
      >
        {label} {requiredText && <i>(required)</i>}
      </InputLabel>
      <div className={meta.error ? "error-container" : ""}>
        <Input
          id={`${props.name}-input`}
          {...field}
          {...props}
          disableUnderline
          fullWidth={fullWidth}
          className={`custom-input ${inputClassName} ${
            valid ? "custom-input-valid" : ""
          } ${forProfile && "custom-input-profile"}`}
          error={!!(meta.touched && meta.error)}
        />
      </div>
      {!(meta.touched && meta.error) && helperText && (
        <FormHelperText
          id={`${props.name}-helper-text`}
          className="helper-text"
        >
          {helperText}
        </FormHelperText>
      )}
      {meta.error && <p className="error-message">{meta.error}</p>}
    </div>
  );
};

CustomFormInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
};

export default CustomFormInput;
