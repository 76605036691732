import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useElements, useStripe } from "@stripe/react-stripe-js";

import ReplyInput from "../ReplyInput";
import CardInfoDialog from "../CardInfoDialog";
import AuthenticateCard from "../AuthenticateCard";

const PaidReplyInput = ({
  order,
  recipient,
  onPaymentSubmit,
  onPaymentError,
  cardInfoOpen,
  onDismiss,
  onSubmit,
  paymentMethod,
  message,
  onChange,
  ...props
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [requiresAction, setRequiresAction] = useState(false);

  useEffect(() => {
    const confirmPayment = async () => {
      if (order && paymentMethod && stripe && !requiresAction) {
        const res = await stripe.confirmCardPayment(order.clientSecret, {
          payment_method: paymentMethod.id,
        });

        if (res.error) {
          onPaymentError(res.error);
          return;
        }
        if (res.status === "requires_action") {
          setRequiresAction(true);
          return;
        }
        onPaymentSubmit(false);
        setRequiresAction(false);
      }
    };

    confirmPayment();
  }, [
    order,
    paymentMethod,
    stripe,
    onPaymentSubmit,
    onPaymentError,
    requiresAction,
  ]);

  const handlePaymentSubmit = useCallback(async () => {
    console.log("submit payment");
    // await confirm
    const { paymentIntent: res, error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        // fixme: handle the case where stripe redirects (only if we enable other methods)
        return_url: window.location.href,
      },
    });

    if (error) {
      onPaymentError(error);
      return;
    }
    if (res.status === "requires_action") {
      setRequiresAction(true);
      return;
    }
    onPaymentSubmit();
    setRequiresAction(false);
  }, [onPaymentSubmit, elements, stripe, onPaymentError]);

  return (
    <>
      <CardInfoDialog
        creator={recipient?.firstName || recipient?.username}
        open={cardInfoOpen}
        onDismiss={onDismiss}
        onSubmit={onSubmit}
        onError={onPaymentError}
        order={order}
        dmPrice={recipient?.dmPrice}
        message={message}
        onMessageChange={onChange}
      />
      {requiresAction && (
        <AuthenticateCard
          onSubmit={handlePaymentSubmit}
          onError={onPaymentError}
          order={order}
        />
      )}
      <ReplyInput
        {...props}
        recipient={recipient}
        onSend={handlePaymentSubmit}
        placeholder="Message..."
      />
    </>
  );
};

PaidReplyInput.propTypes = {
  sending: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSend: PropTypes.func,
  onFilesAdded: PropTypes.func,
  onFilesUploaded: PropTypes.func,
  onFilesRemoved: PropTypes.func,
  onError: PropTypes.func,
  placeholder: PropTypes.string,
  isCreatorView: PropTypes.bool,
  payToSend: PropTypes.bool,
  recipient: PropTypes.shape({
    id: PropTypes.string,
    dmPrice: PropTypes.number,
  }),
};

export default PaidReplyInput;
