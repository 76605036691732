import React, { useState } from 'react';
import BottomDrawer from '../../components/BottomDrawer';
import PropTypes from 'prop-types';
import styles from './UnlockableUpload.module.css';
import { withFormik } from 'formik';
import CustomFormInput from '../CustomFormInput';
import CustomButton from '../CustomButton';
import file from '../../img/file.png';
import * as Yup from 'yup';
import Media from '../Media';

const INITIAL_VALUES = {
  price: 1,
  title: ''
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  price: Yup.number().required('Price is required'),
})

const UnlockableUpload = ({
  isOpen,
  handleSubmit,
  onClose,
  setFieldValue,
  errors,
  values
}) => {
  const [files, setFiles] = useState([]);
  const fileInputRef = React.useRef();

  const handleFilesAdded = async (evt) => {
    if (evt.target.files.length === 0) return;
    setFiles(Array.from(evt.target.files));
    setFieldValue('files', evt.target.files);
  }
  const isValid = values.title && values.price && values.files?.length > 0
    && !errors.title && !errors.price;

  return (
    <BottomDrawer isOpen={isOpen} onClose={onClose}>
      <div className={styles.drawerContainer}>
        <h3>Create an unlockable:<br />Select premium media</h3>
        <p className={styles.drawerContainerText}>
          Your fan can unlock access with an in-thread purchase, and payment goes directly to your account.
        </p>
        <input
          ref={fileInputRef}
          className={styles.hidden}
          type="file"
          onChange={handleFilesAdded}
        />
        {files.length > 0 && files.map((file, i) => (
          <Media
            className={styles.sendMediaPreview}
            key={`file-preview-${i}`}
            file={file}
            icon={<div
              className={styles.removeImage}
              onClick={() => setFiles([])}>X</div>}
          />
        ))}
        {files.length === 0 && <div
          className={styles.uploadBox}
          onClick={() => fileInputRef.current.click()}
        >
          <img
            className={styles.file}
            src={file}
            width={20}
            height={22}
            alt='Uploaad'
          />
        </div>}
        <CustomFormInput
          className={styles.input}
          name='title'
          label='TITLE'
        />
        <CustomFormInput
          className={styles.input}
          name='price'
          label='PRICE'
          type='number'
          inputProps={{
            min: 1
          }}
          startAdornment='$'
        />
        <CustomButton
          className={styles.button}
          type='submit'
          onSubmit={handleSubmit}
          disabled={!isValid}
        >
          Finish
        </CustomButton>
      </div>
    </BottomDrawer>
  )
}

UnlockableUpload.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.shape({
    title: PropTypes.string,
    price: PropTypes.number
  }),
  errors: PropTypes.shape({
    title: PropTypes.string,
    price: PropTypes.string
  }),
  onClose: PropTypes.func,
}

export default withFormik({
  mapPropsToValues: () => INITIAL_VALUES,
  validationSchema,
  handleSubmit: async (values, { resetForm, props: { onSubmit } }) => {
    await onSubmit(values);
    resetForm();
  },
  displayName: 'UnlockableUpload'
})(UnlockableUpload);
