import React from "react";
import beepLogo from "../../../img/logo-with-text.svg";
import BackButton from "../../../components/BackButton";
import { Stack, Typography } from "@mui/material";
import styles from "./Help.module.css";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";

export default function Help() {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.header}>
        <Stack direction="row">
          <BackButton />
          <img src={beepLogo} alt="logo" width={87} height={22} />
        </Stack>
        <Typography variant="body2" fontWeight={600}>
          Help
        </Typography>
      </div>
      <div className={styles.container}>
        <Typography variant="header1" fontSize={"22px"} my={2}>
          Need help?
        </Typography>
        <Typography variant="body1" fontSize={"18px"} mt={2}>
          You can reach us Monday through
          <br /> Friday from 9am to 5pm PST at:
          <br />
          <br /> @beep@beepmehere.com
        </Typography>
        <CustomButton onClick={() => navigate(-1)} sx={{ mt: 15 }}>
          Back to my profile
        </CustomButton>
      </div>
    </>
  );
}
