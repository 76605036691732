import { Alert, Box, Snackbar } from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context";
import useMutate from "../../../hooks/useMutate";
import { pick } from "../../../lib/shared";
import IntegrationsForm from "./IntegrationsForm";

export default function Integrations() {
  const navigate = useNavigate();
  const { user, refreshUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [updateCreator] = useMutate(`/creator/${user?.creator?.id}`, {
    method: "PUT",
  });

  const handleEdit = useCallback(
    async (values) => {
      setLoading(true);
      const response = await updateCreator({
        ...values,
        handle: values.username,
      });

      setLoading(false);
      if (response?.error) {
        setError(response?.error || "Something went wrong. Please try again.");
        return;
      }
      refreshUser();
      navigate(-1);
    },
    [updateCreator, navigate, refreshUser]
  );

  return (
    <Box maxWidth={"550px"} mx="auto">
      <Snackbar open={!!error} autoHideDuration={3000} onClose={setError}>
        <Alert
          className="error-alert"
          onClose={setError}
          severity="error"
          variant="filled"
        >
          {error}
        </Alert>
      </Snackbar>
      <IntegrationsForm
        initialValues={{
          id: user?.id,
          creatorId: user?.creator?.id,
          ...pick(["username", "phoneNumber", "firstName", "lastName"], user),
          ...pick(
            [
              "dmPrice",
              "videoCallHourlyRate",
              "videoDurationsEnabled",
              "videoCallsEnabled",
              "messagesEnabled",
              "firstMessageFree",
              "avatarUrl",
              "schedule",
            ],
            user?.creator
          ),
        }}
        userId={user?.id}
        onSubmit={handleEdit}
        loading={loading}
        onError={setError}
      />
    </Box>
  );
}
