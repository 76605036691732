import React from "react";
import { ReactComponent as LinkedIn } from "../../img/socials/linkedin.svg";
import { ReactComponent as Instagram } from "../../img/socials/instagram.svg";
import { ReactComponent as Twitter } from "../../img/socials/twitter.svg";
import { ReactComponent as Tiktok } from "../../img/socials/tiktok.svg";
import { ReactComponent as Web } from "../../img/socials/web.svg";
import { ReactComponent as Other } from "../../img/socials/other.svg";
import { Stack, Typography } from "@mui/material";

const SocialLinks = ({ linksValue }) => {
  const platformIcons = {
    linkedin: <LinkedIn />,
    instagram: <Instagram />,
    twitter: <Twitter />,
    tiktok: <Tiktok />,
    website: <Web />,
    other: <Other />,
  };

  const safeLinksValue = linksValue || {};

  const filteredLinks = Object.entries(safeLinksValue).filter(
    ([key, value]) => value
  );
  return (
    <Stack>
      {filteredLinks?.map(([platform, value]) => (
        <Stack key={platform} direction="row" alignItems="center" mb={"10px"}>
          {platformIcons[platform]}
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            {value}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default SocialLinks;
