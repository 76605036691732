import { Stack } from "@mui/material";
import React, { useState } from "react";
import BottomDrawer from "../BottomDrawer";
import CustomButton from "../CustomButton";
import CustomFormInput from "../CustomFormInput";
import { ReactComponent as LinkedIn } from "../../img/socials/linkedin.svg";
import { ReactComponent as Instagram } from "../../img/socials/instagram.svg";
import { ReactComponent as Twitter } from "../../img/socials/twitter.svg";
import { ReactComponent as Tiktok } from "../../img/socials/tiktok.svg";
import { ReactComponent as Web } from "../../img/socials/web.svg";
import { ReactComponent as Other } from "../../img/socials/other.svg";

export default function UpdateSocialLinks({
  value,
  setFieldValue,
  open,
  setOpen,
  onSubmit,
}) {
  const [linksValue, setLinksValue] = useState(
    value || {
      linkedin: "",
      instagram: "",
      twitter: "",
      tiktok: "",
      website: "",
      other: "",
    }
  );

  const handleClose = () => {
    setOpen(false);
    setLinksValue(value);
  };

  const handleInputChange = (platform, newValue) => {
    setLinksValue((prevState) => ({
      ...prevState,
      [platform]: newValue,
    }));
  };

  const handleSave = (event) => {
    setFieldValue("socialLinks", linksValue);
    setOpen(false);
    onSubmit(event);
  };

  return (
    <BottomDrawer
      isOpen={open}
      onClose={() => handleClose()}
      header="Socials"
      showHandle
      showBack
      canExit={false}
    >
      <Stack mt={3} gap={1}>
        <CustomFormInput
          name="linkedin"
          label="LINKEDIN (optional)"
          value={linksValue?.linkedin}
          sx={{ mb: 2 }}
          placeholder="Enter your Linkedin URL"
          inputClassName="update-social-input"
          startAdornment={<LinkedIn style={{ paddingRight: 8 }} />}
          onChange={(e) => handleInputChange("linkedin", e.target.value)}
        />
        <CustomFormInput
          name="instagram"
          label="INSTAGRAM (optional)"
          value={linksValue?.instagram}
          sx={{ mb: 2 }}
          placeholder="Enter your IG handle"
          inputClassName="update-social-input"
          startAdornment={<Instagram style={{ paddingRight: 8 }} />}
          onChange={(e) => handleInputChange("instagram", e.target.value)}
        />
        <CustomFormInput
          name="twitter"
          label="TWITTER (optional)"
          value={linksValue?.twitter}
          sx={{ mb: 2 }}
          placeholder="Enter your Twitter account name"
          inputClassName="update-social-input"
          startAdornment={<Twitter style={{ paddingRight: 8 }} />}
          onChange={(e) => handleInputChange("twitter", e.target.value)}
        />
        <CustomFormInput
          name="tiktok"
          label="TIKTOK (optional)"
          value={linksValue?.tiktok}
          sx={{ mb: 2 }}
          placeholder="Enter your Tiktok account name"
          inputClassName="update-social-input"
          startAdornment={<Tiktok style={{ paddingRight: 8 }} />}
          onChange={(e) => handleInputChange("tiktok", e.target.value)}
        />
        <CustomFormInput
          name="website"
          label="WEBSITE (optional)"
          value={linksValue?.website}
          sx={{ mb: 2 }}
          placeholder="Enter your Webstite URL"
          inputClassName="update-social-input"
          startAdornment={<Web style={{ paddingRight: 8 }} />}
          onChange={(e) => handleInputChange("website", e.target.value)}
        />
        <CustomFormInput
          name="other"
          label="Other platform (optional)"
          value={linksValue?.other}
          sx={{ mb: 2 }}
          placeholder="Enter URL"
          inputClassName="update-social-input"
          startAdornment={<Other style={{ paddingRight: 8 }} />}
          onChange={(e) => handleInputChange("other", e.target.value)}
        />
      </Stack>

      <Stack direction="row" gap={2} mt={4}>
        <CustomButton variant="secondary" onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton onClick={handleSave}>Save</CustomButton>
      </Stack>
    </BottomDrawer>
  );
}
