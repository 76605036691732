import React from 'react';
import PropTypes from 'prop-types';
import ScheduleForm from './ScheduleForm';
import AvailabilityForm from './AvailabilityForm';
import { useParams } from 'react-router-dom';

const ScheduleAvailabilityForm = (props) => {
  const { step } = useParams();

  return step === 'schedule'
    ? <ScheduleForm {...props} />
    : <AvailabilityForm {...props} />;
}

ScheduleAvailabilityForm.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setSubmitting: PropTypes.func,
  error: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.func,
  loading: PropTypes.bool
}

export default ScheduleAvailabilityForm;