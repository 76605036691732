import React from "react";

export default function PreviewIcon({ fill }) {
  return (
    <svg
      width="20"
      height="13"
      viewBox="0 0 20 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.37207 6.6841L0.675713 6.40556C0.588663 6.62318 0.608359 6.86899 0.728951 7.06997L1.37207 6.6841ZM18.3721 6.6841L19.0517 7.00127C19.1393 6.81353 19.1454 6.59792 19.0684 6.40556L18.3721 6.6841ZM2.06843 6.96265C2.73822 5.28817 5.25719 1.89062 9.87207 1.89062V0.390625C4.43477 0.390625 1.48418 4.38438 0.675713 6.40556L2.06843 6.96265ZM9.87207 1.89062C14.4869 1.89062 17.0059 5.28817 17.6757 6.96265L19.0684 6.40556C18.26 4.38438 15.3094 0.390625 9.87207 0.390625V1.89062ZM17.6924 6.36694C17.3154 7.17486 16.3771 8.47058 14.997 9.56011C13.6251 10.6432 11.8747 11.4776 9.87207 11.4776V12.9776C12.3042 12.9776 14.3727 11.9641 15.9265 10.7374C17.4721 9.51719 18.5664 8.04117 19.0517 7.00127L17.6924 6.36694ZM9.87207 11.4776C7.87409 11.4776 6.18731 10.647 4.8456 9.56497C3.50009 8.47988 2.53778 7.16921 2.01519 6.29823L0.728951 7.06997C1.31506 8.04682 2.38535 9.50789 3.90397 10.7326C5.42639 11.9603 7.43527 12.9776 9.87207 12.9776V11.4776Z"
        fill={fill || "white"}
      />
      <circle
        cx="9.87114"
        cy="6.68412"
        r="1.83696"
        stroke={fill || "white"}
        stroke-width="1.5"
      />
    </svg>
  );
}
