import { Box, Stack, Typography } from "@mui/material";
import Calendar from "react-calendar";
import "./index.css";
import { ReactComponent as ArrowRight } from "../../../../img/back-arrow.svg";
import TimezoneSelect from "../../../../components/TimezoneSelect";

export default function BookingDate({
  availableDays,
  dateValue,
  setDateValue,
  timeZone,
  setTimeZone,
}) {
  return (
    <Stack alignItems={"center"} my={2}>
      <Typography variant="header1" fontSize={"20px"} mb={3} mt={-2}>
        Select a Day
      </Typography>
      <Calendar
        onChange={setDateValue}
        value={dateValue}
        prevLabel={<ArrowRight />}
        nextLabel={<ArrowRight style={{ transform: "rotate(180deg)" }} />}
        className="react-calendar"
        tileClassName={({ date, view }) => {
          const formattedDate = date?.toISOString().split("T")[0];
          const isAvailable =
            availableDays && availableDays[formattedDate] !== undefined;

          return isAvailable ? "available-day" : "unavailable-day";
        }}
      />
      <Box width={"80%"} alignSelf="start" mt={3} mb={2} px={1}>
        <TimezoneSelect
          timezone={timeZone}
          onChange={(event, newValue) => {
            setTimeZone(newValue);
          }}
        />
      </Box>
    </Stack>
  );
}
