import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { Box, Stack, Typography } from "@mui/material";
import CustomButton from "../../../../components/CustomButton";
import BackButton from "../../../../components/BackButton";
import beepLogo from "../../../../img/logo-with-text.svg";
import { ReactComponent as Check } from "../../../../img/button-check.svg";
import { ReactComponent as Pencil } from "../../../../img/pencil.svg";
import { ReactComponent as ArrowRight } from "../../../../img/button-arrow.svg";
import VideoCallIcon from "../../../../components/icons/VideoCallIcon";
import MessagesIcon from "../../../../components/icons/MessagesIcon";
import styles from "./Preview.module.css";
import PreviewProfileDrawer from "./PreviewProfileDrawer";
import Footer from "../../../../components/Footer";
import LandingFaq from "../../../Faq/LandingFaq";
import HowItWorks from "../../../../components/HowItWorks";
import { expertiseLookup } from "../../Signup/SignupForm/ExpertiseForm";
import CompleteProfile from "../../../../components/CompleteProfile";

const PreviewProfileForm = ({
  user,
  userId,
  values,
  handleSubmit,
  setFieldValue,
  onError,
  errors,
  touched,
  loading,
  setFieldTouched,
  setFieldError,
  editProfileOpen,
  setEditProfileOpen,
}) => {
  const headerRef = useRef(null);
  const handleFormSubmit = useCallback(
    (event) => {
      event.preventDefault();

      handleSubmit(values);
    },
    [handleSubmit, values]
  );

  const handleSave = (event) => {
    handleFormSubmit(event);
    setEditProfileOpen(false);
  };

  useEffect(() => {
    if (editProfileOpen && headerRef.current) {
      headerRef.current.focus();
      headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [editProfileOpen]);

  return (
    <div className={styles.container}>
      <Box className={styles.pageHeader} ref={headerRef}>
        <Stack direction="row">
          <BackButton />
          <img src={beepLogo} alt="logo" width={87} height={22} />
        </Stack>
        {editProfileOpen ? (
          <CustomButton
            className={styles.doneButton}
            onClick={handleSave}
            startIcon={<Check />}
          >
            Done
          </CustomButton>
        ) : (
          <Typography variant="body2" fontWeight={600}>
            Page preview
          </Typography>
        )}
      </Box>
      <Box sx={{ p: editProfileOpen && 2, transition: ".2s ease-in-out" }}>
        <Box
          minHeight="100vh"
          pb={10}
          bgcolor="#000"
          borderRadius={editProfileOpen && 10}
        >
          {user?.creator?.avatarUrl ? (
            <Box position={"relative"}>
              <img
                src={user?.creator?.avatarUrl}
                className={styles.previewImage}
                style={{ borderRadius: editProfileOpen && 35 }}
                alt="avatar"
              />
              <Box className={styles.intro}>
                <Typography variant="header1" fontSize={"48px"}>
                  {user?.firstName} <br />
                  {user?.lastName}
                </Typography>
                {user?.creator?.briefBio &&
                  user?.creator?.briefBio?.brandName &&
                  user?.creator?.briefBio?.topic1 &&
                  user?.creator?.briefBio?.topic2 && (
                    <Typography
                      variant="body1"
                      fontSize={"20px"}
                      fontWeight={500}
                      mt={1}
                    >
                      From the mind behind {user?.creator?.briefBio?.brandName},{" "}
                      {user?.creator?.firstName} advises you on{" "}
                      {user?.creator?.briefBio?.topic1} and{" "}
                      {user?.creator?.briefBio?.topic2}
                    </Typography>
                  )}
              </Box>
            </Box>
          ) : (
            <Stack alignItems={"center"} textAlign="center" pt={5} px={2}>
              <Typography
                variant="header1"
                fontSize={"48px"}
                color="#fff"
                className="image-text"
              >
                {user?.firstName} <br /> {user?.lastName}
              </Typography>
              {user?.creator?.briefBio &&
                user?.creator?.briefBio?.brandName &&
                user?.creator?.briefBio?.topic1 &&
                user?.creator?.briefBio?.topic2 && (
                  <Typography
                    variant="body1"
                    color={"#fff"}
                    fontSize={"20px"}
                    fontWeight={500}
                    mt={1}
                  >
                    From the mind behind {user?.creator?.briefBio?.brandName},{" "}
                    {user?.creator?.firstName} advises you on{" "}
                    {user?.creator?.briefBio?.topic1} and{" "}
                    {user?.creator?.briefBio?.topic2}
                  </Typography>
                )}
            </Stack>
          )}

          <Box px={2} mt={1}>
            <Stack justifyContent="space-between" gap={2} mt={4}>
              {user?.creator?.videoCallsEnabled && (
                <Box className={styles.serviceNoImage}>
                  <VideoCallIcon fillWhite />
                  <Typography
                    variant="body2"
                    fontWeight={900}
                    textAlign={"center"}
                    color="#fff"
                  >
                    Video call {`$${user?.creator?.videoCallHourlyRate / 2}`}
                  </Typography>
                </Box>
              )}
              {user?.creator?.messagesEnabled && (
                <Box className={styles.serviceNoImage}>
                  <MessagesIcon fillWhite />
                  <Typography
                    variant="body2"
                    fontWeight={900}
                    textAlign={"center"}
                    color="#fff"
                  >
                    Message {`$${user?.creator?.dmPrice}`}
                  </Typography>
                </Box>
              )}
            </Stack>
            {!user?.creator?.avatarUrl && (
              <CompleteProfile
                title={"Upload A Profile Photo"}
                description={"to connect with your audience"}
                openEdit={setEditProfileOpen}
                sx={{ mt: 4 }}
              />
            )}
            {user?.creator?.faqs?.length > 0 && (
              <Stack className={styles.faqContainer} gap={5}>
                <Typography
                  variant="header1"
                  color="#fff"
                  textAlign="center"
                  mt={2}
                >
                  {user?.firstName}’s Most Frequently <br /> Asked Questions
                </Typography>
                {user?.creator?.faqs.map((faq) => (
                  <Stack className={styles.faq}>
                    <Typography variant="body1" color={"#fff"}>
                      {faq?.content}
                    </Typography>
                    <CustomButton endIcon={<ArrowRight />}>
                      Ask {user?.firstName}
                    </CustomButton>
                  </Stack>
                ))}
              </Stack>
            )}
            {!user?.creator?.featuredClients && (
              <CompleteProfile
                title={"Add Featured Clients"}
                description={"to highlight your credentials and experience"}
                openEdit={setEditProfileOpen}
                sx={{ mt: 5 }}
              />
            )}
            {user?.creator?.expertise.length > 0 && (
              <Stack
                justifyContent={"end"}
                sx={{
                  position: "relative",
                  height: user?.creator?.expertiseBackground ? 500 : "auto",
                  textAlign: "center",
                  mt: 10,
                  background: `url(${user?.creator?.expertiseBackground}) rgba(0, 0, 0, 0.35) no-repeat`,
                  boxShadow:
                    user?.creator?.expertiseBackground &&
                    "8px 8px 24px 0px rgba(3, 111, 98, 0.12)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "20px",
                }}
              >
                {user?.creator?.expertiseBackground && (
                  <Box
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.35)",
                    }}
                  />
                )}

                <Box zIndex={10}>
                  <Typography variant="header1" color={"#fff"}>
                    {user?.firstName} Can Advise On
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    flexWrap="wrap"
                    mt={3}
                    mb={user?.creator?.expertiseBackground && 7}
                    gap={1}
                  >
                    {user?.creator?.expertise
                      ?.filter((option) => option)
                      ?.map((option, index) => (
                        <Box
                          key={index}
                          sx={{
                            background: "#F3F3F4",
                            color: "#000",
                            borderRadius: "50px",
                            padding: "14px 16px",
                            mb: "5px",
                            fontSize: "14px",
                            fontFamily: "Lato",
                          }}
                        >
                          {expertiseLookup[option?.title || option]}{" "}
                          {option?.title || option}
                        </Box>
                      ))}
                  </Box>
                </Box>
              </Stack>
            )}
            {!user?.creator?.expertiseBackground && (
              <CompleteProfile
                title={"Upload A Background Image"}
                description={"to complete What You Can Advise"}
                openEdit={setEditProfileOpen}
                sx={{ my: 5 }}
              />
            )}
            {!user?.creator?.reviews && (
              <CompleteProfile
                title={"Add Reviews"}
                description={"to show your audience the quality of your work"}
                openEdit={setEditProfileOpen}
              />
            )}
            <HowItWorks />
            <LandingFaq />
          </Box>
        </Box>
        <Footer />
        <Box
          className={`${styles.editButtonContainer}
              ${editProfileOpen && styles.editButtonContainerOpen}`}
        >
          <CustomButton
            className={styles.editButton}
            onClick={() => setEditProfileOpen(true)}
            startIcon={<Pencil />}
          >
            Edit my page
          </CustomButton>
        </Box>
      </Box>
      <PreviewProfileDrawer
        values={values}
        editProfileOpen={editProfileOpen}
        setEditProfileOpen={setEditProfileOpen}
        setFieldValue={setFieldValue}
        onError={onError}
        onSubmit={handleFormSubmit}
      />
    </div>
  );
};

PreviewProfileForm.propTypes = {
  isOpen: PropTypes.bool,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  onLoginClick: PropTypes.func,
  onError: PropTypes.func,
  errors: PropTypes.string,
  touched: PropTypes.func,
  values: PropTypes.any,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => initialValues,
  validationSchem: {},
  handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
    onSubmit(values);
    setSubmitting(false);
  },
  displayName: "PreviewProfileForm",
})(PreviewProfileForm);
