import React from "react";

export default function MessagesIcon({ fillWhite }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
    >
      <path
        d="M22.0995 10.873C22.0995 16.672 17.3547 21.373 11.5017 21.373C9.68543 21.373 7.97586 20.9204 6.48168 20.1227L1.17337 21.0435C0.374792 21.182 -0.2482 20.3628 0.0986525 19.6302L2.02095 15.5704C1.30616 14.157 0.903881 12.5616 0.903881 10.873C0.903881 5.07406 5.64869 0.373047 11.5017 0.373047C17.3547 0.373047 22.0995 5.07406 22.0995 10.873Z"
        fill={fillWhite ? "white" : "black"}
      />
      <circle cx="11.7295" cy="11.0039" r="1.5" fill="#F3F3F3" />
      <circle cx="16.3818" cy="11.0039" r="1.5" fill="#F3F3F3" />
      <circle cx="7.07715" cy="11.0039" r="1.5" fill="#F3F3F3" />
    </svg>
  );
}
