import React from "react";
import PropTypes from "prop-types";
import Template from "../Template";
import { withFormik } from "formik";
import { Box, Typography } from "@mui/material";
import stripeLogo from "../../../../../img/stripe.png";

const StripeForm = ({ activeStep, handleSubmit }) => {
  return (
    <Template
      title={
        <>
          Last step, connect a<br />
          Stripe account to get paid
        </>
      }
      step={activeStep}
      onSubmit={handleSubmit}
      ctaText="Continue to Stripe"
      showProgress={false}
    >
      <Box
        sx={{
          margin: "0 auto",
          textAlign: "center",
          maxWidth: "316px",
          mt: 6,
          mb: 5,
        }}
      >
        <img src={stripeLogo} alt="Earn with Stripe" width={209} />
        <Typography sx={{ fontSize: 14 }} variant="body1">
          Almost done! To start earning, create <br /> a Stripe account.
        </Typography>
      </Box>
    </Template>
  );
};

StripeForm.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setSubmitting: PropTypes.func,
  error: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.func,
  loading: PropTypes.bool,
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => initialValues,
  validationSchem: {},
  handleSubmit: (values, { props: { onSubmit } }) => {
    return onSubmit(null, "/creator/stripe/onboarding");
  },
  displayName: "StripeForm",
})(StripeForm);
