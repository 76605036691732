export default function DeleteIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3489_5131"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="21"
      >
        <rect x="0.851562" y="0.623047" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3489_5131)">
        <path
          d="M7.60733 17.2063C7.23756 17.2063 6.92183 17.0755 6.66014 16.8138C6.39843 16.5521 6.26758 16.2363 6.26758 15.8666V6.12301H5.26758V5.0397H8.85089V4.13586H12.8509V5.0397H16.4342V6.12301H15.4342V15.8575C15.4342 16.2428 15.3047 16.564 15.0457 16.8209C14.7866 17.0779 14.4696 17.2063 14.0945 17.2063H7.60733ZM14.3509 6.12301H7.35089V15.8666C7.35089 15.9414 7.37493 16.0028 7.42302 16.0509C7.4711 16.099 7.53254 16.123 7.60733 16.123H14.0945C14.1586 16.123 14.2173 16.0963 14.2708 16.0429C14.3242 15.9895 14.3509 15.9307 14.3509 15.8666V6.12301ZM9.18745 14.623H10.2708V7.62301H9.18745V14.623ZM11.431 14.623H12.5143V7.62301H11.431V14.623Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}
