import React from "react";

export default function SpeakingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="23"
      viewBox="0 0 27 23"
      fill="none"
    >
      <path
        d="M6.53493 16.9698C7.18432 18.2686 6.53517 20.6226 6.1293 21.8401C5.99393 21.9754 6.37258 22.2461 8.97014 22.2461H13.8406C14.2464 22.2461 13.4347 21.2991 14.2464 19.405C15.0582 17.511 17.6762 17.5987 18.711 16.5639C19.1169 16.1581 18.711 14.9404 18.711 14.5346C18.711 14.1287 19.5227 13.7228 18.711 13.7228C17.8993 13.7228 17.0875 13.7228 17.0875 12.9111C17.0875 12.1851 18.711 11.6935 19.1169 10.8818C18.3051 10.4759 18.3051 10.07 18.3051 9.66415C18.3051 9.25828 18.711 9.25828 19.1169 8.85241C19.5227 8.44654 19.5227 8.17597 19.5227 8.04068C17.4934 8.04068 16.2758 6.4171 16.2758 6.01123C16.2758 4.90224 14.2467 -0.482675 7.75278 0.329063C2.2246 1.02009 -0.364648 4.7923 0.0412676 8.85231C0.485425 13.2948 5.72319 15.3463 6.53493 16.9698Z"
        fill="black"
      />
      <path
        d="M22.1182 9.17545L24.5534 6.74023"
        stroke="black"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.1182 14.8578L24.5534 17.293"
        stroke="black"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.1583 12.077H25.7089"
        stroke="black"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
