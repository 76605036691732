import React, { useCallback, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Typography,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { withFormik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import CustomFormInput from "../../../../../components/CustomFormInput";
import MuiPhoneNumber from "material-ui-phone-number";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import Template from "../Template";
import { SIGNUP_STEPS } from "../../../../../lib/signup";

const INITIAL_VALUES = {
  phoneNotifications: true,
};

const usernameRules = /^(?=.{3})[A-Za-z\d_-]+$/gi;
const phoneRules =
  /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/;

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Must be at least 3 letters or numbers")
    .max(25, "Must be 25 characters or less")
    .matches(usernameRules, {
      message: "Your username must contain only letters or numbers",
    })
    .required("Username is required"),
  phoneNumber: Yup.string()
    .matches(phoneRules, { message: "This is not a valid phone number" })
    .required("Phone number is required"),
});

const ContactForm = ({
  values,
  touched,
  errors,
  setFieldValue,
  setFieldError,
  setSubmitting,
  handleSubmit,
  activeStep,
  setFieldTouched,
  isSubmitting,
}) => {
  const [validUsername, setValidUsername] = useState(false);
  const [isUsernameAvailable, { loading: usernameLoading }] = useLazyFetch(
    `/username/available/${values.username}`,
    { auth: false }
  );

  const handleUsernameValid = useCallback(async () => {
    setFieldTouched("username", true);
    if (!values.username || errors?.username) return;
    const { data } = await isUsernameAvailable();
    if (data?.available) {
      setValidUsername(true);
      setFieldError("username", "");
      return true;
    } else {
      setValidUsername(false);
      setFieldError("username", "Username is not available");
      return false;
    }
  }, [
    isUsernameAvailable,
    setFieldError,
    values.username,
    setFieldTouched,
    errors,
  ]);

  const handleUsernameChange = (e) => {
    setFieldValue("username", e.target.value?.toLowerCase());
    setFieldValue("handle", e.target.value?.toLowerCase());
  };

  const handlePhoneInput = useCallback(
    (value) => {
      let phoneNumber = value.replace(/-|\s|([()])/g, "");
      setFieldTouched("phoneNumber", true);
      setFieldValue("phoneNumber", phoneNumber);
    },
    [setFieldValue, setFieldTouched]
  );

  const handlePhoneNotifications = useCallback(
    (event) => {
      setFieldValue("phoneNotifications", event.target.checked);
    },
    [setFieldValue]
  );

  const onFormSubmit = useCallback(async () => {
    if (isSubmitting) return;
    setSubmitting(true);

    await handleSubmit(values);
  }, [handleSubmit, setSubmitting, values, isSubmitting]);

  return (
    <Template
      title="Create your Beep account"
      step={activeStep}
      disabled={!values.username || !!errors.phoneNumber}
      onSubmit={onFormSubmit}
      showTerms
      showLogin
    >
      <Box mt="-30px">
        <CustomFormInput
          name="username"
          label="Username"
          autoComplete="off"
          className="username"
          valid={!errors.username && validUsername}
          onBlur={handleUsernameValid}
          helperText={
            usernameLoading
              ? "Checking availability..."
              : "Can only contain letters and numbers"
          }
          onChange={handleUsernameChange}
          startAdornment={
            <Box className="username-placeholder">
              <Typography variant="header1" fontSize={"14px"} fontWeight={300}>
                beepmehere.com/
              </Typography>
            </Box>
          }
          endAdornment={
            validUsername && !errors.username ? (
              <CheckCircleOutlineIcon color="success" />
            ) : null
          }
          placeholder="Username"
        />
        <Box mt="30px">
          <InputLabel className="custom-input-label" htmlFor={`number-input`}>
            Phone number
          </InputLabel>
          <MuiPhoneNumber
            onChange={handlePhoneInput}
            name="phoneNumber"
            defaultCountry="us"
            fullWidth
            inputClass="mui-input"
            placeholder="(XXX) XXX - XXXX"
          />
        </Box>

        {errors.phoneNumber && touched.phoneNumber && (
          <p className="error-message">{errors.phoneNumber}</p>
        )}
        <FormControlLabel
          className="creator-signup-input"
          control={
            <Checkbox
              checked={values.phoneNotifications}
              name="phoneNotifications"
              onChange={handlePhoneNotifications}
              sx={{
                color: "#000",
                p: "3",
                "&.Mui-checked": {
                  color: "#000",
                },
              }}
            />
          }
          label="Get notified via SMS text when fans message you"
          classes={{
            label: "checkbox-label",
          }}
        />
      </Box>
    </Template>
  );
};

ContactForm.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setSubmitting: PropTypes.func,
  error: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.func,
  loading: PropTypes.bool,
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => ({
    ...INITIAL_VALUES,
    username: window.localStorage.getItem("username"),
    ...initialValues,
  }),
  validationSchema,
  handleSubmit: (values, { props: { onSubmit, activeStep } }) => {
    return onSubmit(
      values,
      `/creator/onboarding/${SIGNUP_STEPS[activeStep + 1]}`
    );
  },
  displayName: "ContactForm",
})(ContactForm);
