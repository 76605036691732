import { Box, Button, InputLabel, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import BottomDrawer from "../BottomDrawer";
import CustomFormInput from "../CustomFormInput";
import { ReactComponent as Bin } from "../../img/bin.svg";
import { ReactComponent as Plus } from "../../img/plus.svg";
import CustomButton from "../CustomButton";

export default function UpdateFaq({
  value = [],
  setFieldValue,
  open,
  setOpen,
  onSubmit,
}) {
  const [faqs, setFaqs] = useState(value);
  const handleClose = () => {
    setOpen(false);
    setFaqs(value);
  };

  const addFaq = () => {
    const newFaq = { id: faqs.length + 1, content: "" };
    setFaqs([...faqs, newFaq]);
  };

  const deleteFaq = (id) => {
    setFaqs(faqs.filter((faq) => faq.id !== id));
  };

  const updateFaq = (id, content) => {
    setFaqs(faqs.map((faq) => (faq.id === id ? { ...faq, content } : faq)));
  };

  const handleSave = (event) => {
    setFieldValue("faqs", faqs);
    setOpen(false);
    onSubmit(event);
  };

  return (
    <BottomDrawer
      isOpen={open}
      onClose={() => handleClose()}
      header="FAQs"
      showHandle
      canExit={false}
      showBack
    >
      <Stack mt={3}>
        {faqs?.length > 0 ? (
          faqs?.map((faq) => (
            <Box key={faq.id} mb="12px">
              <Stack className="stackLayout">
                <InputLabel
                  sx={{ fontSize: "14px", color: "#000" }}
                >{`FAQ ${faq.id}`}</InputLabel>
                <Bin onClick={() => deleteFaq(faq.id)} cursor="pointer" />
              </Stack>
              <CustomFormInput
                name={`faq-${faq.id}`}
                sx={{ mb: 2 }}
                placeholder="Enter your FAQ text"
                inputClassName="update-social-input"
                value={faq.content}
                onChange={(e) => updateFaq(faq.id, e.target.value)}
              />
            </Box>
          ))
        ) : (
          <Typography textAlign={"center"}>No FAQs added</Typography>
        )}
        <Button className="addButton" startIcon={<Plus />} onClick={addFaq}>
          Add more
        </Button>
      </Stack>
      <Stack direction={"row"} gap={2} mt={4}>
        <CustomButton variant="secondary" onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton onClick={handleSave}>Save</CustomButton>
      </Stack>
    </BottomDrawer>
  );
}
