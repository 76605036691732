import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as UploadIcon } from "../../img/upload.svg";
import AvatarUpload from "../AvatarUpload";
import CloudinaryUpload from "../CloudinaryUpload";

export default function ExpertiseBackground({
  value,
  title,
  noUpdate,
  setFieldValue,
}) {
  const [uploading, setUploading] = useState(false);
  const [preview, setPreview] = useState(value || "");

  const handleSuccessfulUpload = (type) => (url) => {
    setPreview(url);
    setFieldValue("expertiseBackground", url);
  };

  const removeImage = () => {
    setPreview("");
    setFieldValue("expertiseBackground", "");
  };

  return (
    <Box mt={3} mb={noUpdate && 2}>
      {title && (
        <Typography variant="body2" fontSize="12px" my={1}>
          Background image
        </Typography>
      )}
      {preview ? (
        <Box my={2}>
          <AvatarUpload
            onSuccessfulUpload={handleSuccessfulUpload}
            removeImage={removeImage}
            url={preview}
            size="medium"
            noText
            showButtons
          />
        </Box>
      ) : (
        <CloudinaryUpload
          type="image"
          onSuccessfulUpload={handleSuccessfulUpload("image")}
          loading={uploading}
          onLoading={setUploading}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            gap={1}
            sx={{
              height: "108px",
              borderRadius: "5px",
              border: "1.25px dashed #D9D9D9",
            }}
          >
            <UploadIcon />
            <Typography variant="body2" fontWeight={700}>
              Add background image
            </Typography>
          </Stack>
        </CloudinaryUpload>
      )}
    </Box>
  );
}
