import React, { useCallback, useContext, useState } from "react";
import { UserContext } from "../../../../context";
import useMutate from "../../../../hooks/useMutate";
import { pick } from "../../../../lib/shared";
import PreviewProfile from "./PreviewProfile";

export default function Preview() {
  const { user, refreshUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const [updateUser] = useMutate(`/user/${user?.id}`, { method: "PUT" });
  const [updateCreator] = useMutate(`/creator/${user?.creator?.id}`, {
    method: "PUT",
  });

  const handleEdit = useCallback(
    async (values) => {
      setLoading(true);
      const response = user?.isCreator
        ? await updateCreator({
            ...values,
            handle: values.username,
          })
        : await updateUser(values);
      setLoading(false);
      if (response?.error) {
        setError(response?.error || "Something went wrong. Please try again.");
        return;
      }
      refreshUser();
    },
    [updateCreator, updateUser, user?.isCreator, refreshUser]
  );

  console.log(error);

  return (
    <>
      {user && (
        <PreviewProfile
          user={user}
          initialValues={{
            id: user?.id,
            creatorId: user?.creator?.id,
            ...pick(["username", "phoneNumber", "firstName", "lastName"], user),
            ...pick(
              [
                "avatarUrl",
                "briefBio",
                "fullBio",
                "expertise",
                "faqs",
                "workExperience",
                "socialLinks",
                "headlineEnabled",
                "bioEnabled",
                "faqEnabled",
                "expertiseEnabled",
                "socialLinksEnabled",
                "workExperienceCallsEnabled",
                "expertiseBackground",
              ],
              user?.creator
            ),
          }}
          userId={user?.id}
          onSubmit={handleEdit}
          loading={loading}
          onError={setError}
          isPreview
          editProfileOpen={editProfileOpen}
          setEditProfileOpen={setEditProfileOpen}
        />
      )}
    </>
  );
}
