import React from "react";

export default function TimeZoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M8.50721 16.0836C7.46398 16.0836 6.48148 15.8863 5.55971 15.4915C4.63792 15.0968 3.8311 14.5537 3.13925 13.8622C2.44739 13.1706 1.90401 12.3645 1.5091 11.4437C1.1142 10.5229 0.916748 9.53906 0.916748 8.49216C0.916748 7.44524 1.11411 6.46437 1.50883 5.54953C1.90355 4.63469 2.44668 3.83134 3.13821 3.13949C3.82975 2.44763 4.63591 1.90425 5.55669 1.50935C6.47746 1.11444 7.4613 0.916992 8.50821 0.916992C9.55512 0.916992 10.536 1.11435 11.4508 1.50908C12.3657 1.9038 13.169 2.44692 13.8609 3.13845C14.5527 3.82999 15.0961 4.63453 15.491 5.55206C15.8859 6.46958 16.0834 7.44995 16.0834 8.49316C16.0834 9.53638 15.886 10.5189 15.4913 11.4407C15.0966 12.3625 14.5534 13.1693 13.8619 13.8611C13.1704 14.553 12.3658 15.0964 11.4483 15.4913C10.5308 15.8862 9.55041 16.0836 8.50721 16.0836ZM7.50006 14.917V13.5003C7.22228 13.5003 6.98617 13.4024 6.79173 13.2066C6.59728 13.0107 6.50006 12.7753 6.50006 12.5003V11.5003L2.14589 7.14614C2.09034 7.41003 2.05214 7.65308 2.03131 7.87531C2.01048 8.09753 2.00006 8.30586 2.00006 8.50031C2.00006 10.0975 2.51742 11.5072 3.55214 12.7295C4.58687 13.9517 5.90284 14.6809 7.50006 14.917ZM13.5001 12.6461C13.7501 12.3545 13.9688 12.0489 14.1563 11.7295C14.3438 11.41 14.4991 11.0729 14.6221 10.718C14.7452 10.363 14.8389 9.99846 14.9034 9.6242C14.9678 9.24994 15.0001 8.87531 15.0001 8.50031C15.0001 7.18228 14.6403 5.97858 13.9207 4.8892C13.2012 3.79981 12.2276 2.99817 11.0001 2.48428V3.00031C11.0001 3.41281 10.8532 3.76593 10.5594 4.05968C10.2657 4.35343 9.91256 4.50031 9.50006 4.50031H7.50006V5.50031C7.50006 5.78364 7.40423 6.02114 7.21256 6.21281C7.02089 6.40447 6.78339 6.50031 6.50006 6.50031H5.50006V8.50031H10.5001C10.7834 8.50031 11.0209 8.59614 11.2126 8.78781C11.4042 8.97947 11.5001 9.21697 11.5001 9.50031V11.5003H12.3542C12.6737 11.5003 12.9445 11.6114 13.1667 11.8336C13.389 12.0559 13.5001 12.3267 13.5001 12.6461Z"
        fill="#1C1B1F"
      />
    </svg>
  );
}
