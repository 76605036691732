import React, { useCallback } from "react";
import { withFormik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import IOSSwitch from "../../../../components/IOSSwitch";
import beepLogo from "../../../../img/logo-with-text.svg";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import CustomFormInput from "../../../../components/CustomFormInput";
import VideoCallIcon from "../../../../components/icons/VideoCallIcon";
import MessagesIcon from "../../../../components/icons/MessagesIcon";
import SpeakingIcon from "../../../../components/icons/SpeakingIcon";
import BackButton from "../../../../components/BackButton";
import styles from "./UpdateServicesForm.module.css";
import CustomButton from "../../../../components/CustomButton";

const validationSchema = Yup.object().shape({
  dmPrice: Yup.number().min(5, "Your DM price cannot be lower than $5"),
  videoCallHourlyRate: Yup.number().min(5, "Pricing cannot be lower than $5"),
});

function roundToTwoDecimalsIfNeeded(num) {
  if (!Number.isInteger(num)) {
    return num.toFixed(2);
  }
  return num;
}

const ServicesForm = ({
  userId,
  values,
  handleSubmit,
  setFieldValue,
  onError,
  errors,
  touched,
  loading,
  setFieldTouched,
  setFieldError,
}) => {
  const isValidForm = useCallback(
    (setError = true) => {
      let isValid = true;
      if (!values.videoCallsEnabled && !values.messagesEnabled) {
        if (setError) onError("Please enable at least one service");
        isValid = false;
      }
      if (values.videoCallsEnabled && !values.videoCallHourlyRate) {
        if (setError)
          setFieldError(
            "videoCallHourlyRate",
            "Please set your hourly video call rate"
          );
        isValid = false;
      }
      if (values.messagesEnabled && !values.dmPrice) {
        if (setError) setFieldError("dmPrice", "Please set your DM price");
        isValid = false;
      }
      if (
        values.videoCallsEnabled &&
        values.videoDurationsEnabled.length === 0
      ) {
        if (setError) onError("Please enable at least one video call duration");
        isValid = false;
      }
      return isValid;
    },
    [
      onError,
      setFieldError,
      values.videoCallsEnabled,
      values.videoCallHourlyRate,
      values.messagesEnabled,
      values.dmPrice,
      values.videoDurationsEnabled,
    ]
  );

  const handleFormSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (!isValidForm()) return;

      handleSubmit(values);
    },
    [handleSubmit, values, isValidForm]
  );

  const handleDurationChange = (duration) => (event, newValue) => {
    const newDurations = (
      newValue
        ? [...values.videoDurationsEnabled, duration]
        : values.videoDurationsEnabled.filter((dur) => dur !== duration)
    ).sort((a, b) => a - b);
    setFieldValue("videoDurationsEnabled", newDurations);
  };

  return (
    <>
      <div className={styles.header}>
        <Stack direction="row">
          <BackButton />
          <img src={beepLogo} alt="logo" width={87} height={22} />
        </Stack>
        <Typography variant="body2" fontWeight={600}>
          Services & Pricing
        </Typography>
      </div>
      <form onSubmit={handleFormSubmit} className={styles.container}>
        <Typography variant="header1" fontSize={"22px"}>
          Select Your Services
          <br /> and Set Your Price
        </Typography>
        <Box
          border={`1px solid ${
            values?.videoCallHourlyRate < 5 ? "#cc3c20" : "#d9d9d9"
          }`}
          borderRadius="8px"
          p="12px"
          height="auto"
          mt={3}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" gap={1} alignItems="center">
              <VideoCallIcon />
              <Typography fontFamily="Lato" fontSize="14px">
                Video Call Bookings
              </Typography>
            </Stack>
          </Stack>
          {values.videoCallsEnabled && (
            <>
              <Box mt="15px">
                <CustomFormInput
                  name="videoCallHourlyRate"
                  label="Your hourly rate"
                  placeholder="Hourly Rate"
                  type="number"
                  startAdornment="$"
                  inputProps={{ min: 5 }}
                />
              </Box>

              <Stack gap="15px" sx={{ mt: 2 }}>
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    value="end"
                    label="15 min bookings"
                    labelPlacement="end"
                    sx={{
                      color: "#000",
                      marginLeft: "-3px",
                      gap: "5px",
                    }}
                    classes={{
                      label: "service-form-label ",
                    }}
                    control={
                      <Checkbox
                        disabled={values?.videoCallHourlyRate < 5}
                        checked={values.videoDurationsEnabled?.includes(15)}
                        onChange={handleDurationChange(15)}
                        sx={{
                          color: "#000",
                          "&.Mui-checked": {
                            color: "#000",
                          },
                          "&.Mui-disabled": {
                            color: "#a2a2a2",
                          },
                        }}
                      />
                    }
                  />
                  {values.videoCallHourlyRate && (
                    <Typography
                      variant="body1"
                      color={
                        values?.videoCallHourlyRate < 5 ? "#a2a2a2" : "#000"
                      }
                      fontWeight={700}
                      fontSize="14px"
                    >
                      $
                      {roundToTwoDecimalsIfNeeded(
                        values.videoCallHourlyRate / 4
                      )}
                      /session
                    </Typography>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    value="end"
                    label="30 min bookings"
                    labelPlacement="end"
                    sx={{
                      color: "#000",
                      marginLeft: "-3px",
                      gap: "5px",
                    }}
                    classes={{
                      label: "service-form-label ",
                    }}
                    control={
                      <Checkbox
                        disabled={values?.videoCallHourlyRate < 5}
                        checked={values.videoDurationsEnabled?.includes(30)}
                        onChange={handleDurationChange(30)}
                        sx={{
                          color: "#000",
                          "&.Mui-checked": {
                            color: "#000",
                          },
                          "&.Mui-disabled": {
                            color: "#a2a2a2",
                          },
                        }}
                      />
                    }
                  />
                  {values.videoCallHourlyRate && (
                    <Typography
                      variant="body1"
                      color={
                        values?.videoCallHourlyRate < 5 ? "#a2a2a2" : "#000"
                      }
                      fontWeight={700}
                      fontSize="14px"
                    >
                      $
                      {roundToTwoDecimalsIfNeeded(
                        values.videoCallHourlyRate / 2
                      )}
                      /session
                    </Typography>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    value="end"
                    label="60 min bookings"
                    labelPlacement="end"
                    sx={{
                      color: "#000",
                      marginLeft: "-3px",
                      gap: "5px",
                    }}
                    classes={{
                      label: "service-form-label",
                    }}
                    control={
                      <Checkbox
                        disabled={values?.videoCallHourlyRate < 5}
                        checked={values.videoDurationsEnabled?.includes(60)}
                        onChange={handleDurationChange(60)}
                        sx={{
                          color: "#000",
                          "&.Mui-checked": {
                            color: "#000",
                          },
                          "&.Mui-disabled": {
                            color: "#a2a2a2",
                          },
                        }}
                      />
                    }
                  />
                  {values.videoCallHourlyRate && (
                    <Typography
                      variant="body1"
                      color={
                        values?.videoCallHourlyRate < 5 ? "#a2a2a2" : "#000"
                      }
                      fontWeight={700}
                      fontSize="14px"
                    >
                      ${values.videoCallHourlyRate}/session
                    </Typography>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    value="end"
                    label="90 min bookings"
                    labelPlacement="end"
                    sx={{
                      color: "#000",
                      marginLeft: "-3px",
                      gap: "5px",
                    }}
                    classes={{
                      label: "service-form-label ",
                    }}
                    control={
                      <Checkbox
                        disabled={values?.videoCallHourlyRate < 5}
                        checked={values.videoDurationsEnabled?.includes(90)}
                        onChange={handleDurationChange(90)}
                        sx={{
                          color: "#000",
                          "&.Mui-checked": {
                            color: "#000",
                          },
                          "&.Mui-disabled": {
                            color: "#a2a2a2",
                          },
                        }}
                      />
                    }
                  />
                  {values.videoCallHourlyRate && (
                    <Typography
                      variant="body1"
                      color={
                        values?.videoCallHourlyRate < 5 ? "#a2a2a2" : "#000"
                      }
                      fontWeight={700}
                      fontSize="14px"
                    >
                      ${values.videoCallHourlyRate * 1.5}/session
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </>
          )}
        </Box>
        <Box
          border={`1px solid ${values?.dmPrice < 5 ? "#cc3c20" : "#d9d9d9"}`}
          borderRadius="8px"
          p="12px"
          height="auto"
          mt="20px"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" gap={1}>
              <MessagesIcon />
              <Typography fontFamily="Lato" fontSize="14px">
                Messages
              </Typography>
            </Stack>
            <IOSSwitch
              sx={{ m: 1 }}
              checked={values.messagesEnabled}
              onChange={(event, newValue) => {
                setFieldValue("messagesEnabled", newValue);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Stack>

          <Box mt="10px">
            <CustomFormInput
              name="dmPrice"
              label="Your fee"
              placeholder="DM Fee"
              type="number"
              startAdornment="$"
              inputProps={{ min: 5 }}
              sx={{ mb: 2 }}
              disabled={!values.messagesEnabled}
            />
          </Box>
        </Box>
        <Box
          border="1px solid #D9D9D9"
          borderRadius="8px"
          height="auto"
          mt="20px"
          mb="40px"
        >
          <Box
            sx={{
              background: "rgba(243, 243, 244, 0.90)",
              textAlign: "center",
              p: 1.5,
            }}
          >
            <Typography variant="header1">Coming soon!</Typography>
          </Box>
          <Box p="12px">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" gap={1}>
                <SpeakingIcon />
                <Typography fontFamily="Lato" fontSize="14px">
                  Keynote Speaking
                </Typography>
              </Stack>
            </Stack>

            <Typography color="#000" fontFamily="Lato" fontSize="14px" my={1}>
              Book in-person or virtual speaking events directly from your Beep
              dashboard!
            </Typography>
          </Box>
        </Box>
        <CustomButton type="submit" loading={loading}>
          Save
        </CustomButton>
      </form>
    </>
  );
};

ServicesForm.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setSubmitting: PropTypes.func,
  error: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.func,
  loading: PropTypes.bool,
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => initialValues,
  validationSchema,
  handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
    onSubmit(values);
    setSubmitting(false);
  },
  displayName: "ServicesForm",
})(ServicesForm);
