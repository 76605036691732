import BottomDrawer from "../../../components/BottomDrawer";
import { Box, Stack, Typography } from "@mui/material";
import { ReactComponent as Checkmark } from "../../../img/check-black.svg";
import CustomButton from "../../../components/CustomButton";
import { ReactComponent as BeepLogo } from "../../../img/logo-with-text.svg";

const WelcomeModal = ({ isOpen, onClose }) => {
  return (
    <BottomDrawer isOpen={isOpen} canExit={false} showHandle>
      <Stack alignItems={"center"} gap={2} mt={1}>
        <BeepLogo height={23} width={92} />
        <Typography variant="header1" fontSize={"20px"}>
          Quick Questions, Answered
        </Typography>
      </Stack>
      <Stack direction="row" mb={2} mt={3} gap={3}>
        <Checkmark width={"20px"} height={"30px"} style={{ margin: 1 }} />
        <Typography variant="body1" fontSize={"17px"} flexGrow={1.5} mt={0.4}>
          Get fast, personalized responses
        </Typography>
      </Stack>
      <Stack direction="row" gap={3}>
        <Checkmark width={"20px"} height={"28px"} style={{ margin: 1 }} />
        <Typography variant="body1" fontSize={"17px"} flexGrow={1.5} mt={0.4}>
          Receive responses within 7 days
          <br /> or be automatically refunded
        </Typography>
      </Stack>
      <Box sx={{ mt: 3 }}>
        <CustomButton sx={{ background: "#000 !important" }} onSubmit={onClose}>
          Enter thread
        </CustomButton>
      </Box>
    </BottomDrawer>
  );
};

export default WelcomeModal;
