import React, { useState } from "react";
import { Stack } from "@mui/material";
import BottomDrawer from "../BottomDrawer";
import CustomButton from "../CustomButton";
import CustomFormInput from "../CustomFormInput";
import styles from "./CreatorAccount.module.css";

export default function UpdateName({
  firstName,
  lastName,
  setFieldValue,
  open,
  setOpen,
  onSubmit,
}) {
  const [firstNameValue, setFirstNameValue] = useState(firstName || "");
  const [lastNameValue, setLastNameValue] = useState(lastName || "");

  const handleFirstNameChange = (e) => {
    setFirstNameValue(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastNameValue(e.target.value);
  };

  const handleSave = (event) => {
    setFieldValue("firstName", firstNameValue);
    setFieldValue("lastName", lastNameValue);
    setOpen(false);
    onSubmit(event);
  };

  const handleClose = () => {
    setOpen(false);
    setFirstNameValue(firstName);
    setLastNameValue(lastName);
  };

  return (
    <BottomDrawer
      isOpen={open}
      onClose={() => handleClose()}
      header="Change name"
      showHandle
      canExit={false}
      showBack
    >
      <Stack mt={3} gap={2}>
        <CustomFormInput
          name="firstName"
          label="First name*"
          sx={{ mb: 2 }}
          value={firstNameValue}
          onChange={handleFirstNameChange}
          inputClassName={styles.accountSettingsInput}
        />
        <CustomFormInput
          name="lastName"
          label="Last name*"
          sx={{ mb: 2 }}
          value={lastNameValue}
          onChange={handleLastNameChange}
          inputClassName={styles.accountSettingsInput}
        />
      </Stack>
      <Stack direction={"row"} gap={2} mt={3}>
        <CustomButton variant="secondary" onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton
          onClick={handleSave}
          disabled={!firstNameValue || !lastNameValue}
        >
          Save
        </CustomButton>
      </Stack>
    </BottomDrawer>
  );
}
