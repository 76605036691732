import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#5113e7",
      light: "#000",
      dark: "#000",
      contrastText: "#fff",
    },
    // secondary: {
    //     main: "#fff",
    //     light: "#000",
    //     dark: "#000",
    //     contrastText: "#fff",
    // },
    // error: {
    //     main: "#fff",
    //     light: "#000",
    //     dark: "#000",
    //     contrastText: "#fff",
    // },
    // warning: {
    //     main: "#fff",
    //     light: "#000",
    //     dark: "#000",
    //     contrastText: "#fff",
    // },
    // info: {
    //     main: "#fff",
    //     light: "#000",
    //     dark: "#000",
    //     contrastText: "#fff",
    // },
    // success: {
    //     main: "#fff",
    //     light: "#000",
    //     dark: "#000",
    //     contrastText: "#fff",
    // },
    purple: {
      main: "#5113e7",
    },
    grey: {
      main: "#9D9DB5",
      // light: "#000",
      // dark: "#000",
      // contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Heebo", "Poppins", "sans-serif"].join(","),
    h1: {
      fontFamily: "Heebo",
    },
    h2: {
      fontFamily: "Heebo",
    },
    h3: {
      fontFamily: "Heebo",
      fontSize: "22px",
    },
    h4: {
      fontFamily: "Heebo",
      fontSize: "20px",
    },
    h5: {
      fontFamily: "Heebo",
    },
    h6: {
      fontFamily: "Heebo",
    },
    header1: {
      fontFamily: "Archivo",
      fontSize: "18px",
      fontWeight: 900,
    },
    body1: {
      fontFamily: "Lato",
      fontSize: "16px",
    },
    body2: {
      fontFamily: "Lato",
      fontSize: "14px",
    },
    subtitle1: {
      fontFamily: "Poppins",
    },
    button: {
      fontFamily: "Poppins",
    },
  },
  components: {
    // https://mui.com/material-ui/customization/theme-components/#global-style-overrides
    // component overrides here
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
          fontSize: "18px",
          color: "#9D9DB5",
        },
      },
    },
  },
};

export default responsiveFontSizes(createTheme(theme));
