import React, { useContext } from "react";
import { Stack, Typography } from "@mui/material";
import BottomDrawer from "../BottomDrawer";
import CustomButton from "../CustomButton";
import useMutate from "../../hooks/useMutate";
import { UserContext } from "../../context";

export default function DeleteAccount({ userId, open, setOpen, onSubmit }) {
  const { logout } = useContext(UserContext);
  const [deleteAccount, { loading: deleting }] = useMutate(`/user/${userId}`, {
    method: "delete",
  });

  const handleDelete = () => {
    deleteAccount({}).then(logout);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BottomDrawer
      isOpen={open}
      onClose={() => handleClose()}
      header="Delete Account"
      showHandle
      canExit={false}
      showBack
    >
      <Stack mt={3} gap={2}>
        <Typography variant="body2">
          <b>This action cannot be undone.</b>
          <br />
          <br /> Deleting your account is permanent and all data related to your
          Beep account will be removed from the platform. <br />
          <br />
          By deleting your account, you will no longer be able to access your
          account or any messages, calls, scheduled events, or any other
          interactions booked through your Beep account. <br />
          <br />
          Would you like to proceed?
        </Typography>
      </Stack>
      <Stack direction={"row"} gap={2} mt={3}>
        <CustomButton variant="secondary" onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton
          disabled={deleting}
          loading={deleting}
          onClick={handleDelete}
        >
          Delete
        </CustomButton>
      </Stack>
    </BottomDrawer>
  );
}
