import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { Box, Button, Stack, Typography } from "@mui/material";
import styles from "./UpdateAccoutForm.module.css";
import CustomButton from "../../../../components/CustomButton";
import BackButton from "../../../../components/BackButton";
import beepLogo from "../../../../img/logo-with-text.svg";
import { useNavigate } from "react-router-dom";
import UpdateName from "../../../../components/CreatorAccount/UpdateName";
import UpdateEmail from "../../../../components/CreatorAccount/UpdateEmail";
import DeleteAccount from "../../../../components/CreatorAccount/DeleteAccount";

const UpdateAccountForm = ({
  userId,
  values,
  handleSubmit,
  setFieldValue,
  onError,
  errors,
  touched,
  loading,
  setFieldTouched,
  setFieldError,
  refetchPayments,
}) => {
  const navigate = useNavigate();
  const [emailOpen, setEmailOpen] = useState(false);
  const [nameOpen, setNameOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleFormSubmit = useCallback(
    (event) => {
      event.preventDefault();

      handleSubmit(values);
    },
    [handleSubmit, values]
  );

  return (
    <>
      <div className={styles.header}>
        <Stack direction="row">
          <BackButton />
          <img src={beepLogo} alt="logo" width={87} height={22} />
        </Stack>
        <Typography variant="body2" fontWeight={600}>
          Account Settings
        </Typography>
      </div>
      <div className={styles.container}>
        <Typography variant="header1" fontSize={"22px"} my={2}>
          My Account
        </Typography>
        <Box
          border="1px solid #D9D9D9"
          borderRadius="8px"
          p="12px"
          height="auto"
          mt={3}
        >
          <Stack className="stackLayout">
            <Typography variant="header1">Name</Typography>{" "}
            <Button
              className={styles.editButton}
              onClick={() => setNameOpen(true)}
            >
              Edit
            </Button>
          </Stack>
          <Stack width={"85%"}>
            <Typography variant="body2" color={"rgba(0, 0, 0, 0.50)"}>
              {values?.firstName} {values?.lastName}
            </Typography>
          </Stack>
        </Box>

        <Box
          border="1px solid #D9D9D9"
          borderRadius="8px"
          p="12px"
          height="auto"
          mt={4}
        >
          <Stack className="stackLayout">
            <Typography variant="header1">Email</Typography>{" "}
            <Button
              className={styles.editButton}
              onClick={() => setEmailOpen(true)}
            >
              Edit
            </Button>
          </Stack>
          <Stack width={"85%"}>
            <Typography variant="body2" color={"rgba(0, 0, 0, 0.50)"}>
              {values?.email}
            </Typography>
          </Stack>
        </Box>

        {/* <Box
          border="1px solid #D9D9D9"
          borderRadius="8px"
          p="12px"
          height="auto"
          mt={4}
        >
          <Stack className="stackLayout">
            <Typography variant="header1">Payment Methods</Typography>{" "}
            <Button className={styles.editButton}>Edit</Button>
          </Stack>
          <Stack width={"85%"}>
            <Typography variant="body2" color={"rgba(0, 0, 0, 0.50)"}>
              {values?.email}
            </Typography>
          </Stack>
        </Box> */}
        <Box my={2}>
          <Button
            className={styles.editButton}
            onClick={() => setDeleteOpen(true)}
          >
            Delete Account
          </Button>
        </Box>
        <Stack mt={6} mb={4}>
          <CustomButton onClick={() => navigate(-1)}>Save</CustomButton>
        </Stack>
        <UpdateName
          firstName={values?.firstName}
          lastName={values?.lastName}
          setFieldValue={setFieldValue}
          open={nameOpen}
          setOpen={setNameOpen}
          onSubmit={handleFormSubmit}
        />
        <UpdateEmail
          email={values?.email}
          setFieldValue={setFieldValue}
          open={emailOpen}
          setOpen={setEmailOpen}
          onSubmit={handleFormSubmit}
        />
        <DeleteAccount
          userId={userId}
          open={deleteOpen}
          setOpen={setDeleteOpen}
        />
      </div>
    </>
  );
};

UpdateAccountForm.propTypes = {
  isOpen: PropTypes.bool,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  onLoginClick: PropTypes.func,
  onError: PropTypes.func,
  errors: PropTypes.string,
  touched: PropTypes.func,
  values: PropTypes.any,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => initialValues,
  validationSchem: {},
  handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
    onSubmit(values);
    setSubmitting(false);
  },
  displayName: "UpdateAccountForm",
})(UpdateAccountForm);
