import React, { useCallback, useContext, useEffect } from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import zoom from "../../../../img/zoom.png";
import googleMeet from "../../../../img/google-meet.png";
import CustomButton from "../../../../components/CustomButton";
import LoadingIconButton from "../../../../components/LoadingIconButton";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import SuccessIcon from "../../../../components/icons/SuccessIcon";
import useFetch from "../../../../hooks/useFetch";
import useLazyFetch from "../../../../hooks/useLazyFetch";
import { UserContext } from "../../../../context";
import useMutate from "../../../../hooks/useMutate";
import { useSearchParams } from "react-router-dom";

const ConferencingForm = ({ values }) => {
  const { data, loading } = useFetch("/user/connected-apps");
  const { user, onError, refreshUser } = useContext(UserContext);
  const [connectConferencing, { loading: connecting }] = useLazyFetch(
    `/conferencing/connect/zoom`,
    `/creator/integrations`
  );
  const [revokeToken, { loading: revoking }] = useMutate(`/token/revoke`, {
    method: "PUT",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const success = searchParams.get("success");
  const error = searchParams.get("error");

  useEffect(() => {
    if (success || error) {
      if (error) onError("Unable to connect calendar");
      searchParams.delete("success");
      searchParams.delete("error");
      setSearchParams(searchParams);
    }
  }, [success, error, onError, searchParams, setSearchParams]);

  const handleConnectConferencing = useCallback(async () => {
    const { data, error } = await connectConferencing();
    if (error) return onError(error);
    if (data && data.url) {
      window.location.replace(data.url);
    }
  }, [connectConferencing, onError]);

  const handleRemoveCalendar = useCallback(async () => {
    await revokeToken({
      type: "ZOOM_CONFERENCING",
      userId: user?.creator?.id,
    }).then(async (response) => {
      if (response?.error) {
        onError(response?.error || "Something went wrong. Please try again.");
        return;
      }
    });
    connectConferencing();
    refreshUser();
  }, [
    onError,
    revokeToken,
    connectConferencing,
    refreshUser,
    user?.creator?.id,
  ]);

  const zoomConnection = data?.connections?.find(
    (app) => app.type === "ZOOM_CONFERENCING"
  );

  return (
    <>
      <Typography variant="body2" color={"#717192"} my={2}>
        Connect your favorite video apps so your
        <br /> audience can easily book calls with you.
      </Typography>
      {!loading ? (
        <Stack gap={2}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            gap={1}
            sx={{
              borderRadius: "8px",
              border: "1px solid #D9D9D9",
              padding: "7px 12px",
            }}
          >
            <Stack
              direction="row"
              gap={1}
              alignItems={zoomConnection ? "start" : "center"}
            >
              <img src={zoom} alt="Zoom" width={28} height={28} />
              <Box>
                <Typography variant="body1">Zoom</Typography>
                {zoomConnection && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#717192",
                      fontSize: "12px",
                    }}
                  >
                    {zoomConnection.email}
                  </Typography>
                )}
                {zoomConnection && (
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    gap={0.5}
                    mt="3px"
                  >
                    <SuccessIcon />
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#717192",
                        fontSize: "12px",
                        fontWeight: 900,
                      }}
                    >
                      Connected
                    </Typography>
                  </Stack>
                )}
              </Box>
            </Stack>
            {zoomConnection ? (
              <LoadingIconButton
                sx={{
                  height: "fit-content",
                  mt: "-12px",
                  mr: "-5px",
                }}
                onClick={handleRemoveCalendar}
                loading={revoking}
              >
                <DeleteIcon />
              </LoadingIconButton>
            ) : (
              <CustomButton
                sx={{
                  width: "83px",
                  minHeight: "30px !important",
                  height: "30px !important",
                  fontSize: "15px",
                }}
                onSubmit={handleConnectConferencing}
                loading={connecting}
              >
                Connect
              </CustomButton>
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            gap={1}
            sx={{
              borderRadius: "8px",
              border: "1px solid #D9D9D9",
              padding: "7px 12px",
            }}
          >
            <Stack direction="row" gap={1} alignItems={"center"}>
              <img src={googleMeet} alt="Zoom" width={28} height={28} />
              <Box>
                <Typography variant="body1">Google Meet</Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: "#717192",
                    fontSize: "12px",
                  }}
                >
                  Gmail or Google Workspace
                </Typography>
              </Box>
            </Stack>
            <CustomButton
              disabled
              sx={{
                width: "114px",
                minHeight: "30px !important",
                height: "30px !important",
                fontSize: "15px",
              }}
            >
              Coming Soon
            </CustomButton>
          </Stack>
          <Typography variant="body2">
            Heads Up ⚠️: Without integrating a Video Call tool, you will need to
            add it manually to your calendar invite in order to offer 1:1 Video
            Calls.
          </Typography>
        </Stack>
      ) : (
        <Stack gap={2}>
          <Skeleton variant="rectangular" width={"100%"} height={60} />
          <Skeleton variant="rounded" height={60} />
        </Stack>
      )}
    </>
  );
};

export default ConferencingForm;
