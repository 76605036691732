import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Header from "./Header";
import "./index.css";
import useLocalStorage from "../../hooks/useLocalStorage";
import useChat from "../../hooks/useChat";
import { UserContext } from "../../context";
import { Elements } from "@stripe/react-stripe-js";
import useFetch from "../../hooks/useFetch";
import useMutate from "../../hooks/useMutate";
import Media from "../Media";
import { useCreatorAnalytics, useFanAnalytics } from "../../hooks/useAnalytics";
import { useNavigate } from "react-router-dom";
import ReplyInput from "../ReplyInput";
import PaidReplyInput from "../PaidReplyInput";
import PhoneNumberDrawer from "./PhoneNumberDrawer";
import CardInfoDialog from "../CardInfoDialog";
import CustomButton from "../CustomButton";

const Chat = memo(
  ({
    recipient,
    isCreatorView,
    chatId,
    onAnonymousSend,
    onError,
    onBack,
    showWelcomeMessage,
    handleFanLogin,
  }) => {
    const { user, stripePromise, refreshUser } = useContext(UserContext);
    const [
      { messages, participants },
      { sendMessage, setTyping, loadPrevMessages },
      { loading: messagesLoading, hasPrevPage },
    ] = useChat(chatId, 10);
    const [cardInfoOpen, setCardInfoOpen] = useState(false);
    const [phoneNumberOpen, setPhoneNumberOpen] = useState(false);
    const [persistedText, setPersistedText] = useLocalStorage(
      "persistedText",
      ""
    );
    const [text, setText] = useState(persistedText);
    const [order, setOrder] = useState();
    const [sending, setSending] = useState(false);
    const [delivered, setDelivered] = useState(false);
    const { data, refetch: refetchPayments } = useFetch(
      "/user/payment-methods",
      {
        skip: isCreatorView,
      }
    );
    const [updateUser, { loading: updating }] = useMutate(`/user/${user?.id}`, {
      method: "PUT",
    });
    const hasPaymentStored = data?.paymentMethods?.length > 0;
    const [media, setMedia] = useState([]);
    const [createOrder] = useMutate("/order");
    const [fulfillOrders] = useMutate("/order/fulfillment");
    const bottom = React.useRef();
    const sendCreatorEvent = useCreatorAnalytics();
    const sendFanEvent = useFanAnalytics();
    const navigate = useNavigate();
    const [setupPaymentOpen, setSetupPaymentOpen] = useState(false);

    useEffect(() => {
      document.body.classList.add("no-scroll");
      if (bottom.current) {
        bottom.current.scrollIntoView({ behavior: "smooth" });
      }
      return () => {
        document.body.classList.remove("no-scroll");
      };
    }, [messagesLoading]);

    const handleSendMessage = useCallback(async () => {
      let messageAttributes = order
        ? {
            orderId: order.id,
          }
        : {};
      if (media.length > 0) {
        messageAttributes.media = media.map((file) => file.id);
      }
      // TODO: setup unlockable
      // if(unlockables.length > 0) {
      //   messageAttributes.unlockables = [{
      //
      // }]
      // }

      await sendMessage(text, messageAttributes);
      setDelivered(true);
      setMedia([]);
      setText("");
      setSending(false);
      setPersistedText("");

      bottom.current.scrollIntoView({ behavior: "smooth" });
    }, [media, sendMessage, setPersistedText, text, order]);

    const handleCreatorSend = async () => {
      if (sending || (!text && media.length === 0)) return;

      if (messages.length > 1 || !user?.creator?.firstMessageFree) {
        const { error } = await fulfillOrders({
          fanId: recipient?.id,
          type: "MESSAGE",
        });
        if (error) {
          onError("Unable to send message. Please try again.");
          return;
        }
      }

      const event = {
        action: "full chat send message",
      };
      if (media.length > 0) {
        event.media_type = media[0].type;
      }
      sendCreatorEvent(event);

      handleSendMessage();
    };

    const onDismiss = () => {
      setCardInfoOpen(false);
      setOrder();
    };

    const handleSend = async () => {
      if (sending || (!text && media.length === 0)) return;
      if (!user) {
        setPersistedText(text);
        onAnonymousSend();
        return;
      }
      setSending(true);

      const event = {
        action: "send message success",
      };
      if (media.length > 0) {
        event.media_type = media.type;
      }

      if ((!messages || messages.length === 0) && recipient?.firstMessageFree) {
        sendFanEvent({ action: "send first message" });
        // first message free
        handleSendMessage();
      } else if (!hasPaymentStored) {
        sendFanEvent({ action: "send clicked without payment method" });
        if (!order) {
          const { data: orderData } = await createOrder({
            creatorId: recipient?.id,
            fanId: user.id,
          });
          setOrder(orderData);
        }
        sendFanEvent({ action: "add payment method" });
        setSending(false);
        setCardInfoOpen(true);
      } else {
        sendFanEvent({ action: "send clicked with payment method" });
        if (!order) {
          const { data: orderData } = await createOrder({
            creatorId: recipient?.id,
            fanId: user.id,
          });
          setOrder(orderData);
        }
      }
    };

    const handleTextChange = (event) => {
      setText(event.target.value);
      setTyping();
    };

    const handlePaymentSubmit = useCallback(
      async (refetch = true) => {
        setSending(true);
        setCardInfoOpen(false);
        setOrder();
        if (refetch) refetchPayments();
        sendFanEvent({ action: "add credit card success" });

        handleSendMessage();
      },
      [handleSendMessage, refetchPayments, sendFanEvent]
    );

    const handlePaymentError = useCallback(
      (error) => {
        sendFanEvent({ action: "add credit card error" });

        onError(error?.message);
      },
      [sendFanEvent, onError]
    );

    const handleTurnOnSMS = () => {
      if (!user.phoneNumber) {
        return setPhoneNumberOpen(true);
      }
      updateUser({
        phoneNotifications: true,
      }).then(refreshUser);
    };

    const handlePhoneSubmit = useCallback(
      (values) => {
        updateUser(values).then(() => {
          refreshUser();
          setPhoneNumberOpen(false);
        });
      },
      [updateUser, refreshUser]
    );

    const hasWelcomeMessage =
      recipient?.welcome?.text ||
      recipient?.welcome?.image ||
      recipient?.welcome?.video;

    return (
      <>
        <Header
          firstName={recipient?.firstName || recipient?.username}
          lastName={recipient?.firstName ? recipient?.lastName : ""}
          avatarUrl={recipient?.avatarUrl}
          isOnline={participants[recipient?.id]?.isOnline}
          loading={!recipient}
          backButton
          onBack={onBack}
        />
        {messagesLoading ? (
          <div className="chat-body-loading">
            <div className="right-message-group-loading">
              <Skeleton
                variant="circular"
                width={50}
                height={50}
                className="avatar-loading"
              />
              <Skeleton
                variant="rectangular"
                height={118}
                className="right-message-loading"
              />
            </div>
            <div className="left-message-group-loading">
              <Skeleton
                variant="rectangular"
                height={92}
                className="left-message-loading"
              />
              <Skeleton
                variant="circular"
                width={50}
                height={50}
                className="avatar-loading"
              />
            </div>
            <div className="right-message-group-loading">
              <Skeleton
                variant="circular"
                width={50}
                height={50}
                className="avatar-loading"
              />
              <Skeleton
                variant="rectangular"
                height={144}
                className="right-message-loading"
              />
            </div>
            <div className="left-message-group-loading">
              <Skeleton
                variant="rectangular"
                height={74}
                className="left-message-loading"
              />
              <Skeleton
                variant="circular"
                width={50}
                height={50}
                className="avatar-loading"
              />
            </div>
          </div>
        ) : (
          <Box className="chat-body">
            {((messages && messages.length > 0) ||
              (showWelcomeMessage && hasWelcomeMessage)) && (
              <div className="chat-messages">
                {!hasPrevPage && showWelcomeMessage && hasWelcomeMessage && (
                  <div className="left-message-group">
                    {(recipient?.welcome?.image ||
                      recipient?.welcome?.video) && (
                      <div
                        key={
                          recipient?.welcome?.image || recipient?.welcome?.video
                        }
                        className={
                          recipient?.welcome.text
                            ? ""
                            : "left-media-message-group"
                        }
                      >
                        {!recipient?.welcome.text && (
                          <Avatar
                            src={recipient?.avatarUrl}
                            width={50}
                            height={50}
                            className="message-avatar"
                          />
                        )}
                        <Media
                          url={
                            recipient?.welcome?.image ||
                            recipient?.welcome?.video
                          }
                          type={recipient?.welcome?.image ? "image" : "video"}
                          className="left-message-media"
                        />
                      </div>
                    )}
                    {recipient?.welcome.text && (
                      <div className="message-group">
                        <Avatar
                          src={recipient?.avatarUrl}
                          width={50}
                          height={50}
                          className="message-avatar"
                        />
                        <div className="left-message">
                          {recipient?.welcome.text}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {hasPrevPage && (
                  <div className="load-more" onClick={loadPrevMessages}>
                    {"Load previous messages"}
                  </div>
                )}
                {messages &&
                  messages.map((msg, i) => (
                    <div key={msg.sid}>
                      {msg.author === user.id ||
                      msg.author === user?.creator?.id ? (
                        <div className="right-message-group">
                          {msg.attributes?.media?.map((mediaId) => (
                            <div
                              key={mediaId}
                              className={
                                msg.body ? "" : "right-media-message-group"
                              }
                            >
                              <Media
                                mediaId={mediaId}
                                className="right-message-media"
                              />
                              {!msg.body && (
                                <Avatar
                                  src={recipient?.avatarUrl}
                                  className="message-avatar"
                                />
                              )}
                            </div>
                          ))}
                          {msg.body && (
                            <div className="message-group">
                              <div style={{ flexGrow: 1 }} />
                              <div className="right-message">{msg.body}</div>
                              <Avatar
                                src={user.avatarUrl}
                                width={50}
                                height={50}
                                className="message-avatar"
                              />
                            </div>
                          )}
                          {i === messages.length - 1 && delivered && (
                            <p className="delivered">Delivered</p>
                          )}
                        </div>
                      ) : (
                        <div className="left-message-group">
                          {msg.attributes?.media?.map((mediaId) => (
                            <div
                              key={mediaId}
                              className={
                                msg.body ? "" : "left-media-message-group"
                              }
                            >
                              {!msg.body && (
                                <Avatar
                                  src={recipient?.avatarUrl}
                                  width={50}
                                  height={50}
                                  className="message-avatar"
                                />
                              )}
                              <Media
                                mediaId={mediaId}
                                className="left-message-media"
                              />
                            </div>
                          ))}
                          {msg.body && (
                            <div className="message-group">
                              <Avatar
                                src={recipient?.avatarUrl}
                                width={50}
                                height={50}
                                className="message-avatar"
                              />
                              <div className="left-message">{msg.body}</div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                {participants[recipient?.id]?.isTyping && (
                  <div className="left-message-group">
                    <div className="message-group">
                      <Avatar
                        src={recipient?.avatarUrl}
                        width={50}
                        height={50}
                        className="message-avatar"
                      />
                      <div className="typing-indicator">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                )}
                <div ref={bottom} />
              </div>
            )}
            {messages &&
              messages.length === 0 &&
              (!showWelcomeMessage || !hasWelcomeMessage) && (
                <div className="default">
                  <div className="default-container">
                    <h5>Ask me anything!</h5>
                    <p className="info">
                      Beep {recipient?.firstName} for ${recipient?.dmPrice} a
                      message.
                      <br />
                      {recipient?.firstName} will reply within 7 days or your
                      message charge will be refunded.
                    </p>
                  </div>
                </div>
              )}
            {/* {!hasPaymentStored && user && !isCreatorView && (
              <div className="highlighted-area">
                <p>
                  <span
                    className="highlight"
                    onClick={() => setSetupPaymentOpen(true)}
                  >
                    Enter a payment method
                  </span>{" "}
                  to continue messaging.
                </p>
              </div>
            )} */}
            {hasPaymentStored && user && !user?.phoneNotifications && (
              <div className="highlighted-area">
                <p className="sms-message">
                  Get notified when {recipient?.firstName} responds
                </p>
                <p className="highlight" onClick={handleTurnOnSMS}>
                  Turn on SMS notifications
                </p>
              </div>
            )}
            {/* {!user && (
              <div className="highlighted-area">
                <p>
                  <span
                    className="highlight"
                    onClick={() => handleLogin("signup")}
                  >
                    Create an account
                  </span>{" "}
                  or{" "}
                  <span
                    className="highlight"
                    onClick={() => handleLogin("login")}
                  >
                    login
                  </span>{" "}
                  to continue messaging.
                </p>
              </div>
            )} */}
          </Box>
        )}
        <div className="footer-section">
          <div className="reply">
            {messagesLoading ? (
              <Skeleton
                variant="text"
                height={70}
                className="reply-input-loading"
              />
            ) : !user ? (
              <Box>
                <CustomButton
                  sx={{ background: "#000 !important" }}
                  onClick={() => onAnonymousSend()}
                >
                  Sign Up to Chat
                </CustomButton>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  mt="10px"
                >
                  <Typography variant="body1" fontSize={"16px"}>
                    Already have an account?
                  </Typography>
                  <Button
                    onClick={handleFanLogin}
                    style={{
                      textDecoration: "underline",
                      textTransform: "none",
                      color: "#000",
                      fontSize: "16px",
                      fontFamily: "Lato",
                      fontWeight: 900,
                    }}
                  >
                    Log in
                  </Button>
                </Stack>
              </Box>
            ) : (
              <>
                {order || setupPaymentOpen ? (
                  <Elements
                    stripe={stripePromise}
                    options={
                      order
                        ? { clientSecret: order.clientSecret }
                        : { mode: "setup", currency: "usd" }
                    }
                  >
                    {setupPaymentOpen && (
                      <CardInfoDialog
                        open={setupPaymentOpen}
                        showSave={false}
                        setupIntent={true}
                        onDismiss={() => setSetupPaymentOpen(false)}
                        onSubmit={() => {
                          refetchPayments();
                          setSetupPaymentOpen(false);
                        }}
                        onError={onError}
                      />
                    )}
                    {order && (
                      <PaidReplyInput
                        order={order}
                        onPaymentError={handlePaymentError}
                        onPaymentSubmit={handlePaymentSubmit}
                        onDismiss={onDismiss}
                        onSubmit={() => setCardInfoOpen(false)}
                        cardInfoOpen={cardInfoOpen}
                        paymentMethod={data?.paymentMethods[0]}
                        isCreatorView={isCreatorView}
                        onFilesUploaded={setMedia}
                        onFilesRemoved={() => setMedia([])}
                        value={text}
                        focus={!!persistedText}
                        onChange={handleTextChange}
                        payToSend={
                          !recipient?.firstMessageFree ||
                          (messages?.length > 0 && !isCreatorView)
                        }
                        recipient={recipient}
                        onError={onError}
                        sending={sending}
                        message={text}
                      />
                    )}
                  </Elements>
                ) : (
                  <ReplyInput
                    isCreatorView={isCreatorView}
                    onFilesUploaded={setMedia}
                    onFilesRemoved={() => setMedia([])}
                    value={text}
                    focus={!!persistedText}
                    onChange={handleTextChange}
                    payToSend={
                      !recipient?.firstMessageFree ||
                      (messages?.length > 0 && !isCreatorView)
                    }
                    recipient={recipient}
                    onSend={isCreatorView ? handleCreatorSend : handleSend}
                    onError={onError}
                    sending={sending}
                    placeholder="Message..."
                  />
                )}
              </>
            )}
          </div>
          <p className="footer" onClick={() => navigate("/creator/onboarding")}>
            Start earning with Beep
          </p>
        </div>
        <PhoneNumberDrawer
          isOpen={phoneNumberOpen}
          onClose={() => setPhoneNumberOpen(false)}
          onSubmit={handlePhoneSubmit}
          loading={updating}
        />
      </>
    );
  }
);

Chat.propTypes = {
  recipient: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
    dmPrice: PropTypes.number,
  }),
  user: PropTypes.shape({
    id: PropTypes.string,
    avatarUrl: PropTypes.string,
    phoneNotifications: PropTypes.bool,
  }),
  isCreatorView: PropTypes.bool,
  chatId: PropTypes.string,
  onAnonymousSend: PropTypes.func,
  onError: PropTypes.func,
  backButton: PropTypes.bool,
};

Chat.displayName = "Chat";

export default Chat;
