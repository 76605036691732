import React, { useContext } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import "./index.css";
import { ReactComponent as BeepLogo } from "../../../../../img/logo-with-text.svg";
import { ReactComponent as BeepLogoCompleted } from "../../../../../img/logo-completed.svg";
import { ReactComponent as BackArrow } from "../../../../../img/back-arrow.svg";
import { ReactComponent as BackArrowWhite } from "../../../../../img/back-arrow-white.svg";
import { ReactComponent as ButtonArrow } from "../../../../../img/button-arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { Box, Stack } from "@mui/system";
import { SIGNUP_STEPS } from "../../../../../lib/signup";
import CustomButton from "../../../../../components/CustomButton";
import { Form } from "formik";
import { UserContext } from "../../../../../context";

const Template = ({
  title,
  subtitle,
  children,
  step,
  ctaText = "Continue",
  onSubmit,
  onBack,
  onNext,
  disabled,
  showProgress = true,
  isRequired = true,
  variant = "primary",
  showTerms,
  showLogin,
  isCompleted,
}) => {
  const { login } = useContext(UserContext);
  const navigate = useNavigate();
  const currentStep = step + 1;
  const totalSteps = SIGNUP_STEPS.length - 2;

  const STEPS = SIGNUP_STEPS.slice(0, SIGNUP_STEPS.length - 2);

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(`/creator/onboarding/${SIGNUP_STEPS[step - 1]}`);
    }
  };

  return (
    <Form>
      <div className="creator-signup">
        <div
          style={{
            background: variant === "secondary" ? "#000" : "none",
            backgroundSize: "cover",
            backgroundPosition: "center bottom",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
          }}
        />
        <Stack direction="row" alignItems="center" sx={{ m: "10px 10px 40px" }}>
          {step > 0 && (
            <IconButton onClick={handleBack}>
              {isCompleted ? <BackArrowWhite /> : <BackArrow />}
            </IconButton>
          )}
          {isCompleted ? <BeepLogoCompleted /> : <BeepLogo />}

          <Box sx={{ flexGrow: 2 }} />
          {showProgress && (
            <>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  mr: 1,
                  fontFamily: "Lato",
                }}
              >
                Step {currentStep} of {totalSteps}
              </Typography>
              <div className="creator-signup-progress">
                {STEPS.map((step, index) => (
                  <div
                    key={index}
                    className={`creator-signup-step ${
                      index + 1 <= currentStep ? "completed" : ""
                    } ${index + 1 === currentStep ? "active" : ""}`}
                    onClick={() => {
                      if (index + 1 <= currentStep) {
                        navigate(`/creator/onboarding/${STEPS[index]}`);
                      }
                    }}
                  />
                ))}
              </div>
            </>
          )}
        </Stack>
        <div className="creator-signup-form">
          <Typography
            fontSize={"25px"}
            textAlign="center"
            fontFamily={"Archivo"}
            fontWeight="900"
          >
            {title}
          </Typography>
          <Typography
            fontFamily="Lato"
            fontSize="17px"
            textAlign="center"
            mt={1}
          >
            {subtitle}
          </Typography>
          <Box sx={{ mt: "30px" }}>{children}</Box>

          <Box
            sx={{
              mt: "50px",
              mb: "30px",
            }}
          >
            {showTerms && (
              <Typography color={"#717192"} fontSize="12px" fontFamily={"Lato"}>
                By clicking Continue, you agree to Beep’s{" "}
                <Link to="/terms" style={{ color: "#717192" }}>
                  Terms and Conditions
                </Link>{" "}
                and confirm you have read our{" "}
                <Link to="/privacy" style={{ color: "#717192" }}>
                  Privacy Notice
                </Link>
                . You may receive offers, news, and updates from us.
              </Typography>
            )}
            <Box sx={{ mb: isRequired ? 0 : "15px", mt: "20px" }}>
              <CustomButton
                type="button"
                onSubmit={onSubmit}
                disabled={disabled}
                style={{ height: isCompleted && "56px" }}
                endIcon={isCompleted && <ButtonArrow />}
              >
                {ctaText}
              </CustomButton>
            </Box>
            {!isRequired && (
              <CustomButton
                type="button"
                variant="secondary"
                onSubmit={() => {
                  onNext
                    ? onNext()
                    : navigate(`/creator/onboarding/${SIGNUP_STEPS[step + 1]}`);
                }}
              >
                Skip for now
              </CustomButton>
            )}
            {showLogin && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt="10px"
              >
                <Typography fontFamily={"Lato"} textAlign="center">
                  Already have an account?
                </Typography>
                <Button
                  to="/"
                  style={{
                    textDecoration: "underline",
                    textTransform: "none",
                    color: "#000",
                    fontSize: "16px",
                    fontFamily: "Lato",
                    fontWeight: 900,
                  }}
                  onClick={() =>
                    login({
                      appState: {
                        returnTo: "/dashboard",
                      },
                    })
                  }
                >
                  Log in
                </Button>
              </Stack>
            )}
          </Box>
        </div>
      </div>
    </Form>
  );
};

Template.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Template;
