import React, { useCallback } from "react";
import { withFormik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import Template from "../Template";
import { SIGNUP_STEPS } from "../../../../../lib/signup";
import {
  Autocomplete,
  TextField,
  createFilterOptions,
  InputLabel,
  Box,
} from "@mui/material";
import expertises from "./expertises.json";
import { ReactComponent as CloseIcon } from "../../../../../img/close.svg";

const validationSchema = Yup.object().shape({
  expertise: Yup.array().min(1, "Please select at least one expertise"),
});

export const expertiseLookup = expertises?.reduce((acc, expertise) => {
  acc[expertise?.title] = expertise?.emoji;
  return acc;
}, {});

const filter = createFilterOptions();

const ExpertiseForm = ({
  values,
  errors,
  setFieldValue,
  setSubmitting,
  handleSubmit,
  activeStep,
  isSubmitting,
}) => {
  const [value, setValue] = React.useState(values?.expertise || []);
  const [inputValue, setInputValue] = React.useState("");

  const handleRemoveOption = (optionToRemove) => {
    setValue(value.filter((option) => option !== optionToRemove));
  };

  const onFormSubmit = useCallback(async () => {
    if (isSubmitting) return;
    setSubmitting(true);

    await handleSubmit(values);
  }, [handleSubmit, setSubmitting, isSubmitting, values]);

  return (
    <Template
      title={"Almost done!"}
      subtitle={
        <>
          Add your areas of expertise. This will <br /> help clients see the
          topics you advise on <br /> in your calls.
        </>
      }
      step={activeStep}
      onSubmit={onFormSubmit}
      ctaText="Finish"
      disabled={value?.length < 1 || errors.expertise}
    >
      <InputLabel
        sx={{ fontSize: "12px", fontFamily: "Lato", my: 1, color: "#000" }}
      >
        Topics
      </InputLabel>
      <Autocomplete
        size="small"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          setFieldValue(
            "expertise",
            newValue.map((v) => v?.title)
          );
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // const { inputValue } = params;
          // Suggest the creation of a new value
          // const isExisting = options.some(
          //   (option) => inputValue === option.title
          // );
          // if (inputValue !== "" && !isExisting) {
          //   filtered.push({
          //     inputValue,
          //     title: `Add "${inputValue}"`,
          //   });
          // }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={expertises}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search 10k+ badges"
            sx={{
              boxShadow: "0px 3px 16px 0px rgba(0, 0, 0, 0.14)",
              borderRadius: "6px",
            }}
          />
        )}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option?.inputValue) {
            return option?.inputValue;
          }
          return option?.title || "";
        }}
        renderOption={(props, option) => (
          <li {...props}>
            {option?.emoji} {option?.title}
          </li>
        )}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        multiple
        classes={{
          input: "expertise-autocomplete",
          popper: "expertise-autocomplete-popper",
          option: "expertise-autocomplete-option",
          tag: "expertise-autocomplete-tag",
          endAdornment: "expertise-autocomplete-endAdornment",
        }}
      />
      <Box display="flex" flexWrap="wrap" mt="20px" gap={1}>
        {value?.map((option, index) => (
          <Box
            key={index}
            sx={{
              background: value?.length >= 0 ? "#3C4CE1" : "#F3F3F4",
              color: value?.length > 0 ? "#fff" : "#000",
              borderRadius: "50px",
              padding: "14px 16px",
              mb: "5px",
              fontSize: "14px",
              fontFamily: "Lato",
            }}
          >
            {expertiseLookup[option?.title || option]} {option?.title || option}
            <CloseIcon
              onClick={() => handleRemoveOption(option)}
              style={{ marginLeft: 6, cursor: "pointer" }}
            />
          </Box>
        ))}
      </Box>
    </Template>
  );
};

ExpertiseForm.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setSubmitting: PropTypes.func,
  error: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.func,
  loading: PropTypes.bool,
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => initialValues,
  validationSchema,
  handleSubmit: (values, { props: { onSubmit, activeStep } }) => {
    return onSubmit(
      values,
      `/creator/onboarding/${SIGNUP_STEPS[activeStep + 1]}`
    );
  },
  displayName: "ExpertiseForm",
})(ExpertiseForm);
