import React, { useCallback, useState } from "react";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { withFormik } from "formik";
import PropTypes from "prop-types";
import BackButton from "../../../../components/BackButton";
import beepLogo from "../../../../img/logo-with-text.svg";
import styles from "./IntegrationsForm.module.css";
import { TabPanel } from "../../Home";
import AvailabilityForm from "./AvailabilityForm";
import ConferencingForm from "./ConferencingForm";
import CustomButton from "../../../../components/CustomButton";

const IntegrationsForm = ({
  userId,
  values,
  handleSubmit,
  setFieldValue,
  onError,
  errors,
  touched,
  setFieldTouched,
  setFieldError,
  loading,
}) => {
  const [tab, setTab] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const onFormSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      if (isSubmitting) return;
      setSubmitting(true);

      await handleSubmit(values);
    },
    [handleSubmit, setSubmitting, values, isSubmitting]
  );

  return (
    <>
      <div className={styles.header}>
        <Stack direction="row">
          <BackButton />
          <img src={beepLogo} alt="logo" width={87} height={22} />
        </Stack>
        <Typography variant="body2" fontWeight={600}>
          Integrations
        </Typography>
      </div>
      <form onSubmit={onFormSubmit} className={styles.container}>
        <Typography variant="header1" fontSize={"22px"}>
          Integrations
        </Typography>
        <Box mt={2}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            centered
            variant="fullWidth"
            classes={{
              indicator: "tabs-indicator",
            }}
          >
            <Tab
              label={"Calendar"}
              sx={{
                fontSize: "15px",
                fontFamily: "Lato",
                color: "rgba(0, 0, 0, 0.60)",
                minWidth: "fit-content !important",
              }}
              classes={{ selected: "tabs-selected" }}
            />
            <Tab
              label={"Video"}
              sx={{
                fontSize: "15px",
                fontFamily: "Lato",
                color: "rgba(0, 0, 0, 0.60)",
                minWidth: "fit-content !important",
              }}
              classes={{ selected: "tabs-selected" }}
            />
          </Tabs>
        </Box>
        <TabPanel
          value={tab}
          index={0}
          style={{
            overflow: "auto",
            height: "100%",
            paddingBottom: "40px",
          }}
        >
          <AvailabilityForm values={values} />
        </TabPanel>
        <TabPanel
          value={tab}
          index={1}
          style={{
            overflow: "auto",
            height: "100%",
            paddingBottom: "40px",
          }}
        >
          <ConferencingForm values={values} />
        </TabPanel>
        <CustomButton
          type="submit"
          loading={isSubmitting}
          sx={{ mt: "30px !important" }}
        >
          Save
        </CustomButton>
      </form>
    </>
  );
};

IntegrationsForm.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setSubmitting: PropTypes.func,
  error: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.func,
  loading: PropTypes.bool,
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => initialValues,
  validationSchem: {},
  handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
    onSubmit(values);
    setSubmitting(false);
  },
  displayName: "IntegrationsForm",
})(IntegrationsForm);
