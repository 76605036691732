import React from 'react';
import { CircularProgress, IconButton } from '@mui/material';

export default function LoadingIconButton({ onClick, children, loading, sx, ...props }) {
  const handleClick = (evt) => {
    if (!loading) onClick(evt);
  }

  return (
    <IconButton variant='contained' onClick={handleClick} sx={{
      p: 2,
      ...sx
    }} {...props}>
      {loading ? <CircularProgress
        size={16}
        style={{ color: '#5F52EB' }}
      /> : children}
    </IconButton>
  )
}
