import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import TimeZoneIcon from "../icons/TimeZoneIcon";

const TIMEZONES = Intl.supportedValuesOf("timeZone");

export default function TimezoneSelect({ timezone, onChange }) {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <TimeZoneIcon />
      <Typography variant="body1" fontSize={14}>
        Time Zone:
      </Typography>
      <Autocomplete
        disablePortal
        options={TIMEZONES}
        renderInput={(params) => <TextField {...params} label="Timezone" />}
        sx={{ flexGrow: 1 }}
        size="small"
        value={timezone}
        onChange={onChange}
        classes={{
          input: "timeZone-autocomplete",
          option: "timeZone-autocomplete",
        }}
      />
    </Stack>
  );
}
