import React, { useContext, useState } from "react";
import { Skeleton, Typography } from "@mui/material";
import PropTypes from "prop-types";
// import { useNavigate } from "react-router-dom";
import "./index.css";
import ChatPreview from "./ChatPreview";
import { UserContext } from "../../context";
// import { ReactComponent as Link } from '../../img/link.svg';
// import { ReactComponent as WelcomeMsg } from '../../img/welcome-msg.svg';
import ShareLinkGuide from "../ShareLinkGuide";
import BottomDrawer from "../BottomDrawer";
// import CustomButton from "../CustomButton";
// import GuideStep from "./GuideStep";
// import useMutate from "../../hooks/useMutate";
// import useLocalStorage from "../../hooks/useLocalStorage";
import EmptyTabState from "../EmptyTabState";

const CreatorChatList = ({
  conversations,
  loading,
  refetchConversations,
  refetchBalance,
  onError,
  bankLocation,
  className,
  onGetMessagesClick,
}) => {
  const { user } = useContext(UserContext);
  const [shareOpen, setShareOpen] = useState(false);
  // const [welcomeMessageAck, setWelcomeMessageAck] = useLocalStorage('welcomeMessageAck', false);
  // const navigate = useNavigate();
  // const [updateCreator] = useMutate(`/creator/${ user?.creator?.id }`, { method: 'PUT' })

  // const handleAck = (type) => () => {
  //   if(type === 'shareLinkAck') setShareLinkAck(true);
  //   if(type === 'welcomeMessageAck') setWelcomeMessageAck(true);
  //   updateCreator({ [type]: true });
  // }

  return (
    <div className={`chat-list ${className}`}>
      {loading && (
        <>
          <Skeleton
            variant="rectangular"
            className="chat-preview-loading"
            height={91}
          />
          <Skeleton
            variant="rectangular"
            className="chat-preview-loading"
            height={91}
          />
          <Skeleton
            variant="rectangular"
            className="chat-preview-loading"
            height={91}
          />
          <Skeleton
            variant="rectangular"
            className="chat-preview-loading"
            height={91}
          />
        </>
      )}
      {!loading && (
        <>
          {(conversations.needsReply.length > 0 ||
            conversations.replied.length > 0) && (
            <Typography variant="body2" fontWeight={900} my={2}>
              Unread ({conversations.needsReply.length})
            </Typography>
          )}

          {conversations.needsReply.map((chat) => (
            <ChatPreview
              key={chat.chatId}
              chatId={chat.chatId}
              user={user}
              refetchConversations={refetchConversations}
              refetchBalance={refetchBalance}
              needsReply={true}
              recipient={chat.recipient}
              onError={onError}
              bankLocation={bankLocation}
              unrepliedCount={chat.unrepliedCount}
            />
          ))}
          {/* {conversations.needsReply.length === 0 &&
            conversations.replied.length > 0 && (
              // && (user.creator.acknowledgements?.welcomeMessage || welcomeMessageAck)
              <InboxEmpty
                alt="Nice job! You have responded to all of your messages."
                className="inbox-empty"
              />
            )} */}
          {conversations.needsReply.length === 0 &&
            conversations.replied.length === 0 && (
              <>
                <EmptyTabState
                  userInfo={user?.creator}
                  title=" No messages yet"
                />
              </>
            )}
          {/* { !user.creator.acknowledgements?.welcomeMessage && !welcomeMessageAck && (
              <GuideStep
                className='welcome-guide'
                title='Set an automatic welcome message'
                desc='Welcome new fans to your chat thread with a photo or vid, paired with a message.'
                btnText='Set my message'
                onClick={ () => navigate('/creator/welcome') }
                graphic={ <WelcomeMsg /> }
                onExit={ handleAck('welcomeMessageAck') }
              /> 
            )} */}
          <BottomDrawer isOpen={shareOpen} onClose={() => setShareOpen(false)}>
            <ShareLinkGuide />
          </BottomDrawer>
          {conversations.replied.length > 0 && (
            <>
              <Typography variant="body2" fontWeight={900} my={2}>
                All DMs
              </Typography>
              {conversations.replied.map((chat) => (
                <ChatPreview
                  key={chat.chatId}
                  chatId={chat.chatId}
                  user={user}
                  refetchConversations={refetchConversations}
                  recipient={chat.recipient}
                  needsReply={false}
                />
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

CreatorChatList.propTypes = {
  onError: PropTypes.func,
  conversations: PropTypes.shape({
    needsReply: PropTypes.arrayOf(
      PropTypes.shape({
        chatId: PropTypes.string,
        recipient: PropTypes.shape({
          id: PropTypes.string,
        }),
      })
    ),
    replied: PropTypes.arrayOf(
      PropTypes.shape({
        chatId: PropTypes.string,
        recipient: PropTypes.shape({
          id: PropTypes.string,
        }),
      })
    ),
  }),
  loading: PropTypes.bool,
  refetch: PropTypes.func,
};

export default CreatorChatList;
