import React, { useCallback, useContext, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { withFormik } from "formik";
import PropTypes from "prop-types";
import Template from "../../Template";
import { SIGNUP_STEPS } from "../../../../../../lib/signup";
import googleCalendar from "../../../../../../img/google-calendar.svg";
import CustomButton from "../../../../../../components/CustomButton";
import useLazyFetch from "../../../../../../hooks/useLazyFetch";
import { UserContext } from "../../../../../../context";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingIconButton from "../../../../../../components/LoadingIconButton";
import useMutate from "../../../../../../hooks/useMutate";
import SuccessIcon from "../../../../../../components/icons/SuccessIcon";
import DeleteIcon from "../../../../../../components/icons/DeleteIcon";
import useFetch from "../../../../../../hooks/useFetch";

const AvailabilityForm = ({
  values,
  setSubmitting,
  handleSubmit,
  activeStep,
  isSubmitting,
}) => {
  const { onError, user, refreshUser } = useContext(UserContext);
  const { data, loading } = useFetch("/user/connected-apps");
  const [connectCalendar, { loading: connecting }] = useLazyFetch(
    `/calendar/connect/google`
  );
  const [revokeToken, { loading: revoking }] = useMutate(`/token/revoke`, {
    method: "PUT",
  });
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const success = searchParams.get("success");
  const error = searchParams.get("error");

  useEffect(() => {
    if (success || error) {
      if (error) onError("Unable to connect calendar");
      searchParams.delete("success");
      searchParams.delete("error");
      setSearchParams(searchParams);
    }
  }, [success, error, onError, searchParams, setSearchParams]);

  const onFormSubmit = useCallback(async () => {
    if (isSubmitting) return;
    setSubmitting(true);

    await handleSubmit(values);
  }, [handleSubmit, setSubmitting, values, isSubmitting]);

  const handleConnectCalendar = useCallback(async () => {
    const { data, error } = await connectCalendar();
    if (error) return onError(error);
    if (data && data.url) {
      window.location.replace(data.url);
    }
  }, [connectCalendar, onError]);

  const handleRemoveCalendar = useCallback(async () => {
    await revokeToken({
      type: "GOOGLE_CALENDAR",
      userId: user?.creator?.id,
    }).then(async (response) => {
      if (response?.error) {
        onError(response?.error || "Something went wrong. Please try again.");
        return;
      }
    });
    refreshUser();
  }, [onError, revokeToken, refreshUser, user?.creator?.id]);

  const googleConnection = data?.connections?.find(
    (app) => app.type === "GOOGLE_CALENDAR"
  );

  return (
    <Template
      title="Connect your calendar"
      subtitle="Connect your calendar to set availability so your audience can pay to book time with you."
      step={activeStep}
      onSubmit={onFormSubmit}
      isRequired={false}
    >
      {!loading && (
        <Stack gap={2} mb="20px">
          <Stack
            direction="row"
            justifyContent={"space-between"}
            gap={1}
            sx={{
              borderRadius: "8px",
              border: "1px solid #D9D9D9",
              p: "12px",
            }}
          >
            <Stack
              direction="row"
              gap={1}
              alignItems={googleConnection ? "start" : "center"}
            >
              <img
                src={googleCalendar}
                alt="Google Calendar"
                width={28}
                height={28}
              />
              <Box>
                <Typography variant="body1">Google Calendar</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#717192",
                    fontSize: "12px",
                  }}
                >
                  {googleConnection
                    ? googleConnection?.email
                    : "Gmail or Google Workspace"}
                </Typography>
                {googleConnection && (
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    gap={0.5}
                    mt="3px"
                  >
                    <SuccessIcon />
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#717192",
                        fontSize: "12px",
                        fontWeight: 900,
                      }}
                    >
                      Connected
                    </Typography>
                  </Stack>
                )}
              </Box>
            </Stack>
            {googleConnection ? (
              <LoadingIconButton
                sx={{ height: "fit-content", mt: "-12px", mr: "-5px" }}
                onClick={handleRemoveCalendar}
                loading={revoking}
              >
                <DeleteIcon />
              </LoadingIconButton>
            ) : (
              <CustomButton
                sx={{
                  width: "83px",
                  minHeight: "30px !important",
                  height: "30px !important",
                  fontSize: "15px",
                }}
                onSubmit={handleConnectCalendar}
                loading={connecting}
              >
                Connect
              </CustomButton>
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            gap={1}
            sx={{
              borderRadius: "8px",
              border: "1px solid #D9D9D9",
              p: "12px",
            }}
          >
            <Box>
              <Typography variant="body1" fontSize="16px">
                Enter Availability Manually
              </Typography>
              <Typography color="#717192" fontFamily="Lato" fontSize="12px">
                Select Days and Times that you are blocking off for Paid Calls.
              </Typography>
            </Box>
            <CustomButton
              sx={{
                width: "53px",
                minHeight: "30px !important",
                height: "30px !important",
                fontSize: "15px",
              }}
              onClick={() => navigate("/creator/onboarding/schedule")}
            >
              Add
            </CustomButton>
          </Stack>
        </Stack>
      )}
    </Template>
  );
};

AvailabilityForm.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setSubmitting: PropTypes.func,
  error: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.func,
  loading: PropTypes.bool,
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => initialValues,
  validationSchem: {},
  handleSubmit: (values, { props: { onSubmit, activeStep } }) => {
    return onSubmit(
      null,
      `/creator/onboarding/${SIGNUP_STEPS[activeStep + 1]}`
    );
  },
  displayName: "AvailabilityForm",
})(AvailabilityForm);
