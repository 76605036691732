import React, { useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Holidays from "date-holidays";
import IOSSwitch from "../../../../components/IOSSwitch";

export default function HolidaysTab({ countries, values, setFieldValue }) {
  const [creatorWorkingHolidays, setCreatorWorkingHolidays] = useState(
    values?.workingHolidays || []
  );
  const [selectedCountry, setSelectedCountry] = useState("US");

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const currentDate = new Date();
  let hd = new Holidays(selectedCountry);

  const upComingHolidays = hd
    ?.getHolidays(new Date().getFullYear())
    ?.filter((holiday) => {
      const holidayDate = new Date(holiday.date);
      return holidayDate >= currentDate;
    });

  const handleSwitchChange = (holiday, newValue) => {
    const updatedHolidays = newValue
      ? creatorWorkingHolidays?.filter((h) => h?.holidayName !== holiday?.name)
      : [
          ...creatorWorkingHolidays,
          { holidayName: holiday?.name, date: holiday?.date },
        ];
    setCreatorWorkingHolidays(updatedHolidays);
    setFieldValue("workingHolidays", updatedHolidays);
  };

  return (
    <Box>
      <Typography variant="body2" color={"#717192"} my={2}>
        Beep automatically removes certain holidays from your availability based
        on your country, which you can edit here.
      </Typography>
      <Stack>
        <FormControl>
          <Typography variant="body2" fontWeight={600}>
            Country used for holidays
          </Typography>
          <Select
            value={selectedCountry}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              height: 34,
              width: "100%",
              fontSize: "16px",
              fontFamily: "Lato",
            }}
            onChange={handleCountryChange}
          >
            {countries?.map((country, index) => (
              <MenuItem key={index} value={country?.countryCode}>
                {country?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Typography variant="body2" fontWeight={600} mt={3} mb={1}>
        Holidays observed
      </Typography>
      <Box height={250} overflow="auto">
        {upComingHolidays.length > 0 ? (
          upComingHolidays
            ?.filter((r) => r.type === "public")
            ?.map((holiday, index) => (
              <Stack
                key={index}
                direction="column"
                border="1px solid rgba(113, 113, 146, 0.30)"
                p={1}
                sx={{
                  borderTopLeftRadius: index === 0 ? "4px" : "0px",
                  borderTopRightRadius: index === 0 ? "4px" : "0px",
                  borderBottomLeftRadius:
                    index === holiday.length - 1 ? "4px" : "0",
                  borderBottomRightRadius:
                    index === holiday.length - 1 ? "4px" : "0",
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems={"start"}
                >
                  <Box>
                    <Typography variant="body1">{holiday?.name}</Typography>
                    <Typography variant="body1">
                      {" "}
                      {new Date(holiday?.date)?.toLocaleDateString(undefined, {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </Typography>
                  </Box>

                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={
                      !creatorWorkingHolidays.some(
                        (h) => h?.holidayName === holiday?.name
                      )
                    }
                    onChange={(event, newValue) => {
                      handleSwitchChange(holiday, newValue);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Stack>
              </Stack>
            ))
        ) : (
          <Typography textAlign={"center"} mt={5}>
            No Holidays available
          </Typography>
        )}
      </Box>
    </Box>
  );
}
