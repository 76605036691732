import { Box, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import CustomButton from "../../../../components/CustomButton";
import TimezoneSelect from "../../../../components/TimezoneSelect";
import { ReactComponent as Arrow } from "../../../../img/date-arrow.svg";

export default function VideoTime({
  timeSlots,
  selectedDate,
  handleBack,
  timeZone,
  setTimeZone,
  selectedTime,
  setSelectedTime,
}) {
  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  return (
    <Stack alignItems={"center"} mb={2} px={1}>
      <Stack width={"100%"} gap={3} borderBottom="1px solid #717192" pb={1.5}>
        <Stack
          direction={"row"}
          sx={{
            width: "100%",
            borderRadius: "16px",
            background: "#f2f2f2",
            py: 2,
            px: 1,
            boxSizing: "border-box",
          }}
          gap={1}
        >
          <Arrow
            onClick={handleBack}
            style={{ position: "absolute", cursor: "pointer" }}
          />
          <Box flexGrow={1} textAlign="center">
            <Typography variant="header1" fontSize={"20px"}>
              {format(selectedDate, "EEEE")}
            </Typography>
            <Typography>{format(selectedDate, "MMMM d, yyyy")}</Typography>
          </Box>
        </Stack>
        <Box width={"80%"}>
          <TimezoneSelect
            timezone={timeZone}
            onChange={(event, newValue) => {
              setTimeZone(newValue);
            }}
          />
        </Box>
      </Stack>

      <Box my={3} textAlign="center">
        <Typography variant="header1" fontSize={"20px"}>
          Select a Time
        </Typography>
        <Typography variant="body2" color={"#717192"}>
          Duration: 30 min
        </Typography>
      </Box>

      <Stack width={"100%"} gap={1.5}>
        {timeSlots ? (
          timeSlots?.map((time) => {
            const slotTime = new Date(time);
            const isAM = slotTime.getHours() < 12 ? "am" : "pm";
            const formattedTime = `${slotTime.getHours() % 12 || 12}:${slotTime
              .getMinutes()
              .toString()
              .padStart(2, "0")} ${isAM}`;
            const isSelected = selectedTime === time;
            return (
              <CustomButton
                key={formattedTime}
                variant="secondary"
                sx={{
                  background: isSelected ? "#000 !important" : "",
                  color: isSelected ? "#fff !important" : "",
                }}
                onClick={() => handleTimeSelect(time)}
              >
                {formattedTime}
              </CustomButton>
            );
          })
        ) : (
          <Typography variant="body1" textAlign={"center"}>
            No time slots for selected date
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
