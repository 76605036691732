import { Box, Stack, Typography } from "@mui/material";
import { formatISO } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import BottomDrawer from "../../../../components/BottomDrawer";
import CustomButton from "../../../../components/CustomButton";
import useFetch from "../../../../hooks/useFetch";
import { ReactComponent as Exit } from "../../../../img/exit.svg";
import BookingDate from "./BookingDate";
import BookingDetails from "./BookingDetails";
import BookingTime from "./BookingTime";
import { Elements } from "@stripe/react-stripe-js";
import { UserContext } from "../../../../context";
import ConfirmBooking from "./ConfirmBooking";

export default function VideoBooking({
  creator,
  isOpen,
  onClose,
  dateValue,
  setDateValue,
  startDate,
  endDate,
}) {
  const { stripePromise, user, login } = useContext(UserContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [order, setOrder] = useState();
  const [timeZone, setTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [selectedTime, setSelectedTime] = useState(null);
  const topRef = useRef(null);
  const { data } = useFetch(
    `/creator/${creator?.id}/availability?start=${startDate}&end=${endDate}`,
    { auth: false }
  );

  const options = {
    mode: "payment",
    amount: order?.total / 100 || creator?.videoCallHourlyRate / 2,
    currency: "usd",
  };

  const handleClose = () => {
    setCurrentStep(1);
    setDateValue(new Date());
    setSelectedTime(null);
    setOrder(null);
    setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    onClose();
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = () => {
    handleNext();
  };

  const startTime = new Date(selectedTime);
  const endTime = new Date(startTime.getTime());
  endTime.setMinutes(endTime.getMinutes() + 30);
  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const isAM = hours < 12 ? "am" : "pm";
    const hour12 = hours % 12 || 12;
    return `${hour12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${isAM}`;
  };

  const startTimeFormatted = formatTime(startTime);
  const endTimeFormatted = formatTime(endTime);

  const availableDays = data?.slots.reduce((groups, timeSlot) => {
    const date = new Date(timeSlot)?.toISOString()?.split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date]?.push(timeSlot);

    return groups;
  }, {});

  const filterTimeSlotsForDay = (availableDays, specificDay) => {
    const timeSlotsForDay =
      availableDays &&
      availableDays[specificDay]?.filter((timeSlot) => {
        const slotDate = new Date(timeSlot)?.toISOString()?.split("T")[0];
        return slotDate === specificDay;
      });
    return timeSlotsForDay;
  };

  const timeSlotsForSpecificDay = filterTimeSlotsForDay(
    availableDays,
    formatISO(dateValue, { representation: "date" })
  );

  const buttonDisabled =
    (currentStep === 2 && !timeSlotsForSpecificDay) ||
    (currentStep === 2 && !selectedTime);

  let content;
  switch (currentStep) {
    case 1:
      content = (
        <BookingDate
          availableDays={availableDays}
          dateValue={dateValue}
          setDateValue={setDateValue}
          timeZone={timeZone}
          setTimeZone={setTimeZone}
        />
      );

      break;
    case 2:
      content = (
        <BookingTime
          timeSlots={timeSlotsForSpecificDay}
          selectedDate={dateValue}
          handleBack={handleBack}
          timeZone={timeZone}
          setTimeZone={setTimeZone}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
        />
      );
      break;
    case 3:
      content = (
        <Elements
          stripe={stripePromise}
          options={order ? { clientSecret: order.clientSecret } : options}
        >
          <BookingDetails
            creator={creator}
            user={user}
            order={order}
            setOrder={setOrder}
            selectedTime={selectedTime}
            selectedDate={dateValue}
            timeZone={timeZone}
            startTimeFormatted={startTimeFormatted}
            endTimeFormatted={endTimeFormatted}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </Elements>
      );
      break;
    case 4:
      content = (
        <ConfirmBooking
          creator={creator}
          order={order}
          login={login}
          timeZone={timeZone}
          startTimeFormatted={startTimeFormatted}
          endTimeFormatted={endTimeFormatted}
          selectedDate={dateValue}
        />
      );
      break;
    default:
      content = <></>;
  }

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView();
    }
  }, [currentStep]);

  return (
    <BottomDrawer isOpen={isOpen} canExit={false} showHandle centered>
      <div ref={topRef} />
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        mt={1}
        p={"25px"}
      >
        <Typography variant="body2" flexGrow={1} textAlign={"center"} ml={3}>
          Book a Video Call
        </Typography>
        <Exit
          style={{
            width: "24px",
            height: "24px",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
      </Stack>
      <Box p={"10px"}>{content}</Box>
      <Stack alignItems={"center"} gap={2} px={1.5}>
        {currentStep <= 2 && (
          <CustomButton
            onClick={handleSubmit}
            disabled={buttonDisabled}
            sx={{ width: "95%", background: "#000 !important" }}
          >
            Next
          </CustomButton>
        )}
        {currentStep !== 4 && (
          <Typography
            variant="body2"
            sx={{
              color: "#717192",
              textDecoration: "underline",
              fontSize: "12px",
              textAlign: "center",
              mb: 3,
            }}
          >
            Start earning with Beep
          </Typography>
        )}
      </Stack>
    </BottomDrawer>
  );
}
