import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../Home.module.css";
import Chat from "../../../components/Chat";
import { UserContext } from "../../../context";
import { getCreatorByInsta } from "../../../lib/api";
import SignupModal from "../../../components/SignupModal";
import { Alert, Snackbar } from "@mui/material";
import useMutate from "../../../hooks/useMutate";
import { useFanAnalytics } from "../../../hooks/useAnalytics";
import Div100vh from "../../../components/Div100vh";
import WelcomeModal from "../WelcomeModal";

const Home = () => {
  const {
    user,
    chatClient,
    setupChatClient,
    isAuthenticating,
    // isAuthenticated,
  } = useContext(UserContext);
  const [chatId, setChatId] = useState();
  const [creator, setCreator] = useState();
  const [signupOpen, setSignupOpen] = useState(false);
  const [experience, setExperience] = useState("signup");
  const [welcomeOpen, setWelcomeOpen] = useState(!user && !isAuthenticating);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(user);
  const [error, setError] = useState();
  const [invalidCreator, setInvalidCreator] = useState(false);
  const { creatorInsta } = useParams();
  const [createChat, { loading: createChatLoading, error: createChatError }] =
    useMutate("/chat/create");
  const sendFanEvent = useFanAnalytics();
  const navigate = useNavigate();

  useEffect(() => {
    if (!creator) return;
    if (user && !chatClient) setupChatClient(creator?.id);
    if (user) setShowWelcomeMessage(true);
  }, [setupChatClient, creator, user, chatClient]);

  //   useEffect(() => {
  //     if (isAuthenticated) {
  //       setWelcomeOpen(false);
  //     } else if (!isAuthenticating) {
  //       setWelcomeOpen(true);
  //     }
  //   }, [isAuthenticated, isAuthenticating]);

  useEffect(() => {
    if (creator || invalidCreator) return;

    const fetchCreator = async () => {
      const creator = await getCreatorByInsta(creatorInsta);
      if (!creator) {
        setError("Creator has not signed up yet");
        setInvalidCreator(true);
        return;
      }
      sendFanEvent({ action: "chat loaded", creator: creatorInsta });
      setCreator(creator);
    };

    fetchCreator();
  }, [creatorInsta, sendFanEvent, creator, invalidCreator]);

  useEffect(() => {
    if (creator && user && creator.id === user.creator?.id) {
      navigate("/creator/home");
    }
  }, [creator, user, navigate]);

  useEffect(() => {
    const fetchChat = async () => {
      if (
        user &&
        creator &&
        chatClient &&
        !chatId &&
        !createChatError &&
        !createChatLoading
      ) {
        const { error, data } = await createChat({
          creatorId: creator.id,
          fanId: user.id,
        });
        if (error) return;
        setChatId(data?.chatId);
      }
    };

    fetchChat();
  }, [
    chatClient,
    chatId,
    createChat,
    creator,
    user,
    createChatLoading,
    createChatError,
  ]);

  const handleAnonymousSend = () => {
    sendFanEvent({ action: "send first message" });
    // login({
    //   prompt: 'login',
    //   screen_hint: 'signup',
    //   appState: {
    //     returnTo: window.location.pathname + window.location.search,
    //   }
    // });
    setSignupOpen(true);
  };

  const handleLogin = () => {
    setExperience("Login");
    setSignupOpen(true);
  };

  return (
    <Div100vh className={styles.home}>
      <Snackbar
        open={!!error}
        autoHideDuration={3000}
        onClose={() => setError()}
      >
        <Alert
          className="error-alert"
          onClose={() => setError()}
          severity="error"
          variant="filled"
        >
          {error}
        </Alert>
      </Snackbar>
      {creator && (
        <Chat
          recipient={creator}
          isCreatorView={false}
          chatId={chatId}
          onAnonymousSend={handleAnonymousSend}
          handleFanLogin={handleLogin}
          onError={setError}
          backButton={user}
          onBack={() => navigate(`/${creatorInsta}`)}
          showWelcomeMessage={showWelcomeMessage}
        />
      )}
      {welcomeOpen && (
        <WelcomeModal
          isOpen={welcomeOpen}
          onClose={() => {
            setWelcomeOpen(false);
            setShowWelcomeMessage(true);
          }}
        />
      )}
      {signupOpen && (
        <SignupModal
          isOpen={signupOpen}
          onClose={() => setSignupOpen(false)}
          onError={setError}
          experience={experience}
          setExperience={setExperience}
        />
      )}
    </Div100vh>
  );
};

export default Home;
