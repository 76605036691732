import React, { useCallback, useState } from "react";
import { withFormik } from "formik";
import PropTypes from "prop-types";
import Template from "../../Template";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TimezoneSelect from "../../../../../../components/TimezoneSelect";
import { SIGNUP_STEPS } from "../../../../../../lib/signup";

function generateTimeSlots(startHour = 6, endHour = 20, interval = 30) {
  const timeSlots = [];
  const locale = navigator.language; // Get the user's computer locale

  const startTime = new Date();
  startTime.setHours(startHour, 0, 0, 0);

  for (let i = 0; i < (endHour - startHour) * (60 / interval); i++) {
    const formattedTime = new Intl.DateTimeFormat(locale, {
      hour: "numeric",
      minute: "numeric",
    }).format(startTime);

    timeSlots.push({
      key: `${startTime.getHours()}:${
        startTime.getMinutes() === 0 ? "00" : startTime.getMinutes()
      }`,
      value: formattedTime,
    });
    startTime.setTime(startTime.getTime() + interval * 60 * 1000); // Add 30 minutes
  }

  return timeSlots;
}

const INITIAL_VALUES = {
  schedule: {
    Friday: { enabled: false },
    Monday: { enabled: false },
    Saturday: { enabled: false },
    Sunday: { enabled: false },
    Thursday: { enabled: false },
    Tuesday: { enabled: false },
    Wednesday: { enabled: false },
  },
};

const DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const ScheduleForm = ({
  values,
  setSubmitting,
  handleSubmit,
  activeStep,
  isSubmitting,
  setFieldValue,
}) => {
  const [startTime, setStartTime] = useState("6:00");
  const [endTime, setEndTime] = useState("6:30");
  const navigate = useNavigate();
  const onFormSubmit = useCallback(async () => {
    if (isSubmitting) return;
    setSubmitting(true);

    await handleSubmit(values);
  }, [handleSubmit, setSubmitting, values, isSubmitting]);

  const handleSelectTime = useCallback(
    (day) => {
      const schedule = INITIAL_VALUES.schedule;
      if (!schedule[day]) {
        schedule[day] = { enabled: false, start: null, end: null };
      }
      schedule[day].enabled = !schedule[day].enabled;
      if (schedule[day].enabled) {
        schedule[day].start = startTime;
        schedule[day].end = endTime;
      } else {
        delete schedule[day].start;
        delete schedule[day].end;
      }

      setFieldValue("schedule", schedule);
    },
    [setFieldValue, startTime, endTime]
  );

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };

  return (
    <Template
      title="Set your availability"
      subtitle="Let Beep know when you’re typically available to accept meetings."
      step={activeStep}
      onSubmit={onFormSubmit}
      isRequired={false}
      onNext={() => navigate(`/creator/onboarding/availability`)}
      onBack={() => navigate(`/creator/onboarding/availability`)}
    >
      <TimezoneSelect
        timezone={
          values.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        onChange={(event, newValue) => {
          setFieldValue("timezone", newValue);
        }}
      />
      <Divider sx={{ my: "25px" }} />

      <Box>
        <Typography fontFamily="Lato" fontSize="14px" mb={1}>
          Available hours
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <FormControl>
            <Select
              value={startTime}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: 34,
                width: 133,
                fontSize: "14px",
                fontFamily: "Lato",
              }}
              onChange={handleStartTimeChange}
            >
              {generateTimeSlots().map((timeslot, index) => {
                return (
                  <MenuItem key={index} value={timeslot?.key}>
                    {timeslot?.value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Typography fontFamily="Lato" fontSize="14px">
            to
          </Typography>
          <FormControl>
            <Select
              value={endTime}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: 34,
                width: 133,
                fontSize: "14px",
                fontFamily: "Lato",
              }}
              onChange={handleEndTimeChange}
            >
              {generateTimeSlots().map((timeslot, index) => {
                return (
                  <MenuItem key={index} value={timeslot?.key}>
                    {timeslot?.value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
      </Box>

      <Box mt={2}>
        <Typography fontFamily="Lato" fontSize="14px" mb={1}>
          Available days
        </Typography>
        <>
          {DAYS.map((day, index) => (
            <Stack
              key={index}
              direction="column"
              border="1px solid rgba(113, 113, 146, 0.30)"
              sx={{
                borderTopLeftRadius: index === 0 ? "4px" : "0px",
                borderTopRightRadius: index === 0 ? "4px" : "0px",
                borderBottomLeftRadius: index === DAYS.length - 1 ? "4px" : "0",
                borderBottomRightRadius:
                  index === DAYS.length - 1 ? "4px" : "0",
              }}
            >
              <FormControlLabel
                value="end"
                label={day}
                labelPlacement="end"
                sx={{
                  color: "#000",
                  marginLeft: "-3px",
                  gap: "5px",
                  p: 1,
                }}
                classes={{
                  label: "service-form-label ",
                }}
                control={
                  <Checkbox
                    sx={{
                      color: "#000",
                      "&.Mui-checked": {
                        color: "#000",
                      },
                    }}
                    onChange={() => handleSelectTime(day)}
                  />
                }
              />
            </Stack>
          ))}
        </>
      </Box>
      <Divider sx={{ my: "25px" }} />
      <Typography variant="body1" mb="-40px" fontSize="14px" textAlign="center">
        Don’t worry! You can further customize your availability later on.
      </Typography>
    </Template>
  );
};

ScheduleForm.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setSubmitting: PropTypes.func,
  error: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.func,
  loading: PropTypes.bool,
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => initialValues,
  validationSchem: {},
  handleSubmit: (values, { props: { onSubmit, activeStep } }) => {
    return onSubmit(
      values,
      `/creator/onboarding/${SIGNUP_STEPS[activeStep + 1]}`
    );
  },
  displayName: "ScheduleForm",
})(ScheduleForm);
