import { Box, Button, InputLabel, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import BottomDrawer from "../BottomDrawer";
import CustomFormInput from "../CustomFormInput";
import { ReactComponent as Bin } from "../../img/bin.svg";
import { ReactComponent as Plus } from "../../img/plus.svg";
import CustomButton from "../CustomButton";

export default function UpdateReviews({
  value = [{ id: 1, content: "" }],
  setFieldValue,
  open,
  setOpen,
  onSubmit,
}) {
  const [reviews, setReviews] = useState(value);
  const [wordCounts, setWordCounts] = useState(() => {
    return value.reduce((acc, review) => {
      acc[review.id] = review.content.split(/\s+/).filter(Boolean).length;
      return acc;
    }, {});
  });
  const handleClose = () => {
    setOpen(false);
    setReviews(value);
  };

  const addReview = () => {
    const newReview = { id: reviews.length + 1, content: "" };
    setReviews([...reviews, newReview]);
  };

  const deleteReview = (id) => {
    setReviews(reviews.filter((review) => review.id !== id));
  };

  const updateReview = (id, content) => {
    const words = content.split(/\s+/).filter(Boolean);
    const wordCount = words.length;
    if (wordCount <= 10 && (!/\s/.test(content.slice(-1)) || wordCount < 10)) {
      setReviews(
        reviews.map((review) =>
          review.id === id ? { ...review, content } : review
        )
      );
      setWordCounts({ ...wordCounts, [id]: wordCount });
    }
  };

  const handleSave = (event) => {
    setFieldValue("reviews", reviews);
    setOpen(false);
    onSubmit(event);
  };

  return (
    <BottomDrawer
      isOpen={open}
      onClose={() => handleClose()}
      header="Your Reviews"
      showHandle
      canExit={false}
      showBack
    >
      <Stack mt={3}>
        {reviews?.length > 0 ? (
          reviews?.map((review) => (
            <Box key={review.id} mb="12px">
              <Stack className="stackLayout">
                <InputLabel
                  sx={{ fontSize: "14px", color: "#000" }}
                >{`Review ${review.id}`}</InputLabel>
                <Bin onClick={() => deleteReview(review.id)} cursor="pointer" />
              </Stack>
              <CustomFormInput
                name={`review-${review.id}`}
                placeholder="Enter a client review"
                inputClassName="update-social-input"
                value={review.content}
                onChange={(e) => updateReview(review.id, e.target.value)}
              />
              <Typography
                variant="body2"
                fontSize={"12px"}
                color="rgba(133, 133, 163, 0.80)"
                sx={{ float: "right" }}
              >
                {wordCounts[review.id] || 0}/200 words
              </Typography>
            </Box>
          ))
        ) : (
          <Typography textAlign={"center"}>No Reviews added</Typography>
        )}
        <Button className="addButton" startIcon={<Plus />} onClick={addReview}>
          Add more
        </Button>
      </Stack>
      <Stack direction={"row"} gap={2} mt={4}>
        <CustomButton variant="secondary" onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton onClick={handleSave}>Save</CustomButton>
      </Stack>
    </BottomDrawer>
  );
}
