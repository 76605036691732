import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Check } from "../../../../img/confirm-check.svg";
import { ReactComponent as Calendar } from "../../../../img/calendar.svg";
import { format } from "date-fns";
import TimeZoneIcon from "../../../../components/icons/TimeZoneIcon";
import CustomButton from "../../../../components/CustomButton";

export default function ConfirmBooking({
  creator,
  order,
  startTimeFormatted,
  endTimeFormatted,
  selectedDate,
  timeZone,
  login,
}) {
  const signup = () => {
    login({
      appState: {
        returnTo: `/creator/onboarding`,
      },
      prompt: "login",
      screen_hint: "signup",
    });
  };

  return (
    <Stack px={1} mb={2}>
      <Stack
        direction={"row"}
        alignItems="center"
        sx={{
          width: "100%",
          borderRadius: "16px",
          background: "#f2f2f2",
          py: 2,
          px: 1,
          mb: 2,
          boxSizing: "border-box",
        }}
        gap={1}
      >
        <Box flexGrow={1} textAlign="center">
          <Typography variant="header1" fontSize={"20px"}>
            Confirmed
          </Typography>
          <Typography variant="body2">
            You are scheduled with {creator?.firstName} {creator?.lastName}
          </Typography>
        </Box>
      </Stack>
      <Stack gap={1.5} mb={3} mt={1} borderBottom="1px solid #717192" pb={1.5}>
        <Stack direction={"row"} alignItems="center" gap={1}>
          <Check />
          <Typography variant="body2">30 Minute Meeting</Typography>
        </Stack>
        <Stack direction={"row"} alignItems="start" gap={1}>
          <Calendar />
          <Typography variant="body2" lineHeight={"16px"}>
            {startTimeFormatted} – {endTimeFormatted} <br />
            {format(selectedDate, "EEEE, MMMM d, yyyy")}
          </Typography>
        </Stack>
        <Stack direction={"row"} alignItems="center" gap={1}>
          <TimeZoneIcon />
          <Typography variant="body2">{timeZone}</Typography>
        </Stack>
      </Stack>
      <Box borderBottom="1px solid #717192" my={2} pb={3}>
        <Stack className="stackLayout" mt={-2}>
          <Typography variant="body2" fontWeight={700}>
            Amount Paid
          </Typography>
          <Typography variant="body2" color={"#717192"}>
            {" "}
            ${order?.total / 100 || creator?.videoCallHourlyRate / 2} USD
          </Typography>
        </Stack>
        <Typography variant="body2" fontWeight={700} textAlign="center" mt={2}>
          A calendar invitation and payment receipt have been <br /> sent to
          your email address.
        </Typography>
      </Box>
      <Typography
        variant="body2"
        fontWeight={700}
        textAlign="center"
        mt={2}
        mb={3}
      >
        Ready to monetize your expertise with Beep?
      </Typography>
      <CustomButton onClick={signup} sx={{ background: "#000! important" }}>
        Sign Up for Free
      </CustomButton>
    </Stack>
  );
}
