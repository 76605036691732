import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const { useContext, useEffect } = require("react");
const { UserContext } = require("../../context");

const AuthCallback = () => {
  const { auth, onError } = useContext(UserContext);
  const {
    getAccessTokenSilently,
    getAccessTokenWithPopup
  } = useAuth0();
  const navigate = useNavigate();

  const handleRedirect = async (err) => {
    try {
      const nonce = localStorage.getItem('nonce');
      const appStateStr = localStorage.getItem('appState');
      const appState = JSON.parse(appStateStr);
      const returnTo = appState?.[nonce]?.returnTo;
      console.log(appState, returnTo);

      if (err) {
        onError(err.message);
        if (returnTo) navigate(returnTo);
        return navigate('/');
      }
      getAccessTokenSilently()
        .then(() => {
          navigate(returnTo);
        })
        .catch((err) => {
          console.error(err);
          getAccessTokenWithPopup()
            .then(() => {
              navigate(returnTo);
            }).catch((err) => {
              console.error(err);
            });
        });
    } catch (err) {
      console.error(err)
      onError('Invalid redirect');
      return navigate('/');
    }
  }

  useEffect(() => {
    const nonce = localStorage.getItem('nonce');
    if (!nonce) return navigate('/');
    auth.parseHash({
      hash: window.location.hash,
      state: nonce
    }, handleRedirect)
      .catch(err => onError(err));
  })

  return null;
}

export default AuthCallback;