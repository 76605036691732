import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Alert,
  Badge,
  Box,
  Button,
  Divider,
  Skeleton,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CreatorChatList from "../../../components/CreatorChatList";
import VideoCallList from "../../../components/CreatorVideoCallList";
import CreatorHeader from "./CreatorHeader";
import "./index.css";
import { UserContext } from "../../../context";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { useCreatorAnalytics } from "../../../hooks/useAnalytics";
import useLazyFetch from "../../../hooks/useLazyFetch";
import ShareLink, { truncate } from "../../../components/ShareLink";
// import GetMessages from "./GetMessages";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const CreatorHome = () => {
  const { user, chatClient, refreshUser, setupChatClient, loading } =
    useContext(UserContext);
  const [error, setError] = useState();
  const [tab, setTab] = useState(0);
  const [openShare, setOpenShare] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    data: conversationData,
    loading: conversationsLoading,
    refetch: refetchConversations,
  } = useFetch(`/creator/${user?.creator.id}/conversations`);

  const { data: balanceData, refetch: refetchBalance } = useFetch(
    `/creator/${user?.creator.id}/balance`
  );
  const {
    data: videoCallData,
    loading: videoCallsLoading,
    refetch: refetchVideoCalls,
  } = useFetch(`/creator/${user?.creator.id}/bookings`);

  const [getLink, { loading: linkLoading }] = useLazyFetch(
    `/stripe/link/login?redirectUrl=${window.location.origin}`
  );
  const sendCreatorEvent = useCreatorAnalytics();
  const conversations = (conversationData?.conversations || []).reduce(
    (acc, chat) => {
      if (chat.hasReplied) {
        return { ...acc, replied: [chat, ...acc.replied] };
      } else {
        return { ...acc, needsReply: [...acc.needsReply, chat] };
      }
    },
    {
      needsReply: [],
      replied: [],
    }
  );

  function splitMeetingsByStatus(videoCallData) {
    const isPast = (dateString) => {
      const now = new Date();
      return new Date(dateString) < now;
    };
    let upcomingMeetings = [];
    let completedMeetings = [];
    videoCallData?.forEach((meeting) => {
      if (isPast(meeting.endDate)) {
        completedMeetings.push(meeting);
      } else {
        upcomingMeetings.push(meeting);
      }
    });
    return { upcomingMeetings, completedMeetings };
  }
  const videoCalls = splitMeetingsByStatus(videoCallData?.bookings);

  const creatorLink = `${window.location.host}/${user.creator.handle}`;
  const coinDestinationRef = useRef();

  // useEffect(() => {
  //   if(!conversationsLoading && conversationData?.conversations?.length === 0) {
  //     setTab(1)
  //   }
  // }, [conversationsLoading, conversationData])
  useEffect(() => {
    if (user?.creator?.id) setupChatClient(user?.creator?.id);
  }, [setupChatClient, user?.creator?.id]);
  useEffect(() => {
    if (user && !user?.creator?.isStripeSetup) navigate("/creator/onboarding");
  }, [navigate, user]);

  useEffect(() => {
    if (searchParams.get("refreshUser")) {
      sendCreatorEvent({ action: "stripe connect success" });
      refreshUser().then(() => {
        navigate(window.location.pathname);
      });
    }
  }, [navigate, refreshUser, searchParams, sendCreatorEvent]);

  const handleStripeLogin = async () => {
    if (linkLoading) return;
    const { data } = await getLink();
    if (data?.url) window.location = data.url;
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className="home">
      <Snackbar open={!!error} autoHideDuration={3000} onClose={setError}>
        <Alert
          className="error-alert"
          onClose={setError}
          severity="error"
          variant="filled"
        >
          {error}
        </Alert>
      </Snackbar>
      <Stack sx={{ height: 1, position: "relative", overflow: "hidden" }}>
        <CreatorHeader
          avatarUrl={user?.avatarUrl}
          onError={setError}
          onStripeLogin={handleStripeLogin}
          refetchBalance={refetchBalance}
          balance={balanceData?.available}
          pendingBalance={balanceData?.pending}
          bankRef={coinDestinationRef}
          isStripeSetup={user?.creator?.isStripeSetup}
          setOpenShare={setOpenShare}
        />
        <Divider />
        <Stack
          className="stackLayout"
          sx={{ padding: "12px 20px", background: "#f1f1f1" }}
        >
          <Typography variant="body2" fontWeight={700}>
            My Beep:
          </Typography>
          <Typography
            variant="body2"
            fontWeight={400}
            sx={{ textDecoration: "underline" }}
          >
            {truncate(creatorLink)}
          </Typography>
          <Button
            className="creatorLinkButton"
            onClick={() => setOpenShare(true)}
          >
            Share
          </Button>
        </Stack>
        {loading || conversationsLoading ? (
          <>
            <Skeleton
              variant="rectangular"
              className="chat-preview-loading"
              height={91}
            />
            <Skeleton
              variant="rectangular"
              className="chat-preview-loading"
              height={91}
            />
            <Skeleton
              variant="rectangular"
              className="chat-preview-loading"
              height={91}
            />
            <Skeleton
              variant="rectangular"
              className="chat-preview-loading"
              height={91}
            />
            <Skeleton
              variant="rectangular"
              className="chat-preview-loading"
              height={91}
            />
          </>
        ) : (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                centered
                variant="fullWidth"
                classes={{
                  indicator: "tabs-indicator",
                }}
              >
                <Tab
                  label={
                    <>
                      Video Calls
                      <Badge
                        badgeContent={videoCalls.upcomingMeetings.length}
                        invisible={videoCalls.upcomingMeetings.length < 1}
                        sx={{
                          marginLeft: 12,
                          marginTop: -1,
                          background: "red !important",
                        }}
                        classes={{ standard: "badge" }}
                      />
                    </>
                  }
                  sx={{
                    fontSize: "15px",
                    fontFamily: "Lato",
                    color: "rgba(0, 0, 0, 0.60)",
                  }}
                  classes={{ selected: "tabs-selected" }}
                />
                <Tab
                  label={
                    <>
                      Paid DM Inbox
                      <Badge
                        badgeContent={conversations?.needsReply?.length}
                        invisible={conversations?.needsReply?.length < 1}
                        sx={{
                          marginLeft: 15,
                          marginTop: -1,
                          background: "red !important",
                        }}
                        classes={{ standard: "badge" }}
                      />
                    </>
                  }
                  sx={{
                    fontSize: "15px",
                    fontFamily: "Lato",
                    color: "rgba(0, 0, 0, 0.60)",
                  }}
                  classes={{ selected: "tabs-selected" }}
                />
              </Tabs>
            </Box>
            <TabPanel
              value={tab}
              index={0}
              style={{
                overflow: "auto",
                height: "100%",
                paddingBottom: "40px",
              }}
            >
              <VideoCallList
                user={user}
                bookings={videoCalls}
                loading={videoCallsLoading}
                refetchVideoCalls={refetchVideoCalls}
              />
            </TabPanel>
            <TabPanel
              value={tab}
              index={1}
              style={{
                overflow: "auto",
                height: "100%",
                paddingBottom: "40px",
              }}
            >
              {chatClient && (
                <CreatorChatList
                  className={`creator-chat-list`}
                  onGetMessagesClick={() => setTab(1)}
                  conversations={conversations}
                  loading={conversationsLoading}
                  refetchConversations={refetchConversations}
                  refetchBalance={refetchBalance}
                  onError={setError}
                  bankLocation={{
                    x: coinDestinationRef.current?.offsetLeft,
                    y: coinDestinationRef.current?.offsetTop,
                  }}
                />
              )}
            </TabPanel>
            {/* <TabPanel value={tab} index={2}>
              <GetMessages
                creatorLink={creatorLink}
                avatarUrl={user.avatarUrl}
              />
            </TabPanel> */}
          </>
        )}
      </Stack>
      <ShareLink
        creatorName={`${user?.creator?.firstName} ${user?.creator?.lastName}`}
        link={creatorLink}
        open={openShare}
        setOpen={setOpenShare}
      />
    </div>
  );
};

export default CreatorHome;
