import { Box, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { UserContext } from "../../../../context";
import useFetch from "../../../../hooks/useFetch";
import { Elements } from "@stripe/react-stripe-js";
import CardInfoDialog from "../../../../components/CardInfoDialog";
import styles from "./PaymentMethod.module.css";
import BackButton from "../../../../components/BackButton";
import CustomButton from "../../../../components/CustomButton";
import useMutate from "../../../../hooks/useMutate";
import { useNavigate } from "react-router-dom";

const PaymentMethod = () => {
  const { stripePromise, user, onError } = useContext(UserContext);
  const { data, refetch: refetchPayments } = useFetch("/user/payment-methods");
  const [deletePaymentMethod, { loading: deleteLoading }] = useMutate(
    "/user/payment-methods",
    { method: "DELETE" }
  );
  const [updateUser, { loading: updateLoading }] = useMutate(
    `/user/${user?.id}`,
    { method: "PUT" }
  );
  const [addOpen, setAddOpen] = useState(false);
  const navigate = useNavigate();

  const options = {
    mode: "setup",
    currency: "usd",
  };

  const handleMakeDefault = async (paymentMethodId) => {
    await updateUser({
      defaultPaymentMethod: paymentMethodId,
    });
    refetchPayments();
  };

  const handleDelete = async (paymentMethodId) => {
    await deletePaymentMethod({}, `/user/payment-methods/${paymentMethodId}`);
    refetchPayments();
  };

  const handleSubmit = async () => {
    setAddOpen(false);
    refetchPayments();
  };

  return (
    <Box
      sx={{
        margin: "0 auto",
        maxWidth: "550px",
      }}
    >
      <div className={styles.header}>
        <BackButton onClick={() => navigate("/dashboard")} />
        <h3 className={styles.title}>Payment Methods</h3>
        <div className={styles.placeholder} />
      </div>
      <Box sx={{ m: "30px" }}>
        <Stack spacing={2}>
          {data?.paymentMethods?.map((paymentMethod) => (
            <Stack
              direction="row"
              key={paymentMethod.id}
              gap={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" gap={1}>
                <Typography>
                  **** **** **** {paymentMethod.card?.last4}
                </Typography>
                {paymentMethod.default && (
                  <Typography sx={{ color: "#8585A3" }}>Default</Typography>
                )}
              </Stack>
              <Stack direction="row" gap={1}>
                {!paymentMethod.default && (
                  <CustomButton
                    sx={{ width: "fit-content" }}
                    variant="secondary"
                    loading={updateLoading}
                    onSubmit={() => handleMakeDefault(paymentMethod.id)}
                  >
                    Make Default
                  </CustomButton>
                )}
                <CustomButton
                  sx={{ width: "fit-content" }}
                  loading={deleteLoading}
                  onSubmit={() => handleDelete(paymentMethod.id)}
                >
                  Delete
                </CustomButton>
              </Stack>
            </Stack>
          ))}
        </Stack>
        <CustomButton sx={{ mt: 3 }} onSubmit={() => setAddOpen(true)}>
          Add New
        </CustomButton>
      </Box>
      <Elements stripe={stripePromise} options={options}>
        <CardInfoDialog
          open={addOpen}
          showSave={false}
          setupIntent={true}
          onDismiss={() => setAddOpen(false)}
          onSubmit={handleSubmit}
          onError={onError}
        />
      </Elements>
    </Box>
  );
};

export default PaymentMethod;
