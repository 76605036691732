import { Autocomplete, Box, InputLabel, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import BottomDrawer from "../BottomDrawer";
import expertises from "../../views/Creator/Signup/SignupForm/ExpertiseForm/expertises.json";
import { expertiseLookup } from "../../views/Creator/Signup/SignupForm/ExpertiseForm";
import CustomButton from "../CustomButton";
import { ReactComponent as CloseIcon } from "../../img/close.svg";
import ExpertiseBackground from "./ExpertiseBackground";

export default function UpdateExpertise({
  open,
  setOpen,
  setFieldValue,
  expertise,
  backgroundValue,
  onSubmit,
}) {
  const [value, setValue] = useState(expertise || []);
  const [inputValue, setInputValue] = useState("");

  const handleClose = () => {
    setOpen(false);
    setValue(expertise);
  };

  const handleRemoveOption = (optionToRemove) => {
    setValue(value.filter((option) => option !== optionToRemove));
  };

  const handleAutocompleteChange = (event, newValue) => {
    const newValueTitles = newValue.map((item) => item?.title);
    setValue((prevValue) => {
      let updatedValue = [...prevValue];
      newValueTitles.forEach((title) => {
        const index = updatedValue.indexOf(title);
        if (index > -1) {
          updatedValue.splice(index, 1);
        } else {
          updatedValue.push(title);
        }
      });
      updatedValue = updatedValue.filter((value) => value !== undefined);
      return updatedValue;
    });
  };

  const handleSave = (event) => {
    setFieldValue("expertise", value);
    setOpen(false);
    onSubmit(event);
  };

  return (
    <BottomDrawer
      isOpen={open}
      onClose={() => handleClose()}
      header="You can advise on"
      showHandle
      showBack
      canExit={false}
    >
      <InputLabel
        sx={{
          fontSize: "12px",
          fontFamily: "Lato",
          mb: 1,
          mt: 3,
          color: "#000",
        }}
      >
        Topics
      </InputLabel>
      <Autocomplete
        size="small"
        value={value}
        onChange={handleAutocompleteChange}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={expertises}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search 10k+ badges"
            sx={{
              boxShadow: "0px 3px 16px 0px rgba(0, 0, 0, 0.14)",
              borderRadius: "6px",
            }}
          />
        )}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option?.inputValue) {
            return option?.inputValue;
          }
          return option?.title || "";
        }}
        renderOption={(props, option) => (
          <li {...props}>
            {option?.emoji} {option?.title}
          </li>
        )}
        freeSolo
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        multiple
        classes={{
          input: "expertise-autocomplete",
          popper: "expertise-autocomplete-popper",
          option: "expertise-autocomplete-option",
          tag: "expertise-autocomplete-tag",
          endAdornment: "expertise-autocomplete-endAdornment",
        }}
      />
      <Box display="flex" flexWrap="wrap" mt="20px" gap={1}>
        {value?.map((option, index) => (
          <Box
            key={index}
            sx={{
              background: value?.length >= 0 ? "#3C4CE1" : "#F3F3F4",
              color: value?.length > 0 ? "#fff" : "#000",
              borderRadius: "50px",
              padding: "14px 16px",
              mb: "5px",
              fontSize: "14px",
              fontFamily: "Lato",
            }}
          >
            {expertiseLookup[option?.title || option]} {option?.title || option}
            <CloseIcon
              onClick={() => handleRemoveOption(option)}
              style={{ marginLeft: 6, cursor: "pointer" }}
            />
          </Box>
        ))}
      </Box>
      <ExpertiseBackground
        value={backgroundValue}
        title
        setFieldValue={setFieldValue}
      />
      <Stack direction="row" gap={2} mt={4}>
        <CustomButton variant="secondary" onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton onClick={handleSave} disabled={!value}>
          Save
        </CustomButton>
      </Stack>
    </BottomDrawer>
  );
}
