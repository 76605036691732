import React from "react";
import PropTypes from "prop-types";
import Template from "../Template";
import { withFormik } from "formik";
import { Box, Typography } from "@mui/material";

const Completed = ({ activeStep, handleSubmit }) => {
  return (
    <Template
      step={activeStep}
      onSubmit={handleSubmit}
      ctaText="Continue"
      showProgress={false}
      variant="secondary"
      isCompleted
    >
      <Box sx={{ margin: "0 auto", textAlign: "center", maxWidth: "316px" }}>
        <Typography
          fontSize="65px"
          color="#fff"
          fontWeight={900}
          fontFamily="Archivo"
          textAlign="left"
          lineHeight="61px"
          mt={3}
          className="image-text"
        >
          You did
          <br /> it! Your Beep
          <br /> Link awaits.
        </Typography>
      </Box>
    </Template>
  );
};

Completed.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setSubmitting: PropTypes.func,
  error: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.func,
  loading: PropTypes.bool,
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => initialValues,
  validationSchem: {},
  handleSubmit: (values, { props: { onSubmit } }) => {
    return onSubmit(null, "/creator/home");
  },
  displayName: "Completed",
})(Completed);
