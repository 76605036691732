export const pick = (keysToPick, obj = {}) => {
  const result = {};

  for (const key of keysToPick) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
      result[key] = obj[key];
    }
  }

  return result;
}