import React from 'react';
import { useParams } from 'react-router-dom';

import ContactForm from './ContactForm';
import NameForm from './NameForm';
import ServicesForm from './ServicesForm';
import ScheduleAvailabilityForm from './ScheduleAvailabilityForm';
import ConferencingForm from './ConferencingForm';
import BioForm from './BioForm';
import ExpertiseForm from './ExpertiseForm';
import StripeForm from './StripeForm';
import Completed from './Completed';

const getStepForm = (step) => {
  switch (step) {
    case 0:
      return ContactForm;
    case 1:
      return NameForm;
    case 2:
      return ServicesForm;
    case 3:
      return ScheduleAvailabilityForm;
    case 4:
      return ConferencingForm;
    case 5:
      return BioForm;
    case 6:
      return ExpertiseForm;
    case 7:
      return StripeForm;
    case 8:
      return Completed;
    default:
      return () => <></>;
  }
}

export default function SignupForm({ activeStep, initialValues, onSave, loading }) {
  const { step } = useParams();
  const StepForm = getStepForm(activeStep);

  const handleSave = async (values, navigateTo) => {
    return onSave(values, navigateTo);
  }

  return (
    <StepForm
      onSubmit={handleSave}
      initialValues={initialValues}
      loading={loading}
      activeStep={activeStep}
      step={step}
    />
  )
}
