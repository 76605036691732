import React, { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import * as Yup from "yup";
import styles from "./SignupForm.module.css";
import CustomFormInput from "../CustomFormInput";
import CustomButton from "../CustomButton";
import { Link } from "react-router-dom";
import { UserContext } from "../../context";
import { Button, Stack, Typography } from "@mui/material";
import useLazyFetch from "../../hooks/useLazyFetch";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const usernameRules = /^(?=.{3})[A-Za-z\d_-]+$/gi;

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Must be at least 3 letters or numbers")
    .max(25, "Must be 25 characters or less")
    .matches(usernameRules, {
      message: "Your username must contain only letters or numbers",
    })
    .required("Username is required"),
  email: Yup.string().email().required("Email is required"),
  password: Yup.string()
    .min(8, "Password is too short - should be at least 8 characters.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!-_@#$%^&*]{8,}$/,
      "Password must contain: lower case letters (a-z), upper case letters (A-Z) and numbers (0-9)."
    )
    .required("Password is required"),
});
const CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

const makeNonce = (length) => {
  let result = "";
  for (let i = 0; i < length; i++) {
    result += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
  }
  return result;
};

const SignupForm = ({
  values,
  errors,
  setFieldTouched,
  setFieldError,
  onSuccess,
  experience,
  setExperience,
}) => {
  const { auth, onError } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [validUsername, setValidUsername] = useState(false);
  const [isUsernameAvailable, { loading: usernameLoading }] = useLazyFetch(
    `/username/available/${values.username}`,
    { auth: false }
  );

  const handleUsernameValid = useCallback(async () => {
    setFieldTouched("username", true);
    if (!values.username || errors?.username) return;
    const { data } = await isUsernameAvailable();
    if (data?.available) {
      setValidUsername(true);
      setFieldError("username", "");
      return true;
    } else {
      setValidUsername(false);
      setFieldError("username", "Username is not available");
      return false;
    }
  }, [
    isUsernameAvailable,
    setFieldError,
    values.username,
    setFieldTouched,
    errors,
  ]);

  const setMissingField = useCallback(
    (field) => {
      setFieldTouched(field, true);
      setFieldError(field, `${field} is required`);
      onError("Missing required input");
    },
    [setFieldError, setFieldTouched, onError]
  );

  const isValid = useCallback(() => {
    if (!values.username) setMissingField("username");
    if (!values.email) setMissingField("email");
    if (!values.password) setMissingField("password");
    return !!(
      values.username &&
      !errors.username &&
      values.email &&
      !errors.email &&
      values.password &&
      !errors.password
    );
  }, [values, errors, setMissingField]);

  const authCallback = useCallback(
    (error) => {
      if (error) {
        setLoading(false);
        return onError(error.description);
      }
      onSuccess();
      setLoading(false);
    },
    [onSuccess, onError]
  );

  const login = useCallback(
    (nonce) => {
      auth.login(
        {
          password: values.password,
          email: values.email,
          realm: "Username-Password-Authentication",
          state: nonce,
        },
        authCallback
      );
    },
    [auth, authCallback, values]
  );

  const signup = useCallback(
    (nonce) => {
      setLoading(true);
      auth.signupAndAuthorize(
        {
          password: values.password,
          email: values.email,
          username: values.username,
          connection: "Username-Password-Authentication",
        },
        (error) => {
          if (error) {
            setLoading(false);
            return onError(error.description);
          }
          login(nonce);
        }
      );
    },
    [auth, values, onError, login]
  );

  const handleFormSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      if (isValid()) {
        const nonce = makeNonce(12);
        const appState = JSON.stringify({
          [nonce]: {
            returnTo: window.location.pathname + window.location.search,
          },
        });
        localStorage.removeItem("nonce");
        localStorage.removeItem("appState");
        localStorage.setItem("nonce", nonce);
        localStorage.setItem("appState", appState);
        experience === "signup" ? signup(nonce) : login(nonce);
      } else {
        onError(errors?.password || errors?.email);
      }
    },
    [isValid, experience, login, signup, errors, onError]
  );

  return (
    <form onSubmit={handleFormSubmit} className={styles.modalForm}>
      <Stack alignItems={"center"}>
        <Typography variant="header1" textAlign={"center"} fontSize={"20px"}>
          {experience === "signup" ? (
            <>
              Sign Up to Get Your
              <br /> Questions Answered
            </>
          ) : (
            "Login to chat"
          )}
        </Typography>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          mt="10px"
        >
          {experience === "signup" ? (
            <>
              <Typography variant="body1" fontSize={"15px"}>
                Already have an account?
              </Typography>
              <Button
                onClick={() => setExperience("login")}
                style={{
                  textDecoration: "underline",
                  textTransform: "none",
                  color: "#000",
                  fontSize: "15px",
                  fontFamily: "Lato",
                  fontWeight: 900,
                }}
              >
                Log in
              </Button>
            </>
          ) : (
            <>
              <Typography variant="body1" fontSize={"15px"}>
                Don't have a Beep account?{" "}
              </Typography>
              <Button
                style={{
                  textDecoration: "underline",
                  textTransform: "none",
                  color: "#000",
                  fontSize: "15px",
                  fontFamily: "Lato",
                  fontWeight: 900,
                }}
                onClick={() => setExperience("signup")}
              >
                Create Account
              </Button>
            </>
          )}
        </Stack>
      </Stack>
      <>
        {experience === "signup" && (
          <CustomFormInput
            name="username"
            label="Username"
            placeholder="Username"
            fullWidth
            valid={!errors.username && validUsername}
            onBlur={handleUsernameValid}
            helperText={
              usernameLoading
                ? "Checking availability..."
                : "Can only contain letters and numbers"
            }
            endAdornment={
              validUsername && !errors.username ? (
                <CheckCircleOutlineIcon color="success" />
              ) : null
            }
            className={styles.username}
          />
        )}
        <CustomFormInput
          name="email"
          label="Email"
          placeholder="Email"
          fullWidth
          className={styles.username}
        />
        <CustomFormInput
          name="password"
          label="Password"
          type="password"
          placeholder="Password"
          fullWidth
          className={styles.username}
        />
      </>
      {experience === "signup" && (
        <Typography
          color={"#717192"}
          fontSize="12px"
          fontFamily={"Lato"}
          mb={2}
        >
          By clicking Continue, you agree to Beep’s{" "}
          <Link to="/terms" style={{ color: "#717192" }}>
            Terms and Conditions
          </Link>{" "}
          and confirm you have read our{" "}
          <Link to="/privacy" style={{ color: "#717192" }}>
            Privacy Notice
          </Link>
          . You may receive offers, news, and updates from us.
        </Typography>
      )}
      <CustomButton
        type="submit"
        disabled={loading || !validUsername}
        loading={loading}
        sx={{ background: "#000 !important" }}
      >
        {experience === "signup" ? "Create Account" : "Log In"}
      </CustomButton>
    </form>
  );
};

SignupForm.propTypes = {
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  onLoginClick: PropTypes.func,
  onError: PropTypes.func,
  errors: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    phoneNumber: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    username: PropTypes.string,
  }),
  touched: PropTypes.shape({
    email: PropTypes.bool,
    password: PropTypes.bool,
    phoneNumber: PropTypes.bool,
    firstName: PropTypes.bool,
    lastName: PropTypes.bool,
    username: PropTypes.bool,
  }),
  values: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    phoneNumber: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    username: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

export default withFormik({
  mapPropsToValues: () => ({}),
  validationSchema,
  handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
    onSubmit(values);
    setSubmitting(false);
  },
  displayName: "SignupForm",
})(SignupForm);
