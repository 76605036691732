import { Stack, Typography } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { ReactComponent as Logo } from "../../img/logo-no-text.svg";
import { ReactComponent as Check } from "../../img/rounded-check.svg";
import styles from "./HowItWorks.module.css";
import { ReactComponent as Video } from "../../img/video.svg";
import { ReactComponent as Message } from "../../img/message.svg";
import { ReactComponent as Security } from "../../img/security.svg";

export default function HowItWorks() {
  return (
    <>
      <Stack alignItems={"center"} gap={2} mt={10} mb={5}>
        <Logo />
        <Typography variant="header1" color={"#fff"}>
          How It Works
        </Typography>
      </Stack>

      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        autoplay={{
          delay: 2600,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        <SwiperSlide>
          <Stack
            className={styles.carousel}
            alignItems={"center"}
            justifyContent="center"
          >
            <Check />
            <Typography
              variant="body1"
              fontSize={"30px"}
              color="#fff"
              fontWeight={300}
            >
              {/* <span style={{ fontWeight: 800 }}>Find</span> a Beep
              <br /> verified expert
              <br /> or creator */}
              <span style={{ fontWeight: 800 }}>Find</span> your Expert's <br />{" "}
              Beep link to Book <br /> a Call or Ask a<br /> Question.
            </Typography>
          </Stack>
        </SwiperSlide>
        <SwiperSlide>
          <Stack
            className={styles.carousel}
            alignItems={"center"}
            justifyContent="center"
          >
            <Stack
              direction={"row"}
              justifyContent="center"
              position={"relative"}
            >
              <Video style={{ position: "absolute", right: "40%" }} />
              <Message style={{ marginLeft: 60 }} />
            </Stack>
            <Typography
              variant="body1"
              fontSize={"30px"}
              color="#fff"
              fontWeight={300}
            >
              {/* <span style={{ fontWeight: 800 }}>Book a call</span> or <br />
              <span style={{ fontWeight: 800 }}>send a message</span>
              <br /> for fast, personal
              <br /> 1:1 connection */}
              <span style={{ fontWeight: 800 }}>Select a time</span> that
              <br /> works for you both
              <br /> and get fast, personal
              <br /> 1:1 advice and answers
            </Typography>
          </Stack>
        </SwiperSlide>
        <SwiperSlide>
          <Stack
            className={styles.carousel}
            alignItems={"center"}
            justifyContent="center"
          >
            <Security />
            <Typography
              variant="body1"
              fontSize={"30px"}
              color="#fff"
              fontWeight={300}
            >
              Receive responses <br />
              <span style={{ fontWeight: 800 }}>within 7 days</span> or
              <br /> be automatically
              <br /> refunded
            </Typography>
          </Stack>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
