import { Divider, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import BackButton from "../../../../components/BackButton";
import styles from "./SetAvailability.module.css";
import beepLogo from "../../../../img/logo-with-text.svg";
import TimezoneSelect from "../../../../components/TimezoneSelect";
import { withFormik } from "formik";
// import { TabPanel } from "../../Creator/Home";
// import WeeklyHours from "../AvailabilityTabs/WeeklyHours";
// import DateOverrides from "../AvailabilityTabs/DateOverrides";
import HolidaysTab from "../AvailabilityTabs/Holidays";
import axios from "axios";
import CustomButton from "../../../../components/CustomButton";

const SetAvailability = ({
  userId,
  values,
  handleSubmit,
  setFieldValue,
  onError,
  errors,
  touched,
  loading,
  setFieldTouched,
  setFieldError,
}) => {
  // const [tab, setTab] = useState(0);
  const [countries, setCountries] = useState([]);

  // const handleTabChange = (event, newValue) => {
  //   setTab(newValue);
  // };

  const handleFormSubmit = useCallback(
    (event) => {
      event.preventDefault();
      handleSubmit(values);
    },
    [handleSubmit, values]
  );

  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const limitedData = response.data.map((country) => ({
          name: country.name.common,
          countryCode: country.cca2,
        }));

        setCountries(limitedData);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  return (
    <div>
      <div className={styles.header}>
        <Stack direction="row">
          <BackButton />
          <img src={beepLogo} alt="logo" width={87} height={22} />
        </Stack>
        <Typography variant="body2" fontWeight={600}>
          Availability
        </Typography>
      </div>
      <form onSubmit={handleFormSubmit} className={styles.container}>
        <Typography variant="header1" fontSize={"22px"} mt={2} mb={3}>
          Set your availability
        </Typography>
        <TimezoneSelect
          timezone={values?.timezone}
          onChange={(event, newValue) => {
            setFieldValue("timezone", newValue);
          }}
        />
        <Divider sx={{ mt: 3 }} />
        {/* <Box>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            centered
            variant="fullWidth"
            classes={{
              indicator: "tabs-indicator",
            }}
          >
            <Tab
              label={"Weekly hours"}
              sx={{
                fontSize: "15px",
                fontFamily: "Lato",
                color: "rgba(0, 0, 0, 0.60)",
                minWidth: "fit-content !important",
              }}
              classes={{ selected: "tabs-selected" }}
            />
            <Tab
              label={"Date overrides"}
              sx={{
                fontSize: "15px",
                fontFamily: "Lato",
                color: "rgba(0, 0, 0, 0.60)",
                minWidth: "fit-content !important",
              }}
              classes={{ selected: "tabs-selected" }}
            />
            <Tab
              label={"Holidays"}
              sx={{
                fontSize: "15px",
                fontFamily: "Lato",
                color: "rgba(0, 0, 0, 0.60)",
              }}
              classes={{ selected: "tabs-selected" }}
            />
          </Tabs>
        </Box> */}
        {/* <TabPanel
          value={tab}
          index={0}
          style={{
            overflow: "auto",
            height: "100%",
            paddingBottom: "40px",
          }}
        >
          <WeeklyHours scheduleData={values?.schedule} />
        </TabPanel> */}
        {/* <TabPanel
          value={tab}
          index={1}
          style={{
            overflow: "auto",
            height: "100%",
            paddingBottom: "40px",
          }}
        >
          <DateOverrides />
        </TabPanel> */}
        {/* <TabPanel
          value={tab}
          index={2}
          style={{
            overflow: "auto",
            height: "100%",
            paddingBottom: "40px",
          }}
        > */}
        <HolidaysTab
          countries={countries}
          values={values}
          setFieldValue={setFieldValue}
        />
        {/* </TabPanel> */}
        <CustomButton
          loading={loading}
          type="submit"
          sx={{ my: "20px !important" }}
        >
          Save
        </CustomButton>
      </form>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => initialValues,
  validationSchem: {},
  handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
    onSubmit(values);
    setSubmitting(false);
  },
  displayName: "SetAvailability",
})(SetAvailability);
