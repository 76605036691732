import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Home.module.css";
import { UserContext } from "../../context";
import { getCreatorByInsta } from "../../lib/api";
import { Box, Stack, Typography } from "@mui/material";
import { useFanAnalytics } from "../../hooks/useAnalytics";
import VideoCallIcon from "../../components/icons/VideoCallIcon";
import MessagesIcon from "../../components/icons/MessagesIcon";
import CustomButton from "../../components/CustomButton";
import { ReactComponent as Logo } from "../../img/logo-completed.svg";
import { ReactComponent as ArrowRight } from "../../img/button-arrow.svg";
import { expertiseLookup } from "../Creator/Signup/SignupForm/ExpertiseForm";
import HowItWorks from "../../components/HowItWorks";
import LandingFaq from "../Faq/LandingFaq";
import Footer from "../../components/Footer";
import VideoBooking from "./Booking/VideoBooking";
import { formatISO } from "date-fns";

const Home = () => {
  const { user } = useContext(UserContext);
  const [creator, setCreator] = useState();
  const [error, setError] = useState();
  const [invalidCreator, setInvalidCreator] = useState(false);
  const { creatorInsta } = useParams();
  const sendFanEvent = useFanAnalytics();
  const navigate = useNavigate();
  const [videoCallOpen, setVideoCallOpen] = useState(false);
  const [dateValue, setDateValue] = useState(new Date());
  const startOfMonth = formatISO(
    new Date(dateValue.getFullYear(), dateValue.getMonth(), 1),
    { representation: "date" }
  );
  const endOfMonth = formatISO(
    new Date(dateValue.getFullYear(), dateValue.getMonth() + 1, 0),
    { representation: "date" }
  );

  useEffect(() => {
    if (creator || invalidCreator) return;

    const fetchCreator = async () => {
      const creator = await getCreatorByInsta(creatorInsta);
      if (!creator) {
        setError("Creator has not signed up yet");
        setInvalidCreator(true);
        return;
      }
      sendFanEvent({ action: "chat loaded", creator: creatorInsta });
      setCreator(creator);
    };

    fetchCreator();
  }, [creatorInsta, sendFanEvent, creator, invalidCreator]);

  console.log(error);

  return (
    <Box bgcolor={"#000"} className={styles.home}>
      <Stack alignItems={"center"} p={2}>
        <Logo height={22} width={87} />
      </Stack>
      {creator?.avatarUrl ? (
        <Box position={"relative"}>
          <img
            src={creator?.avatarUrl}
            className={styles.previewImage}
            alt="avatar"
          />
          <Box className={styles.intro}>
            <Typography variant="header1" fontSize={"48px"}>
              {creator?.firstName} <br />
              {creator?.lastName}
            </Typography>
            {creator?.briefBio &&
              creator?.briefBio?.brandName &&
              creator?.briefBio?.topic1 &&
              creator?.briefBio?.topic2 && (
                <Typography
                  variant="body1"
                  fontSize={"20px"}
                  fontWeight={500}
                  mt={1}
                >
                  From the mind behind {creator?.briefBio?.brandName},{" "}
                  {creator?.firstName} advises you on{" "}
                  {creator?.briefBio?.topic1} and {creator?.briefBio?.topic2}
                </Typography>
              )}
          </Box>
        </Box>
      ) : (
        <Stack alignItems={"center"} textAlign="center" pt={5} px={2}>
          <Typography
            variant="header1"
            fontSize={"48px"}
            color="#fff"
            className="image-text"
          >
            {creator?.firstName} <br /> {creator?.lastName}
          </Typography>
          <Typography
            variant="body1"
            color={"#fff"}
            fontSize={"20px"}
            fontWeight={500}
            mt={1}
          >
            From the mind behind {creator?.briefBio?.brandName},{" "}
            {creator?.firstName} advises you on {creator?.briefBio?.topic1} and{" "}
            {creator?.briefBio?.topic2}
          </Typography>
        </Stack>
      )}

      <Box px={2} mt={1} mb={5}>
        <Stack justifyContent="space-between" gap={2} mt={4}>
          {creator?.videoCallsEnabled && (
            <Box
              onClick={() => setVideoCallOpen(true)}
              className={styles.serviceNoImage}
            >
              <VideoCallIcon fillWhite />
              <Typography
                variant="body2"
                fontWeight={900}
                textAlign={"center"}
                color="#fff"
              >
                Video call {`$${creator?.videoCallHourlyRate / 2}`}
              </Typography>
            </Box>
          )}
          {creator?.messagesEnabled && (
            <Box
              onClick={() => navigate(`/${creatorInsta}/message`)}
              className={styles.serviceNoImage}
            >
              <MessagesIcon fillWhite />
              <Typography
                variant="body2"
                fontWeight={900}
                textAlign={"center"}
                color="#fff"
              >
                Message {`$${creator?.dmPrice}`}
              </Typography>
            </Box>
          )}
        </Stack>
        {creator?.faqs?.length > 0 && (
          <Stack className={styles.faqContainer} gap={5}>
            <Typography
              variant="header1"
              color="#fff"
              textAlign="center"
              mt={2}
            >
              {creator?.firstName}’s Most Frequently <br /> Asked Questions
            </Typography>
            {creator?.faqs.map((faq) => (
              <Stack key={faq?.id} className={styles.faq}>
                <Typography color={"#fff"}>{faq?.content}</Typography>
                <CustomButton
                  onClick={() => navigate(`/${creatorInsta}/message`)}
                  endIcon={<ArrowRight />}
                >
                  Ask {creator?.firstName}
                </CustomButton>
              </Stack>
            ))}
          </Stack>
        )}
        {creator?.expertise.length > 0 && (
          <Stack
            justifyContent={"end"}
            sx={{
              position: "relative",
              height: user?.creator?.expertiseBackground ? 500 : "auto",
              textAlign: "center",
              mt: 10,
              background: `url(${user?.creator?.expertiseBackground}) rgba(0, 0, 0, 0.35) no-repeat`,
              boxShadow:
                user?.creator?.expertiseBackground &&
                "8px 8px 24px 0px rgba(3, 111, 98, 0.12)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "20px",
            }}
          >
            {creator?.expertiseBackground && (
              <Box
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.35)",
                }}
              />
            )}

            <Box zIndex={10}>
              <Typography variant="header1" color={"#fff"}>
                {creator?.firstName} Can Advise On
              </Typography>
              <Box
                display="flex"
                justifyContent={"center"}
                flexWrap="wrap"
                mt={3}
                mb={user?.creator?.expertiseBackground && 7}
                gap={1}
              >
                {creator?.expertise
                  ?.filter((option) => option)
                  ?.map((option, index) => (
                    <Box
                      key={index}
                      sx={{
                        background: "#F3F3F4",
                        color: "#000",
                        borderRadius: "50px",
                        padding: "14px 16px",
                        mb: "5px",
                        fontSize: "14px",
                        fontFamily: "Lato",
                      }}
                    >
                      {expertiseLookup[option?.title || option]}{" "}
                      {option?.title || option}
                    </Box>
                  ))}
              </Box>
            </Box>
          </Stack>
        )}
        <HowItWorks />
        <LandingFaq />
      </Box>
      <Footer />
      <VideoBooking
        creator={creator}
        isOpen={videoCallOpen}
        onClose={() => setVideoCallOpen(false)}
        dateValue={dateValue}
        setDateValue={setDateValue}
        startDate={startOfMonth}
        endDate={endOfMonth}
      />
    </Box>
  );
};

export default Home;
